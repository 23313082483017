import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { API_BASE_URL } from '../config/api';


const axios = require("axios");

class Filesingledisplay extends React.Component {
	
	constructor(props)
	{
		super(props);	
		this.state = {
			files: [],
			searchfiles: [],
			deleteRecord: "",
			query : "",
			files: {
				plaintiff_name:'',
				  minor:'',
				  plaintiff_ic_number:'',
				  plaintiff_mobile_number:'',
				  plaintiff_occupation:'',
				  plaintiff_dob:'',
				  plaintiff_address:'',
				  plaintiff_telephone_number:'',
				  referral_by:'',
				  accident_date:'',
				  accident_time:'',
				  accident_type:'',
				  accident_location:'',
				  plaintiff_position:'',
				  time_bar:'',
				  p_type:'',
				  case_file_number:'',
				  plaintiff_vehicle_number:'',
				  vehicle_in_front:'',
				  vehicle_in_back:''
							  
			  }
			
		};
		localStorage.setItem('minor', 'unchcked');	
    }
	
	componentDidMount(){
		var case_file_number = JSON.parse(localStorage.getItem('file_list'));
		const data = case_file_number
		console.log('uodatettetet',data);
		   this.setState({
   
			   files: {
				   plaintiff_name:data['plaintiff_name'],
				   minor:data['minor'],
				   plaintiff_ic_number:data['plaintiff_ic_number'],
				   plaintiff_mobile_number:data['plaintiff_mobile_number'],
				   plaintiff_occupation:data['plaintiff_occupation'],
				   plaintiff_dob: data['plaintiff_dob'],
				   plaintiff_address: data['plaintiff_address'],
				   plaintiff_telephone_number: data['plaintiff_telephone_number'],
				   referral_by: data['referral_by'],
				   accident_date: data['accident_date'],
				   accident_time:data['accident_time'],
				   accident_type:data['accident_type'],
				   accident_location:data['accident_location'],
				   plaintiff_position:data['plaintiff_position'],
				   time_bar:data['time_bar'],
				   p_type:data['p_type'],
				   case_file_number: data['case_file_number'],
				   plaintiff_vehicle_number:data['plaintiff_vehicle_number'],
				   vehicle_in_front:data['vehicle_in_front'],
				   vehicle_in_back:data['vehicle_in_back'],
				   plaintiff_id:data['plaintiff_id']
						   
			   }
		})
    }
	
	

	
  render() {
	  const { files, file } = this.state
	  console.log('map',files);
	  var case_file_number = JSON.parse(localStorage.getItem('file_list'));
	  var case_file_number = case_file_number['case_file_number'];
	  console.log('ccassseee',case_file_number);
	  var checked = localStorage.getItem('minor');
    return (	
		<div className="wrapper"> 
     	  <Header />
	      <MenuSidebar />		
       	<div className="content">	
		<div className="content-wrapper">
			<div className="content-body">
			 <div className="form-right">
			 <div class="form-group">
	          <div className="checkbox-right"> <span><b>STATUS OF FILE : {case_file_number}</b></span> </div>
			</div></div>
			<div className="form-wrapper">
			
			{/*<table><tr><th width="140">plaintiff Name</th><th> Ic Number</th><th> Mobile Number</th><th> Occupation</th><th>Address</th><th>Accident Location</th><th>Plaintiff Position</th><th>Case File Number</th><th>Plaintiff Vehicle No</th></tr>
			<tr key={files.plaintiff_id}><td>{files.plaintiff_name} </td> <td>{files.plaintiff_ic_number} </td> <td>{files.plaintiff_mobile_number} </td> <td>{files.plaintiff_occupation} </td> <td>{files.plaintiff_address}</td> <td>{files.accident_location}</td><td>{files.plaintiff_position}</td><td>{files.case_file_number}</td><td>{files.plaintiff_vehicle_number}</td> 
			 </tr> */}
			 <table><tr><th width="20" >No</th><th >Status</th><th> Updated By</th><th> Updated On</th></tr>
			 <tr>
			 <td  width="20" >1</td>
			 <td> Opening of files</td>
			 <td> Updated By MS Jeswanth</td>
			 <td> 20 rd dec 2019 11:30</td>
			 </tr>
			 <tr>
			 <td  width="20" >2</td>
			 <td>Letter of clincs and hospitals</td>
			 <td> Updated By MS Mars</td>
			 <td> 20 rd dec 2019 06:30</td>
			 </tr>
			 <tr>
			 <td  width="20" >3</td>
			 <td> Receipts of MRS nad SPMRS</td>
			 <td> Updated By MS Mars</td>
			 <td> 23 rd jan 2020 07:30</td>
			 </tr>
			 <tr>
			 <td  width="20" >4</td>
			 <td> Letter Of Demand</td>
			 <td> Updated By MS Mars</td>
			 <td> 23 rd jan 2020 11:30</td>
			 </tr>
			 <tr>
			 <td  width="20" >5</td>
			 <td> File Passed To pre-wrint</td>
			 <td> Updated By MS Mars</td>
			 <td> 23 rd jan 2020 11:30</td>
			 </tr>
			 <tr>
			 <td  width="20" >6</td>
			 <td> Communications to Insurance Companies</td>
			 <td> Updated By MS Mars</td>
			 <td> 24 th jan 2020 11:30</td>
			 </tr>
			 <tr>
			 <td  width="20" >7</td>
			 <td> Notice for PI to Insurance Companies</td>
			 <td> Updated By MS Mars</td>
			 <td> 24 th jan 2020 11:30</td>
			 </tr>
			 <tr>
			 <td  width="20" >8</td>
			 <td>  Notice for PD to Insurance Companies</td>
			 <td> Updated By MS Jeswanth</td>
			 <td> 25 th jan 2020 11:30</td>
			 </tr>
			 <tr>
			 <td  width="20" >9</td>
			 <td> Issues Of Writ</td>
			 <td> Updated By MS Jeswanth</td>
			 <td> 25 th feb 2020 11:30</td>
			 </tr>
			 <tr>
			 <td  width="20" >10</td>
			 <td> Servies of Writ</td>
			 <td> Updated By MS Jeswanth</td>
			 <td> 25 th jan 2020 11:30</td>
			 </tr>
			 <tr>
			 <td  width="20" >11</td>
			 <td> File Memoranadom of Service (MOS)</td>
			 <td> Updated By MS Jeswanth</td>
			 <td> 23 rd jan 2020 11:30</td>
			 </tr>
			 <tr>
			 <td  width="20" >12</td>
			 <td> File Memoranadom of Apperance (MOA)</td>
			 <td> Updated By MS Jeswanth</td>
			 <td> 23 rd jan 2020 11:30</td>
			 </tr>
			 <tr>
			 <td  width="20" >13</td>
			 <td> Court Dispute Resolution</td>
			 <td> To Be Updated By MS Durga /Mr Emily</td>
			 <td> 23 rd jan 2020 11:30</td>
			 </tr>
			 <tr>
			 <td  width="20" >14</td>
			 <td> CDR Result</td>
			 <td> To Be Updated By MS Durga /Mr Emily</td>
			 <td> 1 st  jan 2020 11:30</td>
			 </tr>
			 <tr>
			 <td  width="20" >15</td>
			 <td> Matter Setteled</td>
			 <td> To Be Updated By MS Durga /Mr Emily</td>
			 <td> 23 rd jan 2020 11:30</td>
			 </tr>
			 <tr>
			 <td  width="20" >16</td>
			 <td> Accounts</td>
			 <td> To Be Updated By MS Jeswanth/Mr Emily</td>
			 <td> 1 st  jan 1998 11:30</td>
			 </tr>
			 <tr>
			 <td  width="20" >17</td>
			 <td> File closure</td>
			 <td> To Be Updated By MS Jeswanth</td>
			 <td> 23 rd jan 2020 11:30</td>
			 </tr>
			 
			 </table>
					 
			</div>
			
			</div>
			
		</div>	
	</div>
		 	
    </div>
    );
  }
}

export default Filesingledisplay;