import React, { Component } from 'react';
import { Link } from "react-router-dom";
import $ from 'jquery';
import Header from '../Layouts/Header';
import { API_BASE_URL } from '../config/api';
import MenuSidebar from '../Layouts/MenuSidebar';
const axios = require("axios");

class Defendentdetails extends React.Component {
	
	constructor(props)
	{
		super(props);	
		this.state = {
			defendents: [],
      deleteRecord: "",
      insurances : "",
			defendent: {
                defendent_name: '',
                defendent_address: '',
                defendent_vehicle_no: '',
                defendent_nric: '',
                defendent_mobile_number: '',
                defendent_insurance: ''
             		  
			}
		};	
    }

    
	
	componentDidMount(){
     //this.getdefendents()
     this.getInsurances()
    }

    handleFormSubmit = () => {
		const { defendent } = this.state
    localStorage.setItem('defendent',  JSON.stringify(defendent));
    var value_incc = parseInt(localStorage.getItem("idef_inc_val"));
    for (var i = 1; i <= value_incc; i++) {    
    var defendent_name = "defendent_name"+[i];
    var defendent_address = "defendent_address"+[i];
    var defendent_vehicle_no = "defendent_vehicle_no"+[i];
    var defendent_mobile_number = "defendent_mobile_number"+[i];
    var defendent_nric = "defendent_nric"+[i];
    var defendent_insurance = "defendent_insurance"+[i];

  var name = localStorage.getItem(defendent_name);
  var address = localStorage.getItem(defendent_address);
  var vehicle_no = localStorage.getItem(defendent_vehicle_no);
  var mobile_number = localStorage.getItem(defendent_mobile_number);
  var nric = localStorage.getItem(defendent_nric);
  var insurance = localStorage.getItem(defendent_insurance);

    console.log('deffnameee_append454545',insurance);
 if((name!='') && (address!='') && (vehicle_no!='') && (mobile_number!='')&& (nric!='') && (insurance!='')){
          var appenddata = {

          defendent_name: localStorage.getItem((defendent_name)),
          defendent_address: localStorage.getItem(defendent_address),
          defendent_vehicle_no : localStorage.getItem(defendent_vehicle_no),
          defendent_mobile_number : localStorage.getItem(defendent_mobile_number),
          defendent_nric: localStorage.getItem(defendent_nric),
          defendent_insurance: localStorage.getItem(defendent_insurance)  
      
      };
      console.log('appenddata_defendents',appenddata); 
      if((appenddata.defendent_name!= null) && (appenddata.defendent_address!= null) && (appenddata.defendent_vehicle_no!=null) && (appenddata.defendent_mobile_number!= null)&& (appenddata.defendent_nric!= null) && (appenddata.defendent_insurance!= null)){
      localStorage.setItem("appenddata_defendents"+[i], JSON.stringify(appenddata));
     }
   } 
 } 
};
	
	getdefendents = _ => {
    fetch(API_BASE_URL+'/files')
    .then(response => response.json())
    .then(response => this.setState({ defendents: response.data}))
    .catch(err => console.log(err))
  }
  
  handleChangeDelete = e => {
    this.setState({
      deleteRecord: e.target.name
    });
  };
  handleSubmit = e => {
    e.preventDefault();

    var Formdata = {
      deleteRecord: this.state.deleteRecord
    };
    

    axios.post(API_BASE_URL+'/defendents/delete', { Formdata }).then(response => {
      console.log(response.data);
      console.log(response);
	 this.getdefendents();
	  //this.setState({ defendents: this.getdefendents});
      //window.location.reload();
    });
  };

  /*********Get insurance details in drop down***********/

  getInsurances = _ => {
    fetch(API_BASE_URL+'/insurance')
    .then((response) => {
      return response.json();
    })

    .then(response => {
      localStorage.setItem('data_defendent',JSON.stringify(response.data)) 
      this.setState({
      defendents: [{value: '', name: 'Select your insurances'}].concat(response.data)
      })})
      .catch(err => console.log(err))
   
  }
  
  //  adddefendentChild (event) {
	// event.preventDefault();	
	//  var field_html ='<div class="form-row"><div class="form-left"><div class="form-group"><label>Defendent Name</label>	<input type="text" class="form-control" placeholder="" value="" /></div><div class="form-group"><label>Defendent Address</label><input type="text" class="form-control"  placeholder="" value="" /></div><div className="form-group"><label>Defendent Vehicle Number</label><input type="text" class="form-control" placeholder="" value="" /></div></div><div class="form-right"><div class="form-group"><label>Defendent NRIC</label><input type="text" class="form-control"  placeholder="" value="" /></div><div class="form-group"><label>Defendent Mobile Number</label>	<input type="text" maxLength ="8" class="form-control"  placeholder="" value="" /></div><div class="form-group"><label>Defendent Insurance</label> <select value=""></select></div></div></div>';
	// $(".hospital-details-wrap").append(field_html);
  // }

  
  adddefendentChild (inc,e) {
    if (localStorage.getItem("idef_inc_val") > 0){
    var inc_val =  parseInt(localStorage.getItem("idef_inc_val"))+1;
    }else{
      var inc_val =  parseInt(inc)+1; 
    }
    localStorage.setItem("clicked_add", 'yes')
    console.log('idef_inc_val',inc_val);
    localStorage.setItem("idef_inc_val", inc_val)
    var value_incc = parseInt(localStorage.getItem("idef_inc_val"))-1;
    var value_incc_field = parseInt(localStorage.getItem("idef_inc_val"))-1;
    var max_fields = 16;
    var wrapper = $(".hospital-details-wrap");
    var x = 0; 
    e.preventDefault();
    if (inc_val < max_fields) {
      //x++;
      console.log('inc_val',inc_val);
      console.log('max_fields',max_fields);
      //console.log('x',x);
      $(wrapper).append(`
      <div class="form-row">
        <div class="form-left">
         <div class="form-group">
           <label>Defendent Name</label>	
             <input type="text" id='defendent_name`+[inc_val]+`' class="form-control" placeholder="" value="" />
         </div>
        <div class="form-group">
           <label>Defendent Address</label>
             <input type="text" id='defendent_address`+[inc_val]+`' class="form-control"  placeholder="" value="" />
         </div>
         <div className="form-group">
             <label>Defendent Vehicle Number</label>
               <input type="text" id='defendent_vehicle_no`+[inc_val]+`' class="form-control" placeholder="" value="" />
          </div>
      </div>
      <div class="form-right">
        <div class="form-group">
          <label>Defendent NRIC</label>
            <input type="text" id='defendent_nric`+[inc_val]+`' class="form-control"  placeholder="" value="" />
        </div>
       <div class="form-group">
             <label>Defendent Mobile Number</label>
               <input type="text" id='defendent_mobile_number`+[inc_val]+`' maxLength ="8" class="form-control"  placeholder="" value="" />
          </div>
        <div class="form-group">
            <label>Defendent Insurance</label> 
              <select id='defendent_insurance`+[inc_val]+`' value="">
              <option  value="">Select Your Insurance</option>
              </select>
          </div>
        </div>
       </div>
      `);
      var counter = 0;
     $('select#defendent_insurance'+[inc_val]).on('click',function (event) {
        console.log('defendent_insurance');
        counter++;
         var data_defendent =   JSON.parse(localStorage.getItem('data_defendent')); 
         if(counter<2)
          $.each(data_defendent, function (index, value) {
              // APPEND OR INSERT DATA TO SELECT ELEMENT.
              $('select#defendent_insurance'+[inc_val]).append('<option value="' + value.name + '">' + value.name + '</option>');
             
          });

  });
  var appenddata = 	[];
  var a = [];
   $('select#defendent_insurance'+[inc_val]).change(function () {
    var defendent_insurance = $( "select#defendent_insurance"+[inc_val] ).val() || [];
    localStorage.setItem("defendent_insurance"+[inc_val], defendent_insurance)

  });
        $('#defendent_name'+[inc_val]).on('change',function (e) {
       var defendent_name = $( "#defendent_name"+[inc_val] ).val() || [];
       console.log('defendent_name'+[inc_val],defendent_name);
       localStorage.setItem("defendent_name"+[inc_val], defendent_name)
      
      });
      $('#defendent_address'+[inc_val]).on('change',function (e) {
        var defendent_address = $( "#defendent_address"+[inc_val] ).val() || [];
        console.log('defendent_address'+[inc_val],defendent_address);
        localStorage.setItem("defendent_address"+[inc_val], defendent_address)
      
       });
       $('#defendent_vehicle_no'+[inc_val]).on('change',function (e) {
        var defendent_vehicle_no = $( "#defendent_vehicle_no"+[inc_val] ).val() || [];
        console.log('defendent_vehicle_no'+[inc_val],defendent_vehicle_no);
        localStorage.setItem("defendent_vehicle_no"+[inc_val], defendent_vehicle_no)
      
       });
       $('#defendent_nric'+[inc_val]).on('change',function (e) {
        var defendent_nric = $( "#defendent_nric"+[inc_val] ).val() || [];
        console.log('defendent_nric'+[inc_val],defendent_nric);
        localStorage.setItem("defendent_nric"+[inc_val], defendent_nric)
      
       });
       $('#defendent_mobile_number'+[inc_val]).on('change',function (e) {
        var defendent_mobile_number = $( "#defendent_mobile_number"+[inc_val] ).val() || [];
        console.log('defendent_mobile_number'+[inc_val],defendent_mobile_number);
        localStorage.setItem("defendent_mobile_number"+[inc_val], defendent_mobile_number)
       });
    
  }
};


  /****************Addplaintiff details***********/
   
  // addfiles = _ => {
  //   let initialState_plaintiff = {
  //       plaintiff : JSON.parse(localStorage.getItem('plaintiff'))
  //      }
       
  //   const { plaintiff } = initialState_plaintiff
  //   console.log('plaintiff_list',plaintiff);
  //   fetch(API_BASE_URL+`/files/add?plaintiff_name=${plaintiff.plaintiff_name}&minor=1&plaintiff_ic_number=${plaintiff.plaintiff_ic_number}&plaintiff_mobile_number=${plaintiff.plaintiff_mobile_number}&plaintiff_occupation=${plaintiff.plaintiff_occupation}&plaintiff_dob=${plaintiff.plaintiff_dob}&plaintiff_address=${plaintiff.plaintiff_address}&plaintiff_telephone_number=${plaintiff.plaintiff_telephone_number}&referral_by=${plaintiff.referral_by}&accident_date=${plaintiff.accident_date}&accident_time=${plaintiff.accident_time}&accident_type=${plaintiff.accident_type}&accident_location=${plaintiff.accident_location}&plaintiff_position=${plaintiff.plaintiff_position}&time_bar=${plaintiff.time_bar}&p_type=${plaintiff.p_type}&case_file_number=${plaintiff.case_file_number}&plaintiff_vehicle_number=${plaintiff.plaintiff_vehicle_number}&vehicle_in_front=${plaintiff.vehicle_in_front}&vehicle_in_back=${plaintiff.vehicle_in_back}`)
  //   .then(this.getdefendents)
  //   .catch( err => console.log(err))
  //   }
  
  render() {
	  const { defendents, defendent } = this.state
    console.log('map',defendents);
    var p_type = localStorage.getItem('p_type');
    var inc = 0;
    return (	
		<div className="wrapper"> 
     	  <Header />
	      <MenuSidebar />		
       	<div className="content">	
		<div className="content-wrapper">
			<div className="content-body">
			<div className="form-wrapper">
			 <div className="title">
				<h4>DEFENDENT DETAILS</h4>
             </div>
			<div className="hospital-details-wrap"> 
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Defendent Name</label>
					<input type="text" className="form-control" placeholder="" value={defendent.defendent_name}
            onChange={e => this.setState({ defendent: {...defendent, defendent_name:e.target.value}})} />
				</div>
				<div className="form-group">
					<label>Defendent Address</label>
					<input type="text" className="form-control"  placeholder="" value={defendent.defendent_address}
            onChange={e => this.setState({ defendent: {...defendent, defendent_address:e.target.value}})} />
				</div>
				
				<div className="form-group">
					<label>Defendent Vehicle Number</label>
					<input type="text" className="form-control" placeholder="" value={defendent.defendent_vehicle_no}
            onChange={e => this.setState({ defendent: {...defendent, defendent_vehicle_no:e.target.value}})} />
				</div>
                
			</div>
			<div className="form-right">
				<div className="form-group">
					<label>Defendent NRIC</label>
					<input type="text" className="form-control"  placeholder="" value={defendent.defendent_nric}
            onChange={e => this.setState({ defendent: {...defendent, defendent_nric:e.target.value}})} />
				</div>
                <div className="form-group">
					<label>Defendent Mobile Number</label>
					<input type="text" maxLength ="8" className="form-control"  placeholder="" value={defendent.defendent_mobile_number}
            onChange={e => this.setState({ defendent: {...defendent, defendent_mobile_number:e.target.value}})} />
				</div>
				<div className="form-group">
					<label>Defendent Insurance</label>
          <select value={defendent.defendent_insurance}
                    onChange={e => this.setState({ defendent: {...defendent, defendent_insurance:e.target.value}})}
                    
                    >
                    {this.state.defendents.map((insurances) => <option key={insurances.insurance_id}   value={insurances.name} >{insurances.name}</option>)  }
                    </select>
				
				</div>
               
			</div>
			</div>
		</div>	
      <div className="addmore-row">	
            <Link className="addmore-btn" onClick={this.adddefendentChild.bind(this,inc)} > Add another </Link>
		</div>
    <div className="btn-group next-back-group">	
    { p_type === "personal_injury" && 
         <>
       <Link className="back" to={"/files/addHospitaldetails"}> <i class="arrow icon"></i></Link>
      </> }
      { p_type === "personal_damage" && 
        <>
        <Link className="back" to={"/files/add"}> <i class="arrow icon"></i></Link>
        </> }
            <Link className="next" to={"/files/addClaimdetails"} onClick={this.handleFormSubmit} > <i class="arrow icon"></i></Link>
			</div>
      </div>
			</div>	
		</div>	
	</div>
		 	
    </div>
    );
  }
}

export default Defendentdetails;