import React, { Component } from 'react';
import { Link } from "react-router-dom";
import  Header from '../Layouts/Header';
import  onSubmitMessage from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { API_BASE_URL } from '../config/api';
const axios = require("axios");


class EditDocuments extends Component {
	
	constructor(props)
	{
		super(props);	
		this.state = {
			files: [],
			searchfiles: [],
			deleteRecord: "",
			query : "",
			mailmsg: {},
		};	
		
    }

	componentDidMount(){
	 this.getfiles() 
	 
	}
	
	getfiles = _ => {
		fetch(API_BASE_URL+'/files/documents')
		.then(response => response.json())
		.then(response => this.setState({ files: response.data}))
		.catch(err => console.log(err))
		
	  }
	  
	
  handleChangeDelete = e => {
    e.preventDefault();

    this.setState({
      deleteRecord: e.target.name
	});
	console.log('datadocuments',e.target.name);
    var Formdata = {
	  /*deleteRecord: this.state.deleteRecord*/
	  deleteRecord: e.target.name
    };
	
	axios.post(API_BASE_URL+'/files/delete_folder_doc', { Formdata }).then(response => {
      console.log(response.data);
      console.log(response);
	});
	axios.post(API_BASE_URL+'/files/delete_images', { Formdata }).then(response => {
      console.log(response.data);
      console.log(response);
    });
    axios.post(API_BASE_URL+'/files/delete_documents', { Formdata }).then(response => {
      console.log(response.data);
      console.log(response);
	 this.getfiles();
	 //window.location.reload();
    });
  };
 
	renderplaintiff = ({ document_id,document_name,amount,description,upload_files}) => <><tr key={document_id}><td>{document_name} </td> <td>{amount} </td> <td>{description} </td> {/*<td>{upload_files} </td>*/} <td> 
					  <div className="action">	
                     <Link className="btn edit-btn" to={"/files/edit_documents/" + document_id}> Edit</Link> 
					  <button className="btn delete-btn" name={document_id} onClick={this.handleChangeDelete}>Delete</button> 
					  </div>
					  </td></tr></>
	
	
  render() {
	  const { files} = this.state

	  console.log('map',files);
    return (	
		<div className="wrapper"> 
     	  <Header  />
	      <MenuSidebar  />		
       	<div className="content">	
		<div className="content-wrapper">
			<div className="content-body">
			<div className="form-wrapper">			
			 <div className="title">
				<h4>EDIT UPLOAD DOCUMENTS</h4>
             </div>
			{(files !='' && typeof files != 'undefined')?<table><tr><th width="500">Document Name</th><th> Amount</th><th> Description</th>{/*<th> uploaded files</th>*/}<th>Actions</th></tr>{files.map(this.renderplaintiff)}</table>:<div>No data found.</div>}
		
	     	</div>
			</div>	
		</div>	
	</div>
		 	
    </div>
    );
  }
}

export default EditDocuments;