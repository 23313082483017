import React, { Component } from 'react';
import { Link } from "react-router-dom";
import  Header from '../Layouts/Header';
import  onSubmitMessage from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { API_BASE_URL } from '../config/api';
const axios = require("axios");



class files extends Component {
	
	constructor(props)
	{
		super(props);	
		this.state = {
			files: [],
			searchfiles: [],
			deleteRecord: "",
			query : "",
			file: {
			  plaintiff_name:'aaa',
				minor:'1',
				plaintiff_ic_number:'222',
				plaintiff_mobile_number:'asasa',
				plaintiff_occupation:'sss',
				plaintiff_dob:'19/10/2020',
				plaintiff_address:'xxx',
				plaintiff_telephone_number:'222',
				referral_by:'xxx',
				accident_date:'12/09/2021',
				accident_time:'19:00',
				accident_type:'sss',
				accident_location:'ssss',
				plaintiff_position:'sss',
				time_bar:'19:00',
				p_type:'1',
				case_file_number:'2222',
				plaintiff_vehicle_number:'111',
				vehicle_in_front:'sss',
				vehicle_in_back:'sss'
			  			  
			}
			
		};	
		this.onSubmitMessage = this.onSubmitMessage.bind(this);
    }
	
	onSubmitMessage(query) {
		this.setState({ query: query });
		 return axios.get(API_BASE_URL+`/files/search?name=${query}` )
		.then(response => this.setState({ files: response.data}))
		console.log(files);
		
	  }

	 
	  

	componentDidMount(){
	 this.getfiles() 
	 
	}
	
	// handlesearch(){
	// 	var search = {fetchSearchResults}
	// 	console.log('datasearch',search);
	//    }

	getfiles = _ => {
		fetch(API_BASE_URL+'/files')
		.then(response => response.json())
		.then(response => this.setState({ files: response.data}))
		.catch(err => console.log(err))
	  }
	
	
  handleChangeDelete = e => {
    e.preventDefault();

    this.setState({
      deleteRecord: e.target.name
	});
	console.log('data',e.target.name);
    var Formdata = {
	  /*deleteRecord: this.state.deleteRecord*/
	  deleteRecord: e.target.name
    };

    axios.post(API_BASE_URL+'/files/delete', { Formdata }).then(response => {
      console.log(response.data);
      console.log(response);
	 this.getfiles();
	 //window.location.reload();
    });
  };
 
	renderplaintiff = ({ plaintiff_id,plaintiff_name,plaintiff_ic_number,plaintiff_mobile_number,plaintiff_occupation,plaintiff_address,accident_location,plaintiff_position,case_file_number,plaintiff_vehicle_number}) => <><tr key={plaintiff_id}><td>{plaintiff_name} </td> <td>{plaintiff_ic_number} </td> <td>{plaintiff_mobile_number} </td> <td>{plaintiff_occupation} </td> <td>{plaintiff_address}</td> <td>{accident_location}</td><td>{plaintiff_position}</td><td>{case_file_number}</td><td>{plaintiff_vehicle_number}</td><td> 
					  <div className="action">	
                     <Link className="btn edit-btn" to={"/files/edit/" + plaintiff_id}> Edit</Link> 
					  <button className="btn delete-btn" name={plaintiff_id} onClick={this.handleChangeDelete}>Delete</button> 
					  </div>
					  </td></tr></>
	
	addfiles = _ => {
    const { plaintiff } = this.state
    fetch(API_BASE_URL+`/files/add?plaintiff_name =${plaintiff.plaintiff_name}&minor =1&plaintiff_ic_number=${plaintiff.plaintiff_ic_number}&plaintiff_mobile_number=${plaintiff.plaintiff_mobile_number}&plaintiff_occupation=${plaintiff.plaintiff_occupation}&plaintiff_dob=${plaintiff.plaintiff_dob}&plaintiff_address=${plaintiff.plaintiff_address}&plaintiff_telephone_number=${plaintiff.plaintiff_telephone_number}&referral_by=${plaintiff.referral_by}&accident_date=${plaintiff.accident_date}&accident_time=${plaintiff.accident_time}&accident_type=${plaintiff.accident_type}&accident_location=${plaintiff.accident_location}&plaintiff_position=${plaintiff.plaintiff_position}&time_bar=${plaintiff.time_bar}&p_type=${plaintiff.p_type}&case_file_number=${plaintiff.case_file_number}&plaintiff_vehicle_number=${plaintiff.plaintiff_vehicle_number}&vehicle_in_front=${plaintiff.vehicle_in_front}&vehicle_in_back=${plaintiff.vehicle_in_back}`)
    .then(this.getfiles)
    .catch( err => console.log(err))
	}
	

	
	
  render() {
	  const { files, plaintiff ,filessearch} = this.state

	  console.log('map',files);
    return (	
		<div className="wrapper"> 
     	  <Header  onSubmitMessage={this.onSubmitMessage}  />
	      <MenuSidebar  />		
       	<div className="content">	
		<div className="content-wrapper">
			<div className="content-body">
			<div className="form-wrapper">			
			 <div className="title">
				<h4>Files Management System</h4>
				<Link className="add-btn" to={"/files/add"}> Add</Link>
             </div>
			{(files !='' && typeof files != 'undefined')?<table><tr><th width="140">plaintiff Name</th><th> Ic Number</th><th> Mobile Number</th><th> Occupation</th><th>Address</th><th>Accident Location</th><th>Plaintiff Position</th><th>Case File Number</th><th>Plaintiff Vehicle No</th><th>Actions</th></tr>{files.map(this.renderplaintiff)}</table>:<div>No data found.</div>}
		
	     	</div>
			</div>	
		</div>	
	</div>
		 	
    </div>
    );
  }
}

export default files;