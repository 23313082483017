import React, { Component } from 'react';
import { Link } from "react-router-dom";
import  Header from '../Layouts/Header';
import { API_BASE_URL } from '../config/api';
import  onSubmitMessage from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
const axios = require("axios");


class EditDocuments extends Component {
	
	constructor(props)
	{
		super(props);	
		this.state = {
			files: [],
			searchfiles: [],
			deleteRecord: "",
			statusRecord: "",
			query : "",
		};	
		
    }

	componentDidMount(){
	 this.getfiles() 
	 
	}
	
	getfiles = _ => {
		fetch(API_BASE_URL+'/users')
		.then(response => response.json())
		.then(response => this.setState({ files: response.data}))
		.catch(err => console.log(err))
		
	  }


	  handleChangestatus = e => {
		e.preventDefault();
		this.setState({
			statusRecord: e.target.name
		});
		console.log('datausers',e.target.name);
		var Formdata = {
			statusRecord: e.target.name
		};
		alert('Are you sure want to change  status?');
		axios.post(API_BASE_URL+'/users/action', { Formdata }).then(response => {
		  console.log(response.data);
		  console.log(response);
		 this.getfiles();
		 //window.location.reload();
		});
	  };
	  
	
  handleChangeDelete = e => {
    e.preventDefault();

    this.setState({
      deleteRecord: e.target.name
	});
	console.log('datausers',e.target.name);
    var Formdata = {
	  /*deleteRecord: this.state.deleteRecord*/
	  deleteRecord: e.target.name
    };
	
    axios.post(API_BASE_URL+'/users/delete', { Formdata }).then(response => {
      console.log(response.data);
      console.log(response);
	 this.getfiles();
	 //window.location.reload();
    });
  };
 
renderusers = ({ user_id,name,address,email,status,department,password}) => <><tr key={user_id}><td>{name} </td> <td>{address} </td> <td>{email} </td> <td>{department} </td><td><button className={"btn delete-btn " + status} name={user_id} onClick={this.handleChangestatus}>{status}</button> </td> <td> 
					  <div className="action">	
                     <Link className="btn edit-btn" to={"/users/edit/" + user_id}> Edit</Link> 
					  <button className="btn delete-btn" name={user_id} onClick={this.handleChangeDelete}>Delete</button> 
					  </div>
					  </td></tr></>
	
	
  render() {
	  const { files} = this.state

	  console.log('map',files);
    return (	
		<div className="wrapper"> 
     	  <Header  />
	      <MenuSidebar  />		
       	<div className="content">	
		<div className="content-wrapper">
			<div className="content-body">
			<div className="form-wrapper">			
			 <div className="title">
				<h4>USERS LIST</h4>
				<Link className="add-btn" to={"/users/add"}> Add</Link>
             </div>
			{(files !='' && typeof files != 'undefined')?<table><tr><th width="140">User Name</th><th> Address</th><th> Email</th><th> Department</th><th> Status</th><th>Actions</th></tr>{files.map(this.renderusers)}</table>:<div>No data found.</div>}
		
	     	</div>
			</div>	
		</div>	
	</div>
		 	
    </div>
    );
  }
}

export default EditDocuments;