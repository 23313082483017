import React, { Component } from 'react';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { Link } from "react-router-dom";
import { API_BASE_URL } from '../config/api';
//import Validator from 'react-forms-validator';
//import DateTimePicker from 'react-datetime-picker';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
 

const axios = require("axios");

class AddPlaintiff extends React.Component {
	
	constructor(props)
	{
		super(props);	
		// var date = new Date(),
		// mnth = ("0" + (date.getMonth() + 1)).slice(-2),
		// day = ("0" + date.getDate()).slice(-2);
		// var time_bar =  [day,mnth, date.getFullYear()+3,].join("/");
		this.state = {
			plaintiffs: [],
			deleteRecord: "",
			case_file_number: 1,
			
			plaintiff: {
				plaintiff_name:'',
				minor:'',
				plaintiff_ic_number:'',
				plaintiff_mobile_number:'',
				plaintiff_occupation:'',
				plaintiff_dob:'',
				plaintiff_address:'',
				plaintiff_telephone_number:'',
				referral_by:'',
				accident_date:'',
				accident_time:'',
				accident_type:'',
				accident_location:'',
				plaintiff_position:'',
				time_bar:'',
				p_type:'',
				case_file_number: 1,
				plaintiff_vehicle_number:'',
				vehicle_in_front:'',
				vehicle_in_back:''
			
			  		  
			},
			errors: {},
			plaintiff_dob:'',
			accident_date:'',
			accident_time:'',
			time_bar:'',
			disabled : false
		    

		};

	
		
	}

    handleValidation(){
		let fields = this.state.plaintiff;
		let errors = {};
		let formIsValid = true;
		var p_type= this.state.p_type;
 
		if(!fields["plaintiff_name"]){
		   formIsValid = false;
		   errors["plaintiff_name"] = "This filed is required";
		}

		if(!p_type){
			formIsValid = false;
			errors["p_type"] = "This filed is required";
		 }
 
	   this.setState({errors: errors});
	   return formIsValid;
   }
 
   handleChange = (plaintiff_name,e) =>{
	   let fields = this.state.plaintiff;
	   fields[plaintiff_name] = e.target.value;
	   var p_type= this.state.p_type;
	   //fields[p_type] =  p_type;
	   this.setState({fields});
   }

   onChangeplaintiffdate = (plaintiff_dob) => { 
	 this.setState({ plaintiff_dob })
	var plaintiff_dob =  this.convertdob(plaintiff_dob);
	 localStorage.setItem('plaintiff_dob', plaintiff_dob)
	}
   onChangeaccidentdate = (accident_date) => {  
	   this.setState({ accident_date })
	   var accident_date =  this.convert(accident_date);
	   localStorage.setItem('accident_date', accident_date) 
	
	}
   onChangeaccidenttime = (accident_time) => { 
	console.log('Timeee',accident_time)
	this.setState({ accident_time })
	var accident_time =  this.convert2(accident_time);
	localStorage.setItem('accident_time', accident_time)
}
// 	onChangetime = (time_bar) => { 
// 		console.log('time_bar',time_bar)
// 	this.setState({ time_bar })
// 	var time_bar =  this.convert2(time_bar);
// 	localStorage.setItem('time_bar', time_bar)
// }
convert(str) {
	var date = new Date(str),
	  mnth = ("0" + (date.getMonth() + 1)).slice(-2),
	  day = ("0" + date.getDate()).slice(-2);
	  var hours = date.getHours();
var minutes = date.getMinutes();
minutes = minutes < 10 ? '0' + minutes : minutes;
var strTime = hours + ':' + minutes;
console.log('aaffff', [day,mnth, date.getFullYear()+3].join("/"));
this.setState({
	time_bar: [day,mnth, date.getFullYear()+3].join("/")
	});
	var time_bar = [date.getFullYear()+3, mnth, day].join("-");
     localStorage.setItem('time_bar', time_bar);
	return [date.getFullYear(), mnth, day].join("/");

}
convertdob(str) {
	var date = new Date(str),
	  mnth = ("0" + (date.getMonth() + 1)).slice(-2),
	  day = ("0" + date.getDate()).slice(-2);
	  var hours = date.getHours();
var minutes = date.getMinutes();
minutes = minutes < 10 ? '0' + minutes : minutes;
var strTime = hours + ':' + minutes;
	return [date.getFullYear(), mnth, day].join("-");

}

convert2(str) {
	var date = new Date(str),
	  mnth = ("0" + (date.getMonth() + 1)).slice(-2),
	  day = ("0" + date.getDate()).slice(-2);
	  var hours = date.getHours();
var minutes = date.getMinutes();
minutes = minutes < 10 ? '0' + minutes : minutes;
var seconds = ("0" + date.getSeconds()).slice(-2);
var strTime = hours + ':' + minutes + ':' + seconds;
return ([date.getFullYear(), mnth, day].join("-") +' ' + strTime);
	

}



onValueChangetype = (event) => {
	if(event.target.value != ''){
		var accident_type = event.target.value;
	}
localStorage.setItem('accident_type', accident_type);

this.setState({
	accident_type: event.target.value
	});
	
  }


  onValueChangeposition = (event) => {
	if(event.target.value != ''){
		var plaintiff_position = event.target.value;
	}
localStorage.setItem('plaintiff_position', plaintiff_position);

this.setState({
	plaintiff_position: event.target.value
	});
	
  }

	onValueChange = (event) => {
		//this.props.onChange(this.props.myValue)
		console.log('P_typppeeeee',event.target.value);
		if(event.target.value != ''){
			var p_type = event.target.value;
		}
	localStorage.setItem('p_type', p_type);

	this.setState({
		p_type: event.target.value
		});
        
	  }

	handleClickSelecet = (event) => {
		//var minor = event.target.value;
		///console.log('bef',this.state.plaintiff);
			if(this.refs.complete.checked == true){
                var minor = 1;
			}else{
				var minor = 0;
			}
			
		localStorage.setItem('minor', minor);
			
		
	}

	
	handleFormSubmit = (e) => {
		e.preventDefault();
		if(this.handleValidation()){
			console.log('validation successful')
		const { plaintiff } = this.state
        localStorage.setItem('plaintiff', JSON.stringify(plaintiff));
		this.addPlaintiffs()
	    }else{
		console.log('validation failed')
	   }
	  };

	componentDidMount(){
		this.setState(prevState => {
		   return {case_file_number:  prevState.case_file_number + 1}
		 });
		 
		 this.getlastid()

		}

		getlastid = _ => {
			   fetch(API_BASE_URL+'/files/getlastrecord')
			   .then(response => response.json())
			   .then((response) => { 
				   
				const case_file_number_id = ((response.data[0].plaintiff_id)+1) ;
                console.log('ggggg',case_file_number_id)
				localStorage.setItem('case_file_number_id', case_file_number_id)
			   
			 } )}

	//    getPlaintiffs = _ => {
	//    fetch(API_BASE_URL+'/files')
	//    .then(response => response.json())
	//    .then(response => this.setState({ plaintiffs: response.data}))
	//    .catch(err => console.log(err))
	//  }
	 
	 handleChangeDelete = e => {
	   this.setState({
		 deleteRecord: e.target.name
	   });
	 };
	 handleSubmit = e => {
	   e.preventDefault();
   
	   var Formdata = {
		 deleteRecord: this.state.deleteRecord
	   };
   
	   axios.post(API_BASE_URL+'/files/delete', { Formdata }).then(response => {
		 console.log(response.data);
		 console.log(response);
		this.getPlaintiffs();
		 
	   });
	 };
	 
	   addPlaintiffs = _ => {
		let initialState_plaintiff = {
			plaintiff : JSON.parse(localStorage.getItem('plaintiff'))
		   }
		   const { plaintiff } = initialState_plaintiff
		   var minor = localStorage.getItem('minor');
		   var p_type = localStorage.getItem('p_type');
		   var get_case_number_next = localStorage.getItem('nmbbrrr_case');
		   var plaintiff_dob = localStorage.getItem('plaintiff_dob');
		   var accident_date = localStorage.getItem('accident_date');
		   var accident_time = localStorage.getItem('accident_time');
		   var time_bar = localStorage.getItem('time_bar');
		   var accident_type = plaintiff.accident_type;
		   var plaintiff_position = localStorage.getItem('plaintiff_position');
		   var user_id = localStorage.getItem('user_login_id');
		   
	   fetch(API_BASE_URL+`/files/add?plaintiff_name=${plaintiff.plaintiff_name}&minor=${minor}&plaintiff_ic_number=${plaintiff.plaintiff_ic_number}&plaintiff_mobile_number=${plaintiff.plaintiff_mobile_number}&plaintiff_occupation=${plaintiff.plaintiff_occupation}&plaintiff_dob=${plaintiff_dob}&plaintiff_address=${plaintiff.plaintiff_address}&plaintiff_telephone_number=${plaintiff.plaintiff_telephone_number}&referral_by=${plaintiff.referral_by}&accident_date=${accident_date}&accident_time=${plaintiff.accident_time}&accident_type=${accident_type}&accident_location=${plaintiff.accident_location}&plaintiff_position=${plaintiff_position}&time_bar=${time_bar}&p_type=${p_type}&case_file_number=${get_case_number_next}&plaintiff_vehicle_number=${plaintiff.plaintiff_vehicle_number}&vehicle_in_front=${plaintiff.vehicle_in_front}&vehicle_in_back=${plaintiff.vehicle_in_back}&user_id=${user_id}`)
	   .then(response => response.json())
	  .then(response => {
		var my_id= response.insertId;
		console.log('iddd',my_id)
		localStorage.setItem('my_token', my_id)
	  })
		 .catch( err => console.log(err))
		 if(p_type === 'personal_injury'){
			window.location.href = '/files/addHospitaldetails';
		 }else{
			window.location.href = '/files/addDefendentdetails';
		 }
	
	   }

	  
  render() {
	const { plaintiffs, plaintiff} = this.state
	const plaintiff_dob = this.state.plaintiff_dob;
	//console.log('chhhhlllllllllldattteeeeeee',plaintiff_dob);
	const accident_date = this.state.accident_date;
	const accident_time = this.state.accident_time;
	const time_bar = this.state.time_bar;
	let errors = this.state.errors;
	console.log('map',plaintiffs);
	var minor = localStorage.getItem('minor');
	let d = new Date();
		 console.log('checkiinngngdateee',d.getFullYear());
		 var case_file_number = 'CLLC-'+d.getFullYear()+'-'+'0';
		 var num = localStorage.getItem('case_file_number_id');
		 var case_number = case_file_number+num ;
		 console.log('nmbbrrr',case_number);
		 localStorage.setItem('nmbbrrr_case', case_number)
		 var get_case_number_next = localStorage.getItem('nmbbrrr_case');
	//var p_type = this.state;
    return (

		<div className="wrapper"> 
 
	  <Header />
	  <MenuSidebar />		
	  
	
	<div className="content">	
		<div className="content-wrapper">
			<div className="content-body">
			<div className="form-wrapper">
			<div className="title">
				<h4>PLAINTIFF DETAILS</h4>
			</div>
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Plaintiff Name</label>  <div className="checkbox-right"><span>Minor</span> <input name="minor"  onChange={this.handleClickSelecet} ref="complete" type="checkbox" placeholder="" /> </div>
					<input type="text" className="form-control" onChange={this.handleChange.bind(this,"plaintiff_name")} placeholder="" value={plaintiff.plaintiff_name}  />

                 <span style={{color: "red"}}>{this.state.errors["plaintiff_name"]}</span>
				</div>
				
				<div className="form-group">
					<label>Plaintiff IC Number</label>
					<input type="text" maxLength ="8" className="form-control" placeholder="" value={plaintiff.plaintiff_ic_number}
            onChange={e => this.setState({ plaintiff: {...plaintiff, plaintiff_ic_number:e.target.value}})} />
				</div>
				<div className="form-group">
					<label>Plaintiff Mobile Number</label>
					<input type="text" maxLength ="8" className="form-control" placeholder="" value={plaintiff.plaintiff_mobile_number}
            onChange={e => this.setState({ plaintiff: {...plaintiff, plaintiff_mobile_number:e.target.value}})} />
				</div>
				<div className="form-group">
					<label>Occupation</label>
					<input type="text" className="form-control"  placeholder="" value={plaintiff.plaintiff_occupation}
            onChange={e => this.setState({ plaintiff: {...plaintiff, plaintiff_occupation:e.target.value}})} />
				</div>
				
			</div>
			<div className="form-right">
			<div className="form-group">
	            <label>Plaintiff D.O.B</label>
				<DatePicker
				type ='date'
				selected={plaintiff_dob}
				//value={plaintiff_dob}
				onChange={this.onChangeplaintiffdate}
				dateFormat="dd/MM/yyyy"
                  />
			</div>		  
				<div className="form-group">
					<label>Plaintiff Address</label>
					<input type="text" className="form-control" placeholder="" value={plaintiff.plaintiff_address}
            onChange={e => this.setState({ plaintiff: {...plaintiff, plaintiff_address:e.target.value}})} />
				</div>
				<div className="form-group">
					<label>Plaintiff Telephone Number</label>
					<input type="text" maxLength ="8" className="form-control" placeholder="" value={plaintiff.plaintiff_telephone_number}
            onChange={e => this.setState({ plaintiff: {...plaintiff, plaintiff_telephone_number:e.target.value}})} />
				</div>
				<div className="form-group">
					<label>Referral By</label>
					<input type="text" maxLength ="8" className="form-control" placeholder="" value={plaintiff.referral_by}
            onChange={e => this.setState({ plaintiff: {...plaintiff, referral_by:e.target.value}})} />
				</div>
			</div>
			</div>
			<div className="title">
				<h4>ACCIDENT DETAILS</h4>
			</div>
			<div className="form-row">
			<div className="form-left">
			
				<div className="form-group">
			   <label>Accident Date</label>
			    <DatePicker
				selected={accident_date}
				onChange={this.onChangeaccidentdate}
				dateFormat="dd/MM/yyyy"
                  />
				</div>
				<div className="form-group">
					<label>Accident Location</label>
					<textarea type="text" className="form-control"  placeholder="" value={plaintiff.accident_location}
            onChange={e => this.setState({ plaintiff: {...plaintiff, accident_location:e.target.value}})} />
				</div>
				
				<div className="form-group">
					<label>Accident Time</label>
					{/*<DatePicker
				selected={accident_time}
				showTimeSelect
				onChange={this.onChangeaccidenttime}
				showTimeSelect
				dateFormat="dd/MM/yyyy | HH:mm"
					/> */}
					<input type="time" className="form-control" placeholder="" value={plaintiff.accident_time}
            onChange={e => this.setState({ plaintiff: {...plaintiff, accident_time:e.target.value}})} />
				</div>   
				<div className="form-group">
					<label>Accident Type</label>
					<input type="text" className="form-control" placeholder="" value={plaintiff.accident_type}
            onChange={e => this.setState({ plaintiff: {...plaintiff, accident_type:e.target.value}})} />
				</div>
				<div className="form-group">
					<label>Plaintiff Position</label>
			   <select id="lang" onChange={this.onValueChangeposition.bind(this)} value={this.state.plaintiff_position}>
				<option value="select">Select a plaintiff Position</option>
				<option value="Driver">Driver</option>
				<option value="Passenger">Passenger</option>
				<option value="Pedestrian">Pedestrian</option>
				<option value="Rider">Rider</option>
				<option value="Pillion">Pillion</option>
				<option value="Cyclist">Cyclist</option>
				<option value="Owner">Owner</option>
				
				</select>			
				</div>
				<div className="form-group">
					<label>Time Bar</label>
					<input type="text" className="form-control" readOnly placeholder="" value={time_bar} />
				</div>	
			</div>
			
			<div className="form-right">
			<input type="hidden" className="form-control" placeholder="" value={this.state.p_type}  />
				
			{/*	<div className="radio">
              <label><input type="radio" ref="minus" value="personal_injury" checked={this.state.p_type === 'personal_injury'}  onChange={this.onValueChange}
              />
                PI (Personal Injury)
               </label>
              </div>
				<div className="radio">
					<label><input type="radio" ref="minus2" value="personal_damage" checked={ this.state.p_type === 'personal_damage'}  onChange={this.onValueChange}
					/>
					PD (Property Damage) </label> 
	          </div> */}

				<div className="form-group">
					<label>Plaintiff Position</label>
					<select id="lang" onChange={this.onValueChange.bind(this)} value={this.state.p_type}>
					<option value="select">Select a Type</option>
					<option value="personal_injury">PI (Personal Injury)</option>
					<option value="personal_damage">PD (Property Damage)</option>
					</select>
					<span style={{color: "red"}}>{this.state.errors["p_type"]}</span>
				</div>
				  {/* onChange={e => this.setState({ plaintiff: {...plaintiff, case_file_number:e.target.value}})} */}
				<div className="form-group">
					<label>Case File Number</label>
					<input type="text" className="form-control" readOnly placeholder="" value={get_case_number_next}   />
				</div>
				
				<div className="form-group">
					<label>Plaintiff Vehicle Number</label>
					<input type="text" className="form-control" placeholder="" value={plaintiff.plaintiff_vehicle_number}
            onChange={e => this.setState({ plaintiff: {...plaintiff, plaintiff_vehicle_number:e.target.value}})} />
				</div>
				<div className="form-group">
					<label>Vehicle in Front</label>
					<input type="text" className="form-control" placeholder="" value={plaintiff.vehicle_in_front}
            onChange={e => this.setState({ plaintiff: {...plaintiff, vehicle_in_front:e.target.value}})} />
				</div>
				<div className="form-group">
					<label>Vehicle in Back</label>
					<input type="text" className="form-control" placeholder="" value={plaintiff.vehicle_in_back}
            onChange={e => this.setState({ plaintiff: {...plaintiff, vehicle_in_back:e.target.value}})} />
				</div>
			</div>
			</div>

			<div className="btn-group export">	
			{/*	<input type="submit" className="btn"  onClick={this.addPlaintiffs} to={"/files/addHospitaldetails"}  value="Export" />  */}
			</div>
			<div className="btn-group next-back-group">	
			<Link className="next" onClick={this.handleFormSubmit}  > <i class="arrow icon"></i></Link>
           </div>
			</div>
			
			</div>	
		</div>	
	</div>
		 	
    </div>
    );
  }
}

export default AddPlaintiff;