import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import { API_BASE_URL } from '../config/api';

const axios = require("axios");


class EditDocs extends React.Component {
	
	fileObj = [];
	fileArray = [];
	
	constructor(props)
	{
		super(props);	
		this.state = {
			documents: [],
			images :[],
			img_files:[],
			document: {
			document_name: '',
			  amount: '',
              description: '',
              upload_file : ''
			},
			image: {
				document_id: '',
				image_id :'',
				upload_file : ''
				},
			doc:[],
			mailmsg: {},
			files: [],
			file: [],
			id: this.props.match.params.id
		 
		};	
		
    
    }
	
	componentDidMount(){
		
		this.actionload();	
		
	}

	actionload = () => {
		const { documents, document,file,image_url,image,images} = this.state

		axios.get(API_BASE_URL+'/files/document_id/' + this.state.id)
        .then((result) => {
            const data = result.data.data
            this.setState({
				document: {
                    document_name : data.document_name,
                    amount: data.amount,
                    description: data.description,
				    upload_file: data.upload_files
			  },
			  image_url :result.data.image_url
			})
		//	console.log('image_url',result.data.image_url);
		})

		axios.get(API_BASE_URL+'/files/image_id/' + this.state.id)
        .then((result) => {
			const data = result.data.data
			console.log('image_url',data);
			var img = []
			data.forEach(function(data) {

				img.push(data);
	     	})
		this.setState({
			image: {
				document_id : data.document_id,
				image_id: data.image_id,
				upload_file: data.upload_files
		  },
		  image_url :result.data.image_url
		})
		localStorage.setItem('imageedit', JSON.stringify(img));
		localStorage.setItem('urlimage', JSON.stringify(result.data.image_url));
			console.log('image_url45',result.data.image_url);
			
		})
		
   
	var img_files = JSON.parse(localStorage.getItem('imageedit'));
	var urlimage = JSON.parse(localStorage.getItem('urlimage'));
	var tags = [];
	console.log('doc[i]urlimage',urlimage);
	if( ((JSON.parse(localStorage.getItem('imageedit')) != null))){
	var doc = img_files;
	console.log('doc[i]iiimmmggg',this.state.image_url);
	//<img key={i} style={{width:300}} src={image_url+'\\'+doc[i]} />
	for (var i = 0; i <= (doc.length-1); i++) {
		var doc_files1 = doc[i]['upload_files'];
		// const  doc_files2 =   doc_files1.replace('[]', '')
		// const  doc_files3 =   doc_files2.replace('-', '[]-')
		var upload_files = doc_files1;
		tags.push( <p><a href={urlimage+'\\'+upload_files} name={doc[i]['image_id']} target="_blank" >{upload_files}</a><div className="action"><button className="btn action delete-btn" data-key={doc[i]['document_id']}  name={doc[i]['image_id']} onClick={this.handleChangeDelete}>delete</button></div> </p>
		
		);
	 }
	}
	 this.setState({
		tags : tags
	})
	
	}
	

	 handleChange = (e) => {
		this.setState({ file:  e.target.files })
		console.log('aaaaffffff',e.target.files);
		
	 }
	 
	 uploadFile = () => {
		if((this.state.document !='')){
			/*upload file in folder function*/
			const formData = new FormData();
			[].forEach.call(this.state.file, function (file) {
				formData.append('file[]', file);
			});
            console.log('dffffffffffsssssssssssssbbbbbbbbbbb',this.state.file);
          
			axios.post(API_BASE_URL+'/files/upload_file', formData).then(response => {
		/*upload file in database*/
			console.log('sttaette',this.state.document);
            const doc_data = this.state.document;
            if(response.data.img_name!=''){
              var img =response.data.img_name;
            }else{
                var img = doc_data.upload_file; 
            }
		var	description = doc_data.description;
		var	file = img ;
        var document_name = doc_data.document_name;
		var	amount = doc_data.amount;
		
        fetch(API_BASE_URL+`/files/update_documents?document_name=${document_name}&upload_files=${file}&amount=${amount}&description=${description}&document_id=${this.state.id}`)
		.then((result) => {
						console.log(result);
						//localStorage.clear();

					})
			 });
			 let mailmsg = {};
			 mailmsg["msg"] = 'Uploading....';
			 this.setState({
                mailmsg: mailmsg
			  });
			  alert('your modification Uploading....');
			
		 
		}

		window.location.reload();
	};


	handleChangeDelete = e => {
		e.preventDefault();
	
		this.setState({
		  deleteRecord: e.target.name
		});
		console.log('datafilesss',e.target.name);
		var Formdata = {
			//deleteRecord: e.target.dataset.key
		  /*deleteRecord: this.state.deleteRecord*/
		  deleteRecord: e.target.name
		};
		
		
		  console.log('Formdata',Formdata);
		  axios.post(API_BASE_URL+'/files/delete_folder_doc_imgeess', { Formdata }).then(response => {
			console.log(response.data);
			console.log(response);
		  });

		  axios.post(API_BASE_URL+'/files/delete_images_separate', { Formdata }).then(response => {
		  console.log(response.data);
		  console.log(response);
		  alert("File deleted successfully.");
			this.loadingpage();
		  
		});

		  //window.location.reload();
	  };

	  loadingpage = () => { 
		window.location.href = '/files/edit_documents/'+this.state.id;
	};
   
  render() {
	const { documents, document,file,image_url,image,images,tags} = this.state
	 
		
	return (	
		<div className="wrapper"> 
     	  <Header />
	      <MenuSidebar />		
       	<div className="content">	
		<div className="content-wrapper">
			<div className="content-body">
			<div className="form-wrapper uplod-documents-wrapper">
			<div style={{color: "green"}} className="text-danger">{this.state.mailmsg.msg}</div>
			 <div className="title">
				<h4>MODIFY UPLOAD DOCUMENTS</h4>
             </div>
			 
			 <table ><tr><th width="300"> Document Name</th><th  width="250"> Amount</th><th  width="400"> Description</th><th  width="200"  > Upload Files</th></tr>
			
				 <tr>
					<td >
					<input type="text"   placeholder="" value={document.document_name}
                     onChange={e => this.setState({ document: {...document, document_name:e.target.value}})} />
					</td>
					<td>
				     <input type="text"  name='document.amount' value={document.amount}  placeholder="Not Applicable" 
            onChange={e => this.setState({ document: {...document, amount:e.target.value}})} />
				   </td>
				   <td>
			    	<input type="text"  name='document.description' placeholder="" value={document.description}
            onChange={e => this.setState({ document: {...document, description:e.target.value}})} />
				 </td>
			    	
					<td>
					<div className="file-upload">
                     <input name='document.up_file' type="file" ref='doc1' multiple  onChange={this.handleChange} />
                    
                </div>
              
					</td>
				</tr>

	</table>
	              <div>
                     <p><b>Uploaded Files are:</b></p>
					 {tags}
                     </div>
		<div className="btn-group next-back-group">	
			<button onClick={this.uploadFile} className="upbutton"> Upload</button>
		</div>
			</div>
			</div>

		</div>	
	</div>
		 	
    </div>
    
    );
  }
}

export default EditDocs;