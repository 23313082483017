import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { API_BASE_URL } from '../config/api';


const axios = require("axios");

class Filemultipledisplay extends React.Component {
	
	constructor(props)
	{
		super(props);	
		this.state = {
			files: [],
			searchfiles: [],
			deleteRecord: "",
			query : "",
			files: {
				plaintiff_name:'',
				  minor:'',
				  plaintiff_ic_number:'',
				  plaintiff_mobile_number:'',
				  plaintiff_occupation:'',
				  plaintiff_dob:'',
				  plaintiff_address:'',
				  plaintiff_telephone_number:'',
				  referral_by:'',
				  accident_date:'',
				  accident_time:'',
				  accident_type:'',
				  accident_location:'',
				  plaintiff_position:'',
				  time_bar:'',
				  p_type:'',
				  case_file_number:'',
				  plaintiff_vehicle_number:'',
				  vehicle_in_front:'',
				  vehicle_in_back:''
							  
			  }
			
		};
		localStorage.setItem('minor', 'unchcked');	
		localStorage.setItem('keyy', '');
		localStorage.setItem('keycount',parseInt(0));
		localStorage.setItem('caselist_number',[]);
    }
	
	componentDidMount(){
	 var file_list = JSON.parse(localStorage.getItem('file_list'));
	 this.setState({
		files: file_list
		})
			
		}

	handleClickSelecet = (e, value) => {
			  
			  localStorage.setItem('minor', 'chcked')
				  var minor = e.target.value;
				  var keycount = parseInt(localStorage.getItem("keycount"));
				  console.log('ggttyyy',keycount);
				  if((e.target.checked === true)){
					//alert('hii');
					var keycount = parseInt(localStorage.getItem("keycount"));
					if(keycount>=0){
						var keycount =  parseInt(localStorage.getItem("keycount"))+1;
						localStorage.setItem('keycount',keycount);
					}
					
				  }else if(e.target.checked === false ){
					var count = parseInt(localStorage.getItem("keycount"));
					localStorage.removeItem('keyy'+[count-1]);
					localStorage.setItem('keycount',count - 1);
				  }
		      console.log('bef',minor);
				var case_file_number = JSON.parse(localStorage.getItem('file_list'));
				var caselist_number = localStorage.getItem('caselist_number');
				var doc = case_file_number;
		var case_doc_files = [];
		for (var i = 0; i <= [doc.length]-1; i++) {
				var case_files = doc[i]['case_file_number'];
				if(case_files === minor){
				case_doc_files.push(doc[i]);
				//caselist_number.push(doc[i]);
				console.log('sssss',case_doc_files);
				   this.setState({
					   files: case_doc_files
				})
				localStorage.setItem('keyy'+[i], e.target.value);
				localStorage.removeItem('allnumber');
				localStorage.setItem('caselist_number'+[i],JSON.stringify(case_doc_files));
			 }
			}

			//localStorage.setItem('caselist_number', JSON.stringify(caselist_number));
				console.log('uodateiiiiiiiiiiiiiiiiiiiiiitetet',this.state.files);
	  };

	  handleClickSelecetall = (e) => {
		localStorage.setItem('keyy', e.target.value);
		localStorage.setItem('minor', 'chcked');
			var minor = e.target.value;
        console.log('bef',minor);
		  var case_file_number = JSON.parse(localStorage.getItem('file_list'));
		  var doc = case_file_number;
			var case_doc_files = []
			for (var i = 0; i <= [doc.length]-1; i++) {
					var case_files = doc[i]['case_file_number'];
					case_doc_files.push(doc[i]);
					console.log('uodatettetet',case_doc_files);
					localStorage.setItem('allnumber',JSON.stringify(case_file_number));
					localStorage.removeItem('caselist_number');
				
				}
};


	
  render() {
	  const { files, file,tags } = this.state
	  var case_file_number = JSON.parse(localStorage.getItem('file_list'));
	  var keyy = localStorage.getItem('keyy');
	  var caselist_number =[];
	  var keycount =  parseInt(localStorage.getItem("keycount"));
	  
	  for (var i = 0; i <= keycount-1; i++){
		var number = 'caselist_number'+[i];
		console.log('number',number);
		caselist_number.push(JSON.parse(localStorage.getItem(number)));
	}
   
	  console.log('map',files);
	  console.log('tags',tags);

	  console.log('truetrue',caselist_number);
	  var checked = localStorage.getItem('minor');
    return (	
		<div className="wrapper"> 
     	  <Header />
	      <MenuSidebar />		
       	<div className="content">	
		<div className="content-wrapper">
			<div className="content-body">
			<ul>
			{/* <input name="minor" checked={keyy === 'all'?true:false} onChange={this.handleClickSelecetall} value='all' ref="complete" type="checkbox" placeholder="" /> Show All */}
			 {
			   case_file_number.map((files) => {
				return (	<li>
				<input  name="minor" onChange={e => this.handleClickSelecet(e, files.plaintiff_id)}
			  value={files.plaintiff_id}
               type="checkbox" data-key={files.plaintiff_id} ref="complete+index"   value={files.case_file_number} /> <span>STATUS OF FILE : {files.case_file_number}</span>
			   </li> )
			   })
			 }
			 </ul>
			{/* <div className="form-right">
			 <div class="form-group">
			 <div className="checkbox-right">
				{row[index].case_file_number}
				<p> File Lists are:{localStorage.getItem('keyy'+[index])}  </p>
			</div></div></div> */}
			{caselist_number.map((row, index) => (
				
			<div className="form-wrapper">
				{ checked === 'chcked' && 
				<>
				
				<p> File Lists are: {row[0]['case_file_number']}</p>
			 <table><tr><th width="20" >No</th><th >Status</th><th> Updated By</th><th> Updated On</th></tr>
			 <tr>
			 <td  width="20" >1</td>
			 <td> Opening of files</td>
			 <td> Updated By MS Jeswanth</td>
			 <td> 20 rd dec 2019 11:30</td>
			 </tr>
			 <tr>
			 <td  width="20" >2</td>
			 <td>Letter of clincs and hospitals</td>
			 <td> Updated By MS Mars</td>
			 <td> 20 rd dec 2019 06:30</td>
			 </tr>
			 <tr>
			 <td  width="20" >3</td>
			 <td> Receipts of MRS nad SPMRS</td>
			 <td> Updated By MS Mars</td>
			 <td> 23 rd jan 2020 07:30</td>
			 </tr>
			 <tr>
			 <td  width="20" >4</td>
			 <td> Letter Of Demand</td>
			 <td> Updated By MS Mars</td>
			 <td> 23 rd jan 2020 11:30</td>
			 </tr>
			 <tr>
			 <td  width="20" >5</td>
			 <td> File Passed To pre-wrint</td>
			 <td> Updated By MS Mars</td>
			 <td> 23 rd jan 2020 11:30</td>
			 </tr>
			 <tr>
			 <td  width="20" >6</td>
			 <td> Communications to Insurance Companies</td>
			 <td> Updated By MS Mars</td>
			 <td> 24 th jan 2020 11:30</td>
			 </tr>
			 <tr>
			 <td  width="20" >7</td>
			 <td> Notice for PI to Insurance Companies</td>
			 <td> Updated By MS Mars</td>
			 <td> 24 th jan 2020 11:30</td>
			 </tr>
			 <tr>
			 <td  width="20" >8</td>
			 <td>  Notice for PD to Insurance Companies</td>
			 <td> Updated By MS Jeswanth</td>
			 <td> 25 th jan 2020 11:30</td>
			 </tr>
			 <tr>
			 <td  width="20" >9</td>
			 <td> Issues Of Writ</td>
			 <td> Updated By MS Jeswanth</td>
			 <td> 25 th feb 2020 11:30</td>
			 </tr>
			 <tr>
			 <td  width="20" >10</td>
			 <td> Servies of Writ</td>
			 <td> Updated By MS Jeswanth</td>
			 <td> 25 th jan 2020 11:30</td>
			 </tr>
			 <tr>
			 <td  width="20" >11</td>
			 <td> File Memoranadom of Service (MOS)</td>
			 <td> Updated By MS Jeswanth</td>
			 <td> 23 rd jan 2020 11:30</td>
			 </tr>
			 <tr>
			 <td  width="20" >12</td>
			 <td> File Memoranadom of Apperance (MOA)</td>
			 <td> Updated By MS Jeswanth</td>
			 <td> 23 rd jan 2020 11:30</td>
			 </tr>
			 <tr>
			 <td  width="20" >13</td>
			 <td> Court Dispute Resolution</td>
			 <td> To Be Updated By MS Durga /Mr Emily</td>
			 <td> 23 rd jan 2020 11:30</td>
			 </tr>
			 <tr>
			 <td  width="20" >14</td>
			 <td> CDR Result</td>
			 <td> To Be Updated By MS Durga /Mr Emily</td>
			 <td> 1 st  jan 2020 11:30</td>
			 </tr>
			 <tr>
			 <td  width="20" >15</td>
			 <td> Matter Setteled</td>
			 <td> To Be Updated By MS Durga /Mr Emily</td>
			 <td> 23 rd jan 2020 11:30</td>
			 </tr>
			 <tr>
			 <td  width="20" >16</td>
			 <td> Accounts</td>
			 <td> To Be Updated By MS Jeswanth/Mr Emily</td>
			 <td> 1 st  jan 1998 11:30</td>
			 </tr>
			 <tr>
			 <td  width="20" >17</td>
			 <td> File closure</td>
			 <td> To Be Updated By MS Jeswanth</td>
			 <td> 23 rd jan 2020 11:30</td>
			 </tr>
			 
			 </table>
			 
				</>}
			</div>
			) 
			)}
			{ checked === 'unchcked' && 
			<>
           <div>No data found.</div>
			</>}
			</div>
		</div>	
	</div>
		 	
    </div>
    );
  }
}

export default Filemultipledisplay;