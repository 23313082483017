import React, { Component } from 'react';
import { Link } from "react-router-dom";
import  Header from '../Layouts/Header';
import  onSubmitMessage from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { API_BASE_URL } from '../config/api';
const axios = require("axios");
class Forget extends Component {
	
	constructor(props)
	{
		super(props);	
		this.state = {
            users: [],
            deleteRecord: "",
            errors: {},
            errors1: {},
            mailmsg: {},
            mailmsg1: {},
            input: {},
          email:'',
               
        };
        this.handleSubmit = this.handleSubmit.bind(this);
		
    }

      handleSubmit(event) {
        event.preventDefault();
        this.validate();//validationnn
      }
    //validationnn for all pwd ,confrm passwrd and email and alll fields.......
      validate(){
    
          let input = this.state;

          let errors = {};
          let isValid = true;
          if (typeof input["email"] !== "undefined") {
    
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    
            if (!pattern.test(input["email"])) {
    
              isValid = false;
    
              errors["email"] = "Please enter valid email address.";
    
            }
    
          }
         
          if (!input["email"]) {
            isValid = false;
            errors["email"] = "Please enter your email.";
          }

          this.setState({
            errors: errors
          }); 
          console.log('sdsds',isValid);
         if( isValid ==true){
            let mailmsg = {};
           this.sendemail();
           mailmsg["msg"] = 'Sending Mail....';
              this.setState({
                mailmsg: mailmsg
              });
        }
    
      }
        sendemail = _ => {
          var email = this.state.email;
          console.log('email',email);
          let mailmsg = {};
          let mailmsg1 = {};
          axios.get(API_BASE_URL+'/login/forgot?email='+email)
          .then((result) => {
            const data = result.data
            console.log('asas',data);
            if(data == 'success'){
              mailmsg["msg"] = 'Mail Sent successfully!.check mail to reset the password...';
              this.setState({
                email:'',
                errors1: '',
                mailmsg: mailmsg
              }); 
            }else if(data == 'invalid'){
                mailmsg1["msg"] = 'No account with this email address exists.';
                this.setState({
                  errors1: '',
                  mailmsg1: mailmsg1
                }); 
            }
         })
      }
    
	
  render() {
    const { email} = this.state
    return (
        <div className="wrapper"> 
      <div className="content">	
          <div className="content-wrapper">
              <div className="content-body">
			<div className="form-wrapper">
      <div style={{color: "green"}} className="text-danger">{this.state.mailmsg.msg}</div>
      <div style={{color: "red"}} className="text-danger">{this.state.mailmsg1.msg}</div>
			
			<div className="form-row">
            <div className="form-left">
            <div className="title">
				<h4>Forgot password</h4>
			</div>
            <div class="form-group">
					<label>Email</label>
					<input type="text" name='email' id='email' onChange={e => this.setState({ email:e.target.value})} placeholder=""  className="form-control" value={email} />
                    <div style={{color: "red"}} className="text-danger">{this.state.errors.email}</div>
                    <div style={{color: "red"}} className="text-danger">{this.state.errors1.email}</div>
                </div>
                <Link  to={"/login"}> <b>Back to login</b></Link>

			<div className="btn-group export">	
				<input type="submit" onClick={this.handleSubmit} className="btn" value="Submit" />
			</div>
            </div>
			</div>
            </div>	
            </div>
            </div>
    </div>
		 	
    </div>
    );
  }
}

export default Forget;