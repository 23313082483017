import React, { Component } from 'react';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { API_BASE_URL } from '../config/api';
import { Link } from "react-router-dom";

const axios = require("axios");

class EditReferral extends React.Component {
	
	constructor(props)
	{
		super(props);	
		this.state = {
			referrals: [],
			referral: {
			  referrals_name: '',
			  referrals_company: '',
			  referrals_address: '',
			  referrals_contact: '',
			  referrals_email: '',
			  referrals_id:0			  
			},
			id: this.props.match.params.id
		};	
    }
	
	componentDidMount(){
        axios.get(API_BASE_URL+'/referrals/id/' + this.state.id)
        .then((result) => {
            const data = result.data.data
            this.setState({
              referral: {
			  referrals_name: data.referrals_name,
			  referrals_company: data.referrals_company,
			  referrals_address: data.referrals_address,
			  referrals_contact: data.referrals_contact,
			  referrals_email: data.referrals_email,
			  referrals_id: data.referrals_id			  
			}
            })
        })
    }
	
	
	editReferrals = _ => {
    const { referral } = this.state
    fetch(API_BASE_URL+`/referrals/update?referrals_name=${referral.referrals_name}&referrals_company=${referral.referrals_company}&referrals_address=${referral.referrals_address}&referrals_contact=${referral.referrals_contact}&referrals_email=${referral.referrals_email}&referrals_id=${referral.referrals_id}`)
    .then((result) => {
                    console.log(result);
				
                   window.location.href = '/referrals';
                })
    .catch( err => console.log(err))
    }
	
  render() {
	  const { referrals, referral } = this.state
	  return (
        <div className="wrapper"> 
     	<Header />
	    <MenuSidebar />		
       	<div className="content">	
		<div className="content-wrapper">
			<div className="content-body">
			 <div className="form-wrapper">
			 <div className="title">
				<h4>Edit Referral</h4>
	         </div>	  
				<div className="form-row">
			<div className="form-left">
				<div class="form-group">
					<label>Referral Name</label>
					<input type="text" className="form-control" placeholder="" value={referral.referrals_name}
            onChange={e => this.setState({ referral: {...referral, referrals_name:e.target.value}})} />
				</div>
				<div class="form-group">
					<label>Referral Company</label>
					<input type="text" className="form-control"  placeholder="" value={referral.referrals_company}
            onChange={e => this.setState({ referral: {...referral, referrals_company:e.target.value}})} />
				</div>
				<div class="form-group">
					<label>Referral Address</label>
					<input type="text" className="form-control"  placeholder="" value={referral.referrals_address}
            onChange={e => this.setState({ referral: {...referral, referrals_address:e.target.value}})} />
				</div>
			</div>
			<div className="form-right">
			    <div class="form-group">
					<label>Referral Contact</label>
					<input type="text" className="form-control" placeholder="" value={referral.referrals_contact}
            onChange={e => this.setState({ referral: {...referral, referrals_contact:e.target.value}})} />
				</div>
				<div class="form-group">
					<label>Referral Email</label>
					<input type="text" className="form-control"  placeholder="" value={referral.referrals_email}
            onChange={e => this.setState({ referral: {...referral, referrals_email:e.target.value}})} />
				</div>
			   </div>
			</div>
			<div className="btn-group export">	
				<input type="submit" className="btn" onClick={this.editReferrals} value="Update" />
			</div>
			</div>
		</div>	
		</div>	
	</div>		 	
    </div>
    );
  }
}

export default EditReferral;