import React, { Component } from 'react';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import Files from '../Files/Files';

class Home extends Component {
	
	constructor(props)
	{
		super(props);	
		this.state = {
		};
		
    }
	
  render() {
    return (
      <div className="wrapper"> 
 
	  <Header />
	  <MenuSidebar />		

	
	<div className="content">	
		<div className="content-wrapper">
			<div className="content-body">
				
			</div>	
		</div>	
	</div>
		 	
    </div>
    );
  }
}

export default Home;