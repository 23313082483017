import React, { Component } from 'react';

class Files extends Component {
	
	constructor(props)
	{
		super(props);	
		this.state = {
		};
		
    }
	
	
	
  render() {
    return (
			<div className="form-wrapper">
			<div className="title">
				<h4>PLAINTIFF DETAILS</h4>
			</div>
			<div className="form-row">
			<div className="form-left">
				<div class="form-group">
					<label>Plaintiff Name</label>  <label>Minor <input name="minor"  style={{flex: 1, padding: 10}} type="checkbox" /> </label>
					<input type="text" className="form-control" value="John Doe" />
				</div>
				<div class="form-group">
					<label>Plaintiff IC Number</label>
					<input type="text" className="form-control"  value="S1234567X" />
				</div>
				<div class="form-group">
					<label>Plaintiff Mobile Number</label>
					<input type="text" className="form-control" value="9123 4567" />
				</div>
				<div class="form-group">
					<label>Occupation</label>
					<input type="text" className="form-control" value="Construction Engineer" />
				</div>
				
			</div>
			<div className="form-right">
				<div class="form-group">
					<label>Plaintiff D.O.B</label>
					<input type="text" className="form-control"  placeholder="" />
				</div>
				<div class="form-group">
					<label>Plaintiff Address</label>
					<input type="text" className="form-control" value="60, Bukit Timah Road, S123456" />
				</div>
				<div class="form-group">
					<label>Plaintiff Telephone Number</label>
					<input type="text" className="form-control" value="9123 4567" />
				</div>
				<div class="form-group">
					<label>Referral By</label>
					<input type="text" className="form-control" value="thomas tan" />
				</div>
			</div>
			</div>
			<div className="title">
				<h4>ACCIDENT DETAILS</h4>
			</div>
			<div className="form-row">
			<div className="form-left">
				<div class="form-group">
					<label>Accident Date</label>
					<input type="text" className="form-control" value="John Doe" />
				</div>
				<div class="form-group">
					<label>Accident Location</label>
					<textarea type="text" className="form-control"  value="Along PIE towards Changi, between Exit 2 and Exit 3" />
				</div>
				<div class="form-group">
					<label>Accident Time</label>
					<input type="text" className="form-control" value="Along PIE towards Changi, between Exit 2 and Exit 3" />
				</div>
				<div class="form-group">
					<label>Accident Type</label>
					<input type="text" className="form-control" value="Along PIE towards Changi, between Exit 2 and Exit 3" />
				</div>
				<div class="form-group">
					<label>Plaintiff Position</label>
					<input type="text" className="form-control" value="Driver" />
				</div>
				<div class="form-group">
					<label>Time Bar</label>
					<input type="text" className="form-control" value="18/03/2020" />
				</div>
			</div>
			<div className="form-right">
				<div class="form-group">
					<label>PI / PD</label>
					<input type="text" className="form-control" value="9123 4567" />
				</div>
				<div class="form-group">
					<label>Case File Number</label>
					<input type="text" className="form-control"  placeholder="" />
				</div>
				
				<div class="form-group">
					<label>Plaintiff Vehicle Number</label>
					<input type="text" className="form-control" value="SDM 4567 K" />
				</div>
				<div class="form-group">
					<label>Vehicle in Front</label>
					<input type="text" className="form-control" value="SYZ 1234 A" />
				</div>
				<div class="form-group">
					<label>Vehicle in Back</label>
					<input type="text" className="form-control" value="SYZ 1234 A" />
				</div>
			</div>
			</div>

			<div className="btn-group export">	
				<input type="submit" className="btn" value="Export" />
			</div>

			</div>
    );
  }
}

export default Files;