import React, { Component } from 'react';
import { Link } from "react-router-dom";
import  Header from '../Layouts/Header';
import  onSubmitMessage from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { API_BASE_URL,API_KEY_CHAT,REGION_CHAT,APP_ID_CHAT } from '../config/api';
import { Widget , addResponseMessage ,addUserMessage } from 'react-chat-widget';
import { CometChat } from "@cometchat-pro/chat" 
import $ from 'jquery';
import 'react-chat-widget/lib/styles.css';
 
const axios = require("axios");



class UsergroupList extends Component {
	
	constructor(props)
	{
		super(props);	
		this.state = {
			id: this.props.match.params.id 
		};
        
        
    }
	componentDidMount(){

    
        var appID = APP_ID_CHAT;
        var region = REGION_CHAT;
        var appSetting = new CometChat.AppSettingsBuilder().subscribePresenceForAllUsers().setRegion(region).build();
        CometChat.init(appID, appSetting).then(
        () => {
            console.log("Initialization completed successfully");
            this.usersgrouplist();
            // You can now call login function.
        },
        error => {
            console.log("Initialization failed with error:", error);
            // Check the reason for error and take appropriate action.
        }
        );
    }
   

      back = () => {
        window.location.href = '/chat';
        
      };

      usersgrouplist = () => {

        let GUID = this.state.id;
        var limit = 100;
        var groupMemberRequest = new CometChat.GroupMembersRequestBuilder(GUID)
          .setLimit(limit)
          .build();
        
        groupMemberRequest.fetchNext().then(
          groupMembers => {
            console.log("Group Member list fetched successfully:", groupMembers);
            localStorage.setItem('group_members_list',JSON.stringify(groupMembers))
          },
          error => {
            console.log("Group Member list fetching failed with exception:", error);
          }
        );

    var group_members_list = JSON.parse(localStorage.getItem('group_members_list')) ?JSON.parse(localStorage.getItem('group_members_list')) :[];
      group_members_list.forEach(usermem => {
        if(usermem.scope == 'admin'){
          console.log("usermem", usermem.uid);
          localStorage.setItem('usermem',usermem.uid)
        }
      });
      }


      removemember = (e) => {
        e.preventDefault();
        console.log(e.target.id);
        let GUID = this.state.id;
        var UID = e.target.id;
        var usermem = localStorage.getItem('usermem');
        var user_login_id = localStorage.getItem('user_login_id');
        if(usermem == user_login_id){
        CometChat.kickGroupMember(GUID, UID).then(
        response => {
            console.log("Group member kicked successfully", response);
            alert('Group member kicked successfully')
            window.location.reload();
        },
        error => {
            console.log("Group member kicking failed with error", error);
            window.location.reload();
        }
        );
      }else{
        alert("OOPS!..Sorry...Group Member will be deleting only by the admin...");
        window.location.reload();
       }

      }
   
  render() {
    var group_members_list = JSON.parse(localStorage.getItem('group_members_list')) ?JSON.parse(localStorage.getItem('group_members_list')) :[];
   
    return (	
		<div className="wrapper"> 
     	  <Header />
	      <MenuSidebar  />		
       	<div className="content">	
		<div className="content-wrapper">
			<div className="content-body">
			<div className="form-wrapper">	
			<div className="title">
            <div id="cometchat"></div>
				<h4> Members List in group</h4>
             </div>
             <h4>Users List </h4>	
             {(group_members_list !='' )?	
          <table><tr><th width='300px'>Users Name</th><th width='300px'>Status</th><th width='300px'>Role</th><th width='100px'> Actions</th></tr>
             {group_members_list.map((group_members_list) =>
             <tr key={group_members_list.uid} ><td>{group_members_list.name}</td> <td>{group_members_list.status}</td><td>{group_members_list.scope}</td><td width='100px'>
              
               <span  id={group_members_list.uid} name={group_members_list.uid} onClick={this.removemember}>Remove Member</span> 
                 </td></tr >
             )}
             </table>:<div>No Group Member List found.......Refresh Your window to load data's..</div>}
        
	     	</div>
             <div className="btn-group export">	
				<input type="submit" className="btn" onClick={this.back} value="Back" />
			</div>
			</div>	
		</div>	
	</div>
		 	
    </div>
    );
  }
}

export default UsergroupList;