import React,  { Component , useState } from 'react';

import { API_BASE_URL } from '../config/api';
const axios = require("axios");
 
class Login extends Component {
	
	constructor(props)
	{
		super(props);	
		this.state = {
      users: [],
      errors1: {},
      mailmsg: {},
      mailmsg1: {},
      errors: {},
      user: {
          client_name:'',
          password:'',
          rememberMe:''
         
      },
      rememberMe:'',
      //id: 1
      id: ''
     };

     localStorage.setItem('login_type', 'user');
     //localStorage.setItem('rememberMe', 'no');
     this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount(){
      axios.get(API_BASE_URL+'/users/id/' + this.state.id)
        .then((result) => {
            const data = result.data.data
            if(data.remember_me == 'yes'){
            this.setState({
              user: {
                client_name:data.name,
                password:data.password,
                
               
            },
            rememberMe:data.remember_me
            })
            var remem = data.remember_me;
            console.log('rememberMe',data.remember_me);
            localStorage.setItem('rememberMe', remem);
          }else{
            this.setState({
              user: {
                client_name:'',
                password:'',
            },
            rememberMe:''
            })
            var remem = 'no';
            localStorage.setItem('rememberMe', remem);
          }
        })
      
     }

    toggleRememberMe = (event) => {
        if(this.refs.rememberMe.checked == true){
        var rememberMe = 'yes';
        this.setState({
          rememberMe: rememberMe
          })
          localStorage.setItem('rememberMe', rememberMe);
        }else{
          var rememberMe = 'no';
          this.setState({
          rememberMe:''
          })
          localStorage.setItem('rememberMe', rememberMe);
        }
     
    }

    handleSubmit(event) {
      event.preventDefault();
      this.validate();//validationnn
    }
  //validationnn for all pwd ,confrm passwrd and name.......
    validate(){
  
        let input = this.state.user;

        let errors = {};
        let isValid = true;
        if (!input["client_name"]) {
          isValid = false;
          errors["client_name"] = "Please enter  user name.";
        }
  
        if (!input["password"]) {
          isValid = false;
          errors["password"] = "Please enter  password.";
        }
       
        this.setState({
          errors: errors
        }); 
        console.log('sdsds',isValid);
       if( isValid ==true){
          this.handleLogin();
      }
  
    }

    handleLogin = _ =>{
      const { user } = this.state;
      let errors1 = {};
        let mailmsg = {};
        let mailmsg1 = {};
      console.log('login',user);
       var rememberMe =  localStorage.getItem('rememberMe');
       console.log('rememberMe',rememberMe);
      axios.get(API_BASE_URL+`/users/login?client_name=${user.client_name}&password=${user.password}&rememberMe=yes&logged_in='yes'` )
      
      .then((result) => {
        const data = result.data
        console.log('asas',data.data);
        if(data.status != ''){
          localStorage.setItem('user_login_id', data.data);
          mailmsg["login_msg"] = 'Logged in successfully..';
          this.setState({
            mailmsg: mailmsg,
            mailmsg1: ''
          });
          window.location.href = '/'; 

        }else{
          errors1["email"] = data;
          mailmsg1["msg"] = 'Your Login Name or Password is invalid';
          this.setState({
            mailmsg1: mailmsg1,
            mailmsg: ''
          }); 
        }
        })
      }

  render() {
    const { users, user} = this.state
  return (
    <div className="login-sec">
      <div style={{color: "green"}} className="text-danger">{this.state.mailmsg.login_msg}</div>
      <div style={{color: "red"}} className="text-danger">{this.state.mailmsg1.msg}</div>
    
    <div className="login-form">   
      <div className="title">
          <h4>User Login </h4>
      </div>
      <div class="form-group">
          <label>User Name</label>
          <input type="text" placeholder="Enter User Name" className="form-control" onChange={e => this.setState({ user: {...user, client_name:e.target.value}})} placeholder="" value={user.client_name} />
          <div style={{color: "red"}} className="text-danger">{this.state.errors.client_name}</div>
      </div>
      <div class="form-group">
          <label>Password</label>
          <input type="password" placeholder="Enter Password" className="form-control" onChange={e => this.setState({ user: {...user, password:e.target.value}})} placeholder="" value={user.password} autoComplete="new-password" />
          <div style={{color: "red"}} className="text-danger">{this.state.errors.password}</div>
      </div>
      {/* <div className="form-group">                
            <input type="checkbox"  id="rememberMe" ref="rememberMe" checked={this.state.rememberMe} placeholder="Remember Me" onChange={this.toggleRememberMe} />
            <label >Remember me</label>
      </div> */}
      <div className="form-group">
        <input type="button" value='Login' onClick={this.handleSubmit} />
      </div> 
      {/* <a href="#" title="Upload Docs">Forgot Username</a> or */} 
      <div className="forgot-group">
        <a href="/users/forgot" title="Forgot Password">Forgot Password?</a>
      </div>
    
    </div>     
    </div>



  );
}
}
 

 
export default Login;