import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { API_BASE_URL } from '../config/api';
const axios = require("axios");

class AddReferral extends React.Component {
	
	constructor(props)
	{
		super(props);	
		this.state = {
			referrals: [],
			referral: {
			  referrals_name: '',
			  referrals_company: '',
			  referrals_address: '',
			  referrals_contact: '',
			  referrals_email: '',
			  referrals_id:0			  
			},
		};	
    }
	
	componentDidMount(){
     this.getReferrals()
    }
	
	getReferrals = _ => {
    fetch(API_BASE_URL+'/referrals')
    .then(response => response.json())
    .then(response => this.setState({ referrals: response.data}))
    .catch(err => console.log(err))
  }
  
  handleChangeDelete = e => {
    this.setState({
      deleteRecord: e.target.name
    });
  };
  handleSubmit = e => {
    e.preventDefault();

    var Formdata = {
      deleteRecord: this.state.deleteRecord
    };

    axios.post(API_BASE_URL+'/referrals/delete', { Formdata }).then(response => {
      console.log(response.data);
      console.log(response);
	 this.getReferrals();
	});
  };
  
	addReferrals = _ => {
    const { referral } = this.state
    fetch(API_BASE_URL+`/referrals/add?referrals_name=${referral.referrals_name}&referrals_company=${referral.referrals_company}&referrals_address=${referral.referrals_address}&referrals_contact=${referral.referrals_contact}&referrals_email=${referral.referrals_email}`)
    .then(this.getReferrals)
    .catch( err => console.log(err))
	 window.location.href = '/referrals';
    }
	
  render() {
	  const { referrals, referral } = this.state
	  console.log('map',referrals);
    return (	
		<div className="wrapper"> 
     	  <Header />
	      <MenuSidebar />		
       	<div className="content">	
		<div className="content-wrapper">
			<div className="content-body">
			<div className="form-wrapper">
			 <div className="title">
				<h4>Add Referral</h4>
             </div>
			<div className="form-row">
						<div className="form-left">
				<div class="form-group">
					<label>Referral Name</label>
					<input type="text" className="form-control" placeholder="" value={referral.referrals_name}
            onChange={e => this.setState({ referral: {...referral, referrals_name:e.target.value}})} />
				</div>
				<div class="form-group">
					<label>Referral Company</label>
					<input type="text" className="form-control"  placeholder="" value={referral.referrals_company}
            onChange={e => this.setState({ referral: {...referral, referrals_company:e.target.value}})} />
				</div>
				<div class="form-group">
					<label>Referral Address</label>
					<input type="text" className="form-control"  placeholder="" value={referral.referrals_address}
            onChange={e => this.setState({ referral: {...referral, referrals_address:e.target.value}})} />
				</div>
			</div>
			<div className="form-right">
			    <div class="form-group">
					<label>Referral Contact</label>
					<input type="text" className="form-control" placeholder="" value={referral.referrals_contact}
            onChange={e => this.setState({ referral: {...referral, referrals_contact:e.target.value}})} />
				</div>
				<div class="form-group">
					<label>Referral Email</label>
					<input type="text" className="form-control"  placeholder="" value={referral.referrals_email}
            onChange={e => this.setState({ referral: {...referral, referrals_email:e.target.value}})} />
				</div>
			   </div>
			</div>
			<div className="btn-group export">	
				<input type="submit" className="btn" onClick={this.addReferrals} value="Submit" />
			</div>
			</div>
			</div>	
		</div>	
	</div>
		 	
    </div>
    );
  }
}

export default AddReferral;