import React, { Component } from 'react';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import EditAccount from './EditAccount';
import { API_BASE_URL } from '../config/api';

class Account extends Component {
	
	constructor(props)
	{
		super(props);	
		this.state = {}
			
    }
	
	
  render() {
	 return (	
		<div className="wrapper"> 
     	  <Header />
	      <MenuSidebar />		
       	<div className="content">	
		<div className="content-wrapper">
			<div className="content-body">
			<div className="form-wrapper">			
				 <div className="title">
					<h4>Update Account Details</h4>
				 </div>
			     <EditAccount />		
	     	</div>
			</div>	
		</div>	
	</div>
		 	
    </div>
    );
  }
}
export default Account;