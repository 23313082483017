import React, { Component } from 'react';
import { Link } from "react-router-dom";
import $ from 'jquery';
import axios from 'axios';
import { CometChat } from "@cometchat-pro/chat" 
import { API_BASE_URL } from '../config/api';

class Header extends Component {
	
	constructor( props ) {
		super( props );
		this.state = {
			query: [],
			results :[],
			loading: false,
			message: '',
			id: 1
		};
		this.cancel = '';
	}


	componentDidMount(){
		var logintype = localStorage.getItem('login_type');
		var login_user_id = localStorage.getItem('user_login_id');
		let urlElements = window.location.href.split('/')
		console.log('dddd',urlElements)
		if((logintype=='admin')){
	    let urlElelement = (urlElements[3])
		console.log('rrrrrr',urlElelement)
		axios.get(API_BASE_URL+'/login/id/' + this.state.id)
		  .then((result) => {
			  const data = result.data.data
			  const logged_in = data.logged_in
			  console.log('urlElelement',urlElelement)
			  if((logged_in == 'no') && (urlElelement != 'forgot') && (urlElelement != 'reset')) {
				window.location.href = '/login';
			}else if(((urlElelement == 'forgot')&& (logged_in == 'no'))){
				window.location.href = '/forgot';
			}else if(((urlElelement == 'reset')&& (logged_in == 'no'))){
				window.location.href = '/reset';
			}
		  
		  })
		}else if((logintype=='user')&&(login_user_id!='')){
			let urlElelement = (urlElements[4])
		console.log('rrrrrr',urlElelement)
			axios.get(API_BASE_URL+'/users/id/' + login_user_id)
			.then((result) => {
				const data = result.data.data
				const logged_in = data.logged_in
				console.log('urlElelement',urlElelement)
				if((logged_in == 'no') && (urlElelement != 'forgot') && (urlElelement != 'reset')) {
				  window.location.href = '/users/Login';
			  }else if(((urlElelement == 'forgot')&& (logged_in == 'no'))){
				  window.location.href = '/users/forgot';
			  }else if(((urlElelement == 'reset')&& (logged_in == 'no'))){
				  window.location.href = '/users/reset';
			  }
			})
		}else{
			window.location.href = '/page-login';
		}
	    }


	   handleClicklogout = _ => {
		
		axios.get(API_BASE_URL+`/login/logout?logged_in='no'&admin_id=${this.state.id}`)
		  .then((result) => {
			  const data = result.data
			  if(data == 'success'){
				CometChat.logout();
				console.log("logout");
				localStorage.removeItem('login_type');
				//window.location.href = '/login';
				localStorage.clear();
				window.location.href = '/page-login';
			}
		  })
			
		
	}

	handleClicklogoutuser = _ => {
		var logintype = localStorage.getItem('login_type');
		var login_user_id = localStorage.getItem('user_login_id');
		axios.get(API_BASE_URL+`/users/logout?logged_in='no'&user_id=${login_user_id}`)
		  .then((result) => {
			  const data = result.data
			  if(data == 'success'){
				CometChat.logout();
				console.log("logout");
				localStorage.removeItem('login_type');
				localStorage.removeItem('user_login_id');
				//window.location.href = '/users/Login';
				localStorage.clear();
				window.location.href = '/page-login';
			}
		  })
			
		
	}



	/**
	 * Fetch the search results and update the state with the result.
	 *
	 * @param {int} updatedPageNo Updated Page No.
	 * @param {String} query Search Query.
	 *
	 */
	fetchSearchResults = ( query ) => {
		//const pageNumber = updatedPageNo ? `&page=${updatedPageNo}` : '';
		// By default the limit of results is 20
		const searchUrl = API_BASE_URL+`/files/search?name=${query}`;
		if (this.cancel) {
			// Cancel the previous request before making a new request
			this.cancel.cancel();
		}
		// Create a new CancelToken
		this.cancel = axios.CancelToken.source();
		axios
			.get(searchUrl, {
				cancelToken: this.cancel.token,
			})
			
			.then((res) => {
				const resultNotFoundMsg = !res.data.hits.length
					? 'There are no more search results. Please try a new search.'
					: '';
				this.setState({
					results: res.data.hits,
					message: resultNotFoundMsg,
					loading: false,
				});
			})

			.then(response => response.json())
			  .then(response => this.setState({ results: response.data}))
			  
			.catch((error) => {
				if (axios.isCancel(error) || error) {
					this.setState({
						loading: false,
						message: 'Failed to fetch results.Please check network',
					});
				}
			});
	};
	
	handleOnInputChangesearch = (event) => {
		const query = event.target.value;
		if ( ! query ) {
			this.setState({ query, results: {}, message: '' } );
		} else {
			this.setState({query, loading: true, message: '' }, () => {
				this.fetchSearchResults(query);
				
				this.props.onSubmitMessage((this.state.query));
			});
		}
	};

	 refreshPage() {
		window.location.reload(false);
	  }
	

  render() {
	const { query } = this.state;
	var logintype = localStorage.getItem('login_type');
		var login_user_id = localStorage.getItem('user_login_id');
    return (
	<div className="header">	
	  <div className="navbar-wrapper">
	  
		    <div className="logo-box mobi-close">
				<a href="index.html" className="logo text-center">
					<span className="logo-lg">CENTRO LEGAL LLC</span>
					<span className="logo-sm">CLLC</span>
				</a>
			</div>	
			
			<div className="header-cnt">
			<div className="header-cnt-left">			
				<a href="javascript:void(0)" className="trigger-menu menu-icon">
                    <span className="icon_menu1"></span>
                    <span className="icon_menu2"></span>
                    <span className="icon_menu3"></span>
                </a>				
				<div className="search-box">
					<input type="text" className="form-control" id="search-input" value={query} onChange={this.handleOnInputChangesearch} placeholder="Search Case File" />
					<span className="search-icon"><i className="sc-icon icon"></i></span>
					
				</div>
				<Link className="refresh-btn" onClick={this.refreshPage}><i class="refresh-icon icon"></i></Link>
				<Link className="show-file btn" to={"/files"}>Show all Files</Link>
			</div>
			
		    {/*<div className="mobi-logo">
				<a href="index.html" className="logo text-center">
					<img src="common/images/logo-sm.png" alt="logo">
				</a>
			</div>*/}
			<div className="header-cnt-right">	
				<div className="myaccount">
				 {/*	<a href="javascript:void(0)" alt="notity"  className="clock-icon r-icon">
						<i className="ck-icon icon"></i>
					</a>	*/}	
					<a href="javascript:void(0)" alt="mail" className="alert-icon r-icon">
						<i className="a-icon icon"></i>
					</a>	
					<a href="javascript:void(0)" alt="mail" className="dropdown-toggle user-icon r-icon">
						<i className="u-icon icon"></i>
					</a>
					<ul className="user-menu">
					{ (logintype === 'admin') && 
						<>
						<li>
							<a href="/login/edit/1" title="Profile">
								<i className="profile-icon icon"></i> <span>Profile</span>
							</a>
						</li>
						</> }
						{ (logintype === 'user') && 
						<>
						<li>
							<Link to={"/users/edit/" + login_user_id} title="Profile">
								<i className="profile-icon icon"></i> <span>Profile</span>
							</Link>
						</li>
						</> }
						{/*<li>
							<a href="#" title="Color">
								<i className="color-icon icon"></i> <span>Color</span>
							</a>
						</li> */}
						{ (logintype === 'admin') && 
						<>
						<li>
							<a  onClick={this.handleClicklogout} title="Logout">
								<i className="logout-icon icon"></i> <span>Logout</span>
							</a>
						</li>
						</> }
						{ (logintype === 'user') && 
						<>
						<li>
							<a  onClick={this.handleClicklogoutuser} title="Logout">
								<i className="logout-icon icon"></i> <span>Logout</span>
							</a>
						</li>
						</> }
					</ul>
				</div>	
			</div>		
			</div>			
			
		</div>
	</div>
	
    );
  }
}

export default Header;