import React, { Component } from 'react';
import { Link } from "react-router-dom";
import  Header from '../Layouts/Header';
import  onSubmitMessage from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { API_BASE_URL } from '../config/api';
const axios = require("axios");

class EditUsers extends Component {
	
	constructor(props)
	{
		super(props);	
		this.state = {
            users: [],
            files: [],
            file: [],
            deleteRecord: "",
            errors: {},
            input: {},
            user: {
			        	name:'',
                address:'',
                password:'',
                email:'',
                department:'',
                status:'',
                confirm_password:''
            },
            id: this.props.match.params.id
        };
        
        this.handleSubmit = this.handleSubmit.bind(this);
		
		
    }

    componentDidMount(){
		
         axios.get(API_BASE_URL+'/users/id/' + this.state.id)
         .then((result) => {
             console.log(result.data);
             const data = result.data.data

             this.setState({
                user: {
                    name:data.name,
                    address:data.address,
                    department:data.department,
                    status:data.status,
                    email:data.email,
                    password:data.password,
                    user_id:data.user_id,
                    permission_module:data.permission_module
                            
				}
       })
       
      var test = data.permission_module.split(',');
      console.log('test',test);
      for(var i=0;i<=test.length;i++){
        if(test[i]=='dashboard'){
          
          this.setState({dashboard : test[i] })
        }
        if(test[i]=='files'){
          this.setState({files : test[i] })
        }
        if(test[i]=='documents'){
          console.log('test[i]',test[i]);
          this.setState({documents : test[i] })
        }
        if(test[i]=='letters'){
          this.setState({letters : test[i] })
        }
        if(test[i]=='communications'){
          this.setState({
          communications : test[i]
        })
        }
        if(test[i]=='accounts'){
          this.setState({
            accounts : test[i]
          })
        }
        if(test[i]=='users'){
         
          this.setState({users : test[i] })
        }
        if(test[i]=='settings'){
          this.setState({settings : test[i] })
        }

      }
      console.log('test',this.state.dashboard);
       localStorage.setItem('array', data.permission_module.toString());
             })

        

    }
    

    handleClickSelecet = (event) => {
      let array=[];
      
        if((event.target.checked === true) && (event.target.name === 'dashboard')){
          this.setState({dashboard : 'dashboard' })
            var value = event.target.name;
            var existing = localStorage.getItem('array');
            existing = existing ? existing.split(',') : [];
            existing.push(value);
            localStorage.setItem('array', existing.toString());
         
         }else  if((event.target.checked === false) && (event.target.name === 'dashboard')){ 
          this.setState({dashboard : '' })
          var existing = localStorage.getItem('array');
          existing = existing.replace('dashboard', '');
          console.log('dashboardpushh',existing);
          localStorage.setItem('array', existing);
         }
         if((event.target.checked === true) && (event.target.name === 'files')){
          this.setState({files : 'files' })
          var value = event.target.name;
          var existing = localStorage.getItem('array');
          existing = existing ? existing.split(',') : [];
          existing.push(value);
          localStorage.setItem('array', existing.toString());
         
        }else  if((event.target.checked === false) && (event.target.name === 'files')){ 
          this.setState({files : '' })
          var existing = localStorage.getItem('array');
          existing = existing.replace('files', '');
          console.log('dashboardpushh',existing);
          localStorage.setItem('array', existing);
         }
        if((event.target.checked === true) && (event.target.name === 'documents')){
          this.setState({documents : 'documents' })
          var value = event.target.name;
          var existing = localStorage.getItem('array');
          existing = existing ? existing.split(',') : [];
          existing.push(value);
          localStorage.setItem('array', existing.toString());
        }else  if((event.target.checked === false) && (event.target.name === 'documents')){ 
          this.setState({documents : '' })
          var existing = localStorage.getItem('array');
          existing = existing.replace('documents', '');
          console.log('dashboardpushh',existing);
          localStorage.setItem('array', existing);
         }
        if((event.target.checked === true) && (event.target.name === 'letters')){
          this.setState({letters : 'letters' })
         var value = event.target.name;
         var existing = localStorage.getItem('array');
         existing = existing ? existing.split(',') : [];
         existing.push(value);
         localStorage.setItem('array', existing.toString());
        }else  if((event.target.checked === false) && (event.target.name === 'letters')){ 
          this.setState({letters : '' })
          var existing = localStorage.getItem('array');
          existing = existing.replace('letters', '');
          console.log('dashboardpushh',existing);
          localStorage.setItem('array', existing);
         }
        if((event.target.checked === true) && (event.target.name === 'communications')){
          this.setState({communications : 'communications' })
          var value = event.target.name;
          var existing = localStorage.getItem('array');
          existing = existing ? existing.split(',') : [];
          existing.push(value);
          localStorage.setItem('array', existing.toString());
        }else  if((event.target.checked === false) && (event.target.name === 'communications')){ 
          this.setState({communications : '' })
          var existing = localStorage.getItem('array');
          existing = existing.replace('communications', '');
          console.log('dashboardpushh',existing);
          localStorage.setItem('array', existing);
         }
        if((event.target.checked === true) && (event.target.name === 'accounts')){
          this.setState({accounts : 'accounts' })
          var value = event.target.name;
          var existing = localStorage.getItem('array');
          existing = existing ? existing.split(',') : [];
          existing.push(value);
          localStorage.setItem('array', existing.toString());
        }else  if((event.target.checked === false) && (event.target.name === 'accounts')){ 
          this.setState({accounts : '' })
          var existing = localStorage.getItem('array');
          existing = existing.replace('accounts', '');
          console.log('dashboardpushh',existing);
          localStorage.setItem('array', existing);
         }
        if((event.target.checked === true) && (event.target.name === 'users')){
          this.setState({users : 'users' })
          var value = event.target.name;
          var existing = localStorage.getItem('array');
          existing = existing ? existing.split(',') : [];
          existing.push(value);
          localStorage.setItem('array', existing.toString());
        }else  if((event.target.checked === false) && (event.target.name === 'users')){ 
          this.setState({users : '' })
          var existing = localStorage.getItem('array');
          existing = existing.replace('users', '');
          console.log('dashboardpushh',existing);
          localStorage.setItem('array', existing);
         }
        if((event.target.checked === true) && (event.target.name === 'settings')){
          this.setState({settings : 'settings' })
          var value = event.target.name;
          var existing = localStorage.getItem('array');
          existing = existing ? existing.split(',') : [];
          existing.push(value);
          localStorage.setItem('array', existing.toString());
        }else  if((event.target.checked === false) && (event.target.name === 'settings')){ 
          this.setState({settings : '' })
          var existing = localStorage.getItem('array');
          existing = existing.replace('settings', '');
          console.log('dashboardpushh',existing);
          localStorage.setItem('array', existing);
         }
            
      
    }

    getUsers = _ => {
        fetch(API_BASE_URL+'/users')
        .then(response => response.json())
        .then(response => this.setState({ user: response.data}))
        .catch(err => console.log(err))
      }


      uploadfiless(e) {
      // let reader = new FileReader();
      // let file = e.target.files[0];

      //   //   reader.onloadend = () => {
      //   //     this.photo = reader.result.toString();
      //   // };
      //  // this.photo_name = oldFileItem+'.'+'jpg';
      //   var imgFile = new File([file], {type:file.type});
      //   console.log('imageefilesss',file);
      //   reader.readAsDataURL(file);
      e.preventDefault();
     // this.setState({ file:  e.target.files });
      console.log('aaaaffffff',e.target.files);
      const formData = new FormData();
      formData.append('file[]', e.target.files[0]);
  
      console.log('filessprofile',e.target.files[0]);
    axios.post(API_BASE_URL+'/users/upload_file', formData).then(response => {
    if(response.data.status==="success") {
     // this.setState({ file:  response.img_name});
     console.log('filessprofile',response);
     localStorage.setItem('profile_image', response.data.img_name[0].filename);
  
  }
  });
      }


      handleSubmit(event) {
        event.preventDefault();
        this.validate();
      }
    
      validate(){
    
          let input = this.state.user;

          let errors = {};
          let isValid = true;
          if (typeof input["email"] !== "undefined") {
    
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    
            if (!pattern.test(input["email"])) {
    
              isValid = false;
    
              errors["email"] = "Please enter valid email address.";
    
            }
    
          }

          if (!input["name"]) {
            isValid = false;
            errors["name"] = "Please enter your name.";
          }
    
          if (!input["email"]) {
            isValid = false;
            errors["email"] = "Please enter your email.";
          }

          if (!input["department"]) {
            isValid = false;
            errors["department"] = "Please enter your department.";
          }

          if (!input["address"]) {
            isValid = false;
            errors["address"] = "Please enter your address.";
          }
    
          if (!input["password"]) {
            isValid = false;
            errors["password"] = "Please enter your password.";
          }
          if (!input["confirm_password"]) {
            isValid = false;
            errors["confirm_password"] = "Please enter your confirm password.";
          }
          if (typeof input["password"] !== "undefined" && typeof input["confirm_password"] !== "undefined") {
            if (input["password"] != input["confirm_password"]) {
              isValid = false;
              errors["password"] = "Passwords don't match.";
            }
          } 
    
          this.setState({
            errors: errors
          }); 
          console.log('sdsds',isValid);
         if( isValid ==true){
            this.editUsers();
        }
    
      }
    
    editUsers = _ => {
        const { user,files } = this.state;
        //var status = 'Active';
        var permission_module = localStorage.getItem('array');
        var profile_image = localStorage.getItem('profile_image');
       
		fetch(API_BASE_URL+`/users/update?name=${user.name}&address=${user.address}&email=${user.email}&department=${user.department}&status=${user.status}&password=${user.password}&user_id=${this.state.id}&permission_module=${permission_module}&profile=${profile_image}`)
        .then(this.getUsers)
        .catch( err => console.log(err))
        // window.location.href = '/users';

	
        }
	
	
  render() {
    const { users,files, user,dashboard} = this.state
    return (
        <div className="wrapper"> 
 
        <Header />
        <MenuSidebar />		
  
      
      <div className="content">	
      <div className="content-wrapper">
      <div className="content-body">
			<div className="form-wrapper edit-profile-form">
			<div className="title">
				<h4>Edit Users</h4>
			</div>
			<div className="form-row">
			<div className="form-left">
				<div class="form-group">
					<label>User Name</label>
					<input type="text" className="form-control" onChange={e => this.setState({ user: {...user, name:e.target.value}})} placeholder="" value={user.name} />
                    <div style={{color: "red"}} className="text-danger">{this.state.errors.name}</div>
                </div>
				<div class="form-group">
					<label>Password</label>
					<input type="password" onChange={e => this.setState({ user: {...user, password:e.target.value}})} placeholder="" value={user.password} className="form-control"  />
                    <div style={{color: "red"}} className="text-danger">{this.state.errors.password}</div>
                </div>
                <div class="form-group">
					<label>Department</label>
					<input type="text" className="form-control"  onChange={e => this.setState({ user: {...user, department:e.target.value}})} placeholder=""  className="form-control" value={user.department} />
                    <div style={{color: "red"}} className="text-danger">{this.state.errors.department}</div>
                </div>
			</div>
			<div className="form-right">
        <div class="form-group">
					<label>Email</label>
					<input type="text" readOnly onChange={e => this.setState({ user: {...user, email:e.target.value}})} placeholder=""  className="form-control" value={user.email} />
				    <div style={{color: "red"}} className="text-danger">{this.state.errors.email}</div>
        </div>
				<div class="form-group">
					<label>Confirm Password </label>
					<input type="password" onChange={e => this.setState({ user: {...user, confirm_password:e.target.value}})} placeholder=""  className="form-control" value={user.confirm_password}/>
				    <div style={{color: "red"}} className="text-danger">{this.state.errors.confirm_password}</div>
        </div>
				<div class="form-group">
					<label>Address</label>
					<input type="text"onChange={e => this.setState({ user: {...user, address:e.target.value}})} placeholder=""  className="form-control" value={user.address} />
          <div style={{color: "red"}} className="text-danger">{this.state.errors.address}</div>
        </div>	
        <div className="file-upload">
                     <input type="file"    onChange={this.uploadfiless} />
				</div>			
			</div>

      <div className="module-access">
        <div className="title">
          <h4>Module Access Permission</h4>
        </div>
        <div className="module-access-row">
        <div > <input name="dashboard" checked={this.state.dashboard === 'dashboard'?true:false}  onChange={this.handleClickSelecet} ref="dashboard" type="checkbox" placeholder="" /> <span>Dashboard</span></div>
        <div > <input name="files" checked={this.state.files === 'files'?true:false} onChange={this.handleClickSelecet} ref="files" type="checkbox" placeholder="" /> <span>Files</span></div>
        <div > <input name="documents" checked={this.state.documents === 'documents'?true:false} onChange={this.handleClickSelecet} ref="documents" type="checkbox" placeholder="" /> <span>Documents</span></div>
        <div > <input name="letters" checked={this.state.letters === 'letters'?true:false} onChange={this.handleClickSelecet} ref="letters" type="checkbox" placeholder="" /> <span>Letters</span></div>
        <div > <input name="communications" checked={this.state.communications === 'communications'?true:false} onChange={this.handleClickSelecet} ref="communications" type="checkbox" placeholder="" /> <span>Communications</span></div>
        <div > <input name="accounts" checked={this.state.accounts === 'accounts'?true:false} onChange={this.handleClickSelecet} ref="accounts" type="checkbox" placeholder="" /> <span>Accounts</span></div>
        <div > <input name="users"  checked={this.state.users === 'users'?true:false} onChange={this.handleClickSelecet} ref="users" type="checkbox" placeholder="" /> <span>Users</span></div>
        <div > <input name="settings" checked={this.state.settings === 'settings'?true:false}  onChange={this.handleClickSelecet} ref="settings" type="checkbox" placeholder="" /> <span>Settings</span></div>
        </div>    
        <div className="btn-group export">	
          <input type="submit" onClick={this.handleSubmit} className="btn" value="Update" />
        </div>
      </div>

			</div>
    
	</div>
  </div>	
	</div>	
	</div>
		 	
    </div>
    );
  }
}

export default EditUsers;