import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { API_BASE_URL,API_KEY_CHAT,REGION_CHAT,APP_ID_CHAT } from '../config/api';
import { CometChat } from "@cometchat-pro/chat" 
import $ from 'jquery';
import randomstring from 'randomstring';
const axios = require("axios");

class Creategroup extends Component {
	
	constructor(props)
	{
		super(props);	
		this.state = {
			files: [],
			chats: [],
			deleteRecord: "",
			chat: {
			  name: '',
			  type: '',
			  		  
			}
		};	
	}
	
	componentDidMount(){
        var appID = APP_ID_CHAT;
        var region = REGION_CHAT;
        var appSetting = new CometChat.AppSettingsBuilder().subscribePresenceForAllUsers().setRegion(region).build();
        CometChat.init(appID, appSetting).then(
        () => {
            console.log("Initialization completed successfully");
        },
        error => {
            console.log("Initialization failed with error:", error);
            // Check the reason for error and take appropriate action.
        }
		);
		this.getfiles() 
	}
	
	getfiles = _ => {
		let apiKey = API_KEY_CHAT; //rest api key from common caht dashboard
        
		fetch(API_BASE_URL+'/users')
		.then(response => response.json())
		.then((response) => {this.setState({ files: response.data})   
		console.log('jjj',response.data);
		response.data.forEach(userslogin => {
			var uid = JSON.stringify(userslogin.user_id);
         var name = userslogin.name;
           console.log('uidlogin',uid);
           console.log('namelogin',name);
         var user = new CometChat.User(uid);
          
         user.setName(name);
 
         CometChat.createUser(user, apiKey).then(
             user => {
                 console.log("user created", user);
                 
             },error => {
                 console.log("error", error);
             }
         )
       
		});
	    })
		.catch(err => console.log(err))
		/*********************************** */
		var login_user_id = localStorage.getItem('user_login_id');
		this.loginchatadmincall(login_user_id,apiKey);
		
	  }
	

	  loginchatadmincall = (uid,apiKey) => {
		var login_user_id = localStorage.getItem('user_login_id');
  
		  var logintype = localStorage.getItem('login_type');
		  console.log('logintype',logintype);
	   if((logintype=='admin')){
	   axios.get(API_BASE_URL+'/login/id/' + login_user_id)
		 .then((result) => {
		  const data = result.data.data
		  var uid = JSON.stringify(data.admin_id);
		  var name = data.name;
			console.log('uid',uid);
			console.log('name',name);
			localStorage.setItem('user_chat_name',name)
		 
	   
		  console.log("uid", uid);
		  var userid = uid;
		  var api_key = apiKey;
		  CometChat.login(uid, apiKey).then(
			  user => {
				console.log("Login Successful:", { user }); 
				localStorage.setItem('user_chat_status',user['status'])
				
			  }
			, error => {
			  console.log("User login failed with error:", error);
			  //Check the reason for error and take appropriate action.
		  });
		 
		 })
	   }
		 
	  } 
	  
    onValueChangeposition = (event) => {
        if(event.target.value != ''){
            var type = event.target.value;
        }
    localStorage.setItem('Group_type', type);
    
    this.setState({
        group_type: event.target.value
        });
        
      }
    
      
      Creategroupnow = () => {
        var name = this.state.chat.name;
		//var group_type = localStorage.getItem('Group_type');
		var group_type = 'PRIVATE';
        var GUID = randomstring.generate(7);
        
        console.log("name", name);
        console.log("group_type", group_type);
		console.log("GUID", GUID);
		if(group_type == 'PUBLIC'){
			var GUID = name+GUID;
			var groupName = name;
			var groupType = CometChat.GROUP_TYPE.PUBLIC;
			var password = "";

			var group = new CometChat.Group(GUID, groupName, groupType, password);

			CometChat.createGroup(group).then(
				group => {
					console.log("Group created successfully:", group);
					alert('Group Created Successfully..');
					window.location.href = '/creategroup';
				},
				error => {
					console.log("Group creation failed with exception:", error);
				}
			);
		}else{

			var GUID = name+GUID;
			var groupName =  name;
			var groupType = CometChat.GROUP_TYPE.PRIVATE;
			var password = "";
			var group = new CometChat.Group(GUID, groupName, groupType, password);
	
			CometChat.createGroup(group).then(
				group => {
					console.log("Group created successfully:", group);
					alert('Group Created Successfully..');
					window.location.href = '/creategroup';
				},
				error => {
					console.log("Group creation failed with exception:", error);
				}
			);
		}
		
       
      }
	
  render() {
	  const { chat, chats } = this.state
	  console.log('map',chats);
    return (	
		<div className="wrapper"> 
     	  <Header />
	      <MenuSidebar />		
       	<div className="content">	
		<div className="content-wrapper">
			<div className="content-body">
			<div className="form-wrapper">			
				 <div className="title">
					<h4>Create Group</h4>
				 </div>
                 <div className="form-row">
			<div className="form-left">
				<div class="form-group">
					<label>Group Name</label>
					<input type="text" className="form-control" placeholder="" value={chat.name}
            onChange={e => this.setState({ chat: {...chat, name:e.target.value}})} />
				</div>
				<p><b>(Note : Type Group name without whitespace)</b></p>
				<div class="form-group" style={{display : 'none'}}>
					<label>Group Type</label>
				<select id="lang" onChange={this.onValueChangeposition.bind(this)} value={this.state.type}>
				<option value="select">Select Group Type</option>
				<option value="PUBLIC">Public</option>
				<option value="PRIVATE">Private</option>
				</select>	
				</div>
			</div>
			</div>
			<div className="btn-group export">	
				<input type="submit" className="btn" onClick={this.Creategroupnow} value="Create" />
			</div>
	     	</div>
			</div>	
		</div>	
	</div>
		 	
    </div>
    );
  }
}

export default Creategroup;