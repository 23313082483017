
/*************Base Url settings***********/
//DEVELOPMENT-SERVER-URL :
//export const API_BASE_URL = 'http://54.251.226.114:9000';
//export const API_BASE_URL = 'http://13.213.12.148';  /*new server url */

export const API_BASE_URL = 'http://172.104.185.163:3000';  /*live url */



//LOCAL-HOST-URL :

//export const API_BASE_URL = 'http://13.214.69.9:9000';

//export const API_BASE_URL      = "http://localhost:9000";

/*************chat settings***********/
//Commetchat loginurl to get the keys- https://app.cometchat.com/login
export const API_KEY_CHAT = '9b49b07fc0da03ceb7fc5c0ddcf0da8007437591';
export const REGION_CHAT = 'us';
export const APP_ID_CHAT = '234912220247bbc';
export const AUTH_KEY_CHAT = '967e0f8d868c1e7d336a6102f70017965ae8ae36';


