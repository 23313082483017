import React, { Component } from 'react';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { Link } from "react-router-dom";
import { API_BASE_URL } from '../config/api';

const axios = require("axios");

class EditInsurance extends React.Component {
	
	constructor(props)
	{
		super(props);	
		this.state = {
			insurances: [],
			insurance: {
			  name: '',
			  address: '',
			  email: '',
			  fax: '',
			  telephone: '',
			  insurance_id:0			  
			},
			id: this.props.match.params.id
		};	
    }
	
	componentDidMount(){
        axios.get(API_BASE_URL+'/insurance/id/' + this.state.id)
        .then((result) => {
            const data = result.data.data
            this.setState({
              insurance: {
			  name: data.name,
			  address: data.address,
			  email: data.email,
			  fax: data.fax,
			  telephone: data.telephone,
	          insurance_id: data.insurance_id			  
			}
            })
        })
    }
	
	
	editInsurance = _ => {
    const { insurance } = this.state
    fetch(API_BASE_URL+`/insurance/update?name=${insurance.name}&address=${insurance.address}&email=${insurance.email}&fax=${insurance.fax}&telephone=${insurance.telephone}&insurance_id=${insurance.insurance_id}`)
    .then((result) => {
                    console.log(result);
				
                   window.location.href = '/insurance';
                })
    .catch( err => console.log(err))
    }
	
  render() {
	  const { insurances, insurance } = this.state
	  return (
        <div className="wrapper"> 
     	<Header />
	    <MenuSidebar />		
       	<div className="content">	
		<div className="content-wrapper">
			<div className="content-body">
			<div className="form-wrapper">
			 <div className="title">
				<h4>Edit Insurance</h4>
	         </div>	
				<div className="form-row">
			<div className="form-left">
				<div class="form-group">
					<label>Company Name</label>
					<input type="text" className="form-control" placeholder="" value={insurance.name}
            onChange={e => this.setState({ insurance: {...insurance, name:e.target.value}})} />
				</div>
				<div class="form-group">
					<label>Company Address</label>
					<input type="text" className="form-control"  placeholder="" value={insurance.address}
            onChange={e => this.setState({ insurance: {...insurance, address:e.target.value}})} />
				</div>
				<div class="form-group">
					<label>Email Address</label>
					<input type="text" className="form-control"  placeholder="" value={insurance.email}
            onChange={e => this.setState({ insurance: {...insurance, email:e.target.value}})} />
				</div>
			</div>
			<div className="form-right">
			  <div class="form-group">
					<label>Fax Number</label>
					<input type="text" className="form-control" placeholder="" value={insurance.fax}
            onChange={e => this.setState({ insurance: {...insurance, fax:e.target.value}})} />
				</div>
				<div class="form-group">
					<label>Telephone Number</label>
					<input type="text" className="form-control"  placeholder="" value={insurance.telephone}
            onChange={e => this.setState({ insurance: {...insurance, telephone:e.target.value}})} />
				</div>
			 </div>
			</div>
			<div className="btn-group export">	
				<input type="submit" className="btn" onClick={this.editInsurance} value="Update" />
			</div>
			</div>
		</div>	
		</div>	
	</div>		 	
    </div>
    );
  }
}

export default EditInsurance;