import React, { Component } from 'react';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { Link } from "react-router-dom";
import { API_BASE_URL } from '../config/api';
import $ from 'jquery';

const axios = require("axios");

class Claimdetails extends React.Component {
	
	constructor(props)
	{
	  super(props);	
		this.state = {
			disbursements: [],
            disbursement: { 
                pI_general_1:'',
                pI_general_2:'',
                pI_general_3:'',
                pI_general_4:'',
                pI_speciall_1:'',
                pI_speciall_2:'',
                pI_speciall_3:'',
                pI_speciall_4:'',
                PD_general_1:'',
                PD_general_2:'',
                PD_general_3:'',
                PD_general_4:'',
                PD_speciall_1:'',
                PD_speciall_2:'',
                PD_speciall_3:'',
                PD_speciall_4:'',
				other_damages:'',
				custom_labels:'',
                plaintiff_id:''		  
			  },
			  other_damages: [{other_damages: "",custom_labels:''}],
			  //custom_labels: [{custom_labels: ""}]
		};	
    }
	
    componentDidMount(){
        //this.getdefendents()
        //this.getInsurances()
       }
   
    //    getdefendents = _ => {
    //     fetch(API_BASE_URL+'/files')
    //     .then(response => response.json())
    //     .then(response => this.setState({ disbursements: response.data}))
    //     .catch(err => console.log(err))
	//   }
	
	handleChange(i, e) {
		const { name, value } = e.target;
		let other_damages = [...this.state.other_damages];
		other_damages[i] = {...other_damages[i], [name]: value};
		console.log('OTTT',other_damages);
		localStorage.setItem('other_damages',  JSON.stringify(other_damages));
		this.setState({ other_damages });
		
		
	 }

	//  handleChangelabel(i, e) {
	// 	const { name, value } = e.target;
	// 	let custom_labels = [...this.state.custom_labels];
	// 	custom_labels[i] = {...custom_labels[i], [name]: value};
	// 	console.log('custom_labels',custom_labels);
	// 	localStorage.setItem('custom_labels',  JSON.stringify(custom_labels));
	// 	this.setState({ custom_labels });
		
	//  }
    
   handleFormSubmit = () => {
	const { disbursement } = this.state;
	localStorage.setItem('claim',  JSON.stringify(disbursement));

	var value_incc = parseInt(localStorage.getItem("inc_val_othersdamage"));
	for (var i = 1; i <= value_incc; i++) {
		var custom_labels = "custom_labels"+[i];
		var other_damages = "other_damages"+[i];

		var custom = localStorage.getItem(custom_labels);
	   var other = localStorage.getItem(other_damages);
	   console.log('otherother',other);

	   if((custom!='') && (other!='')){
		 
		var appenddata = {
			custom_labels: localStorage.getItem((custom_labels)),
			other_damages: localStorage.getItem(other_damages)
		 };
			console.log('appenddata',appenddata); 

			if((appenddata.custom_labels!= null) && (appenddata.other_damages!= null)){
				localStorage.setItem("append_otherdamges"+[i], JSON.stringify(appenddata));
		}
	  }
	}
	
  };


  addChild (inc,e) {
	if (localStorage.getItem("inc_val_othersdamage") > 0){
		var inc_val =  parseInt(localStorage.getItem("inc_val_othersdamage"))+1;
		}else{
		  var inc_val =  parseInt(inc)+1; 
		}
  
		localStorage.setItem("clicked_add_ot.D", 'yes')
		console.log('inc_val_othersdamage',inc_val);
		localStorage.setItem("inc_val_othersdamage", inc_val)
		var max_fields = 16;
		e.preventDefault();
if (inc_val < max_fields) {
	$(".other-damage-wrap").append(`
	<div class="form-row">
	<div class="form-left">
		<div class="form-group">
			<div class="doller-inputbox">
				<span class="doller">$</span>
				<input id='custom_labels`+[inc_val]+`' type="text" class="form-control"  placeholder="Custom Labels" />
			</div>
		</div>
	</div>
	<div class="form-right">
		<div class="form-group">
			<div class="doller-inputbox"> 
				<span class="doller">$</span>
				<input id='other_damages`+[inc_val]+`' type="text" class="form-control" />
			</div>
		 </div>
		</div>
	</div>
	</div>`);

	$('#custom_labels'+[inc_val]).on('change',function (e) {
		var custom_labels = $( "#custom_labels"+[inc_val] ).val() || [];
		console.log('custom_labels'+[inc_val],custom_labels);
		localStorage.setItem("custom_labels"+[inc_val], custom_labels)
	   
	   });
	   $('#other_damages'+[inc_val]).on('change',function (e) {
		 var other_damages = $( "#other_damages"+[inc_val] ).val() || [];
		 console.log('other_damages'+[inc_val],other_damages);
		 localStorage.setItem("other_damages"+[inc_val], other_damages)
	   
		});
   }
  };
	
  render() {
      const { disbursements, disbursement,other_damages ,custom_labels} = this.state
	  console.log('map',disbursements);
	  var p_type = localStorage.getItem('p_type');
	  var inc = 0;
	 
	  return (
        <div className="wrapper"> 
 
        <Header />
        <MenuSidebar />		
  
      
      <div className="content">	
          <div className="content-wrapper">
              <div className="content-body">
              <div className="form-wrapper">			
				 <div className="title">
					<h4>CLAIM DETAILS</h4>
				 </div>
				 { p_type === "personal_injury" && 
 
			     <>
				<div  className='classInjury'> 
                 <div className="title">
                 <h4>Personal  injury claims</h4>
                 </div>
                 
        <div className="form-row">
			<div className="form-left">
            
			<h4 className="sub-title">General Damages</h4>
				<div class="form-group">
					<label>Pain and Suffering </label>
					<div className="doller-inputbox">
					<span className="doller">$</span>
					<input type="text" className="form-control"  placeholder="" value={disbursement.pI_general_1}
            onChange={e => this.setState({ disbursement: {...disbursement, pI_general_1:e.target.value}})} />
				</div>
				</div>
				<div class="form-group">
					<label>Loss Of Earing Capability </label>
					<div className="doller-inputbox">
					<span className="doller">$</span>
					<input type="text" className="form-control"   placeholder="" value={disbursement.pI_general_2}
            onChange={e => this.setState({ disbursement: {...disbursement, pI_general_2:e.target.value}})} />
				</div>
				</div>
				<div class="form-group">
					<label>Future Medical Expenses </label>
					<div className="doller-inputbox">
					<span className="doller">$</span>
					<input type="text" className="form-control"   placeholder="" value={disbursement.pI_general_3}
            onChange={e => this.setState({ disbursement: {...disbursement, pI_general_3:e.target.value}})} />
				</div>
				</div>
                <div class="form-group">
					<label>Future Loss Of Income </label>
					<div className="doller-inputbox">
					<span className="doller">$</span>
					<input type="text" className="form-control"   placeholder="" value={disbursement.pI_general_4}
            onChange={e => this.setState({ disbursement: {...disbursement, pI_general_4:e.target.value}})} />
				</div>
				</div>
			</div>
			<div className="form-right">
			<h4 className="sub-title">Special Damages</h4>
			    <div class="form-group">
					<label>Medical Expenses </label>
					<div className="doller-inputbox">
					<span className="doller">$</span>
					<input type="text" className="form-control"  placeholder="" value={disbursement.pI_speciall_1}
            onChange={e => this.setState({ disbursement: {...disbursement, pI_speciall_1:e.target.value}})} />
				</div>
				</div>
				<div class="form-group">
					<label>Transport Expenses </label>
					<div className="doller-inputbox">
					<span className="doller">$</span>
					<input type="text" className="form-control"   placeholder="" value={disbursement.pI_speciall_2}
            onChange={e => this.setState({ disbursement: {...disbursement, pI_speciall_2:e.target.value}})} />
				</div>
				</div>
                <div class="form-group">
					<label>Pre-loss Of Income</label>
					<div className="doller-inputbox">
					<span className="doller">$</span>
					<input type="text" className="form-control"   placeholder="" value={disbursement.pI_speciall_3}
            onChange={e => this.setState({ disbursement: {...disbursement, pI_speciall_3:e.target.value}})} />
				</div>
				</div>
				<div class="form-group">
					<label>Others </label>
					<div className="doller-inputbox">
					<span className="doller">$</span>
					<input type="text" className="form-control"   placeholder="" value={disbursement.pI_speciall_4}
            onChange={e => this.setState({ disbursement: {...disbursement, pI_speciall_4:e.target.value}})} />
				</div>
				</div>
			   </div>
			</div>
			</div>
			</> }
			{ p_type === "personal_damage" && 
			<>
			<div className="classDamage">
			 <div className="title">
				<h4>Property Damage Claims</h4>
	         </div>
			<div className="form-row">
			<div className="form-left">
            
			
				<div class="form-group">
					<label>Cost Of Repair </label>
					<div className="doller-inputbox">
					<span className="doller">$</span>
					<input type="text" className="form-control"  placeholder="" value={disbursement.PD_general_1}
            onChange={e => this.setState({ disbursement: {...disbursement, PD_general_1:e.target.value}})} />
				</div>
				</div>
				<div class="form-group">
					<label>Loss Of Use </label>
					<div className="doller-inputbox">
					<span className="doller">$</span>
					<input type="text" className="form-control"   placeholder="" value={disbursement.PD_general_2}
            onChange={e => this.setState({ disbursement: {...disbursement, PD_general_2:e.target.value}})} />
				</div>
				</div>
				<div class="form-group">
					<label>PRI Loss Of Use </label>
					<div className="doller-inputbox">
					<span className="doller">$</span>
					<input type="text" className="form-control"   placeholder="" value={disbursement.PD_general_3}
            onChange={e => this.setState({ disbursement: {...disbursement, PD_general_3:e.target.value}})} />
				</div>
				</div>
                <div class="form-group">
					<label>Rental </label>
					<div className="doller-inputbox">
					<span className="doller">$</span>
					<input type="text" className="form-control"   placeholder="" value={disbursement.PD_general_4}
            onChange={e => this.setState({ disbursement: {...disbursement, PD_general_4:e.target.value}})} />
				</div>
				</div>
			</div>
			<div className="form-right">
			
			    <div class="form-group">
					<label>Survey Report Fee </label>
					<div className="doller-inputbox">
					<span className="doller">$</span>
					<input type="text" className="form-control"  placeholder="" value={disbursement.PD_speciall_1}
            onChange={e => this.setState({ disbursement: {...disbursement, PD_speciall_1:e.target.value}})} />
				</div>
				</div>
				<div class="form-group">
					<label>Party and Legal Cost </label>
					<div className="doller-inputbox">
					<span className="doller">$</span>
					<input type="text" className="form-control"   placeholder="" value={disbursement.PD_speciall_2}
            onChange={e => this.setState({ disbursement: {...disbursement, PD_speciall_2:e.target.value}})} />
				</div>
				</div>
                <div class="form-group">
					<label>Legal Cost </label>
					<div className="doller-inputbox">
					<span className="doller">$</span>
					<input type="text" className="form-control"   placeholder="" value={disbursement.PD_speciall_3}
            onChange={e => this.setState({ disbursement: {...disbursement, PD_speciall_3:e.target.value}})} />
				</div>
				</div>
				<div class="form-group">
					<label>Others </label>
					<div className="doller-inputbox">
					<span className="doller">$</span>
					<input type="text" className="form-control"   placeholder="" value={disbursement.PD_speciall_4}
            onChange={e => this.setState({ disbursement: {...disbursement, PD_speciall_4:e.target.value}})} />
				</div>
				</div>
			   </div></div>
			   </div>
             </>}
			
               <div className="title">
				<h4>Other Damages</h4>
	         </div>
			 <div className="other-damage-wrap">
		
			</div>	
		<div className="addmore-row">		
			<Link className="addmore-btn" value='add more' onClick={this.addChild.bind(this,inc)}> Add another </Link>
		</div>
		<div className="btn-group next-back-group">	
            <Link className="back"  to={"/files/addDefendentdetails"}> <i class="arrow icon"></i></Link>
            <Link className="next" to={"/files/addDisbursementdetails"}  onClick={this.handleFormSubmit} > <i class="arrow icon"></i></Link>
		</div>	
            </div>	
        </div>	
		</div>	
	</div>
		 	
    </div>
	  );
  }
}

export default Claimdetails;