import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { API_BASE_URL } from '../config/api';
const axios = require("axios");

class Hospitals extends Component {
	
	constructor(props)
	{
		super(props);	
		this.state = {
			hospitals: [],
			deleteRecord: "",
			hospital: {
			  name: '',
			  address: '',
			  email: '',
			  fax: '',
			  telephone: '',
			  amount: 0			  
			}
		};	
    }
	
	componentDidMount(){
		this.getHospitals()
		
    }
	
	getHospitals = _ => {
    fetch(API_BASE_URL+'/hospitals')
    .then(response => response.json())
    .then(response => this.setState({ hospitals: response.data}))
    .catch(err => console.log(err))
  }
  
  handleChangeDelete = e => {
	  e.preventDefault();
    this.setState({
      deleteRecord: e.target.name
    });
	
    var Formdata = {
      deleteRecord: e.target.name
    };

    axios.post(API_BASE_URL+'/hospitals/delete', { Formdata }).then(response => {
      console.log(response.data);
      console.log(response);
	 this.getHospitals();
	  //this.setState({ hospitals: this.getHospitals});
      //window.location.reload();
    });
  };

  
	renderHospital = ({ hospital_id, name, address, email, fax, telephone, amount}) => <><tr key={hospital_id}><td>{name}</td> <td>{address}</td><td>{email}</td><td>{fax}</td><td>{telephone}</td><td>{amount}</td><td>
            <div className="action">
			<Link className="btn edit-btn" to={"/hospitals/edit/" + hospital_id}>Edit</Link>
			<button className="btn delete-btn" name={hospital_id} onClick={this.handleChangeDelete} >Delete</button>
		    </div>
			</td></tr></>
	
	addHospitals = _ => {
    const { hospital } = this.state
    fetch(API_BASE_URL+`/hospitals/add?name=${hospital.name}&address=${hospital.address}&email=${hospital.email}&fax=${hospital.fax}&telephone=${hospital.telephone}&amount=${hospital.amount}`)
    .then(this.getHospitals)
    .catch( err => console.log(err))
    }
	
  render() {
	  const { hospitals, hospital } = this.state
	  console.log('map',hospitals);
    return (	
		<div className="wrapper"> 
     	  <Header />
	      <MenuSidebar />		
       	<div className="content">	
		<div className="content-wrapper">
			<div className="content-body">
			<div className="form-wrapper">			
				 <div className="title">
					<h4>Hospital Management</h4>
					<Link className="add-btn" to={"/hospitals/add"}> Add</Link>
				 </div>
			{(hospitals !='' && typeof hospitals != 'undefined')?<table><tr><th width="140">Hospital Name</th><th>Address</th><th>Email</th><th>Fax</th><th>Telephone</th><th>Amount</th><th>Actions</th></tr>{hospitals.map(this.renderHospital)}</table>:<div>No data found.</div>}
		
	     	</div>
			</div>	
		</div>	
	</div>
		 	
    </div>
    );
  }
}

export default Hospitals;