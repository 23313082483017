import React, { Component } from 'react';
import $ from 'jquery';
import axios from 'axios';
import { API_BASE_URL } from '../config/api';

class MenuSidebar extends Component {
	
	constructor(props)
	{
		super(props);	
		this.state = {
			results :[],
		};
		
    }
	

	componentDidMount(){
		var logintype = localStorage.getItem('login_type');
		var login_user_id = localStorage.getItem('user_login_id');
		let urlElements = window.location.href.split('/')
		console.log('dddd',urlElements)
		if((logintype=='user')&&(login_user_id!='')){
			let urlElelement = (urlElements[4])
		console.log('rrrrrr',urlElelement)
		axios.get(API_BASE_URL+'/users/id/' + login_user_id)
		.then((result) => {
			console.log(result.data);
			const data = result.data.data

			this.setState({
			   user: {
				   name:data.name,
				   address:data.address,
				   department:data.department,
				   status:data.status,
				   email:data.email,
				   password:data.password,
				   user_id:data.user_id,
				   permission_module:data.permission_module
						   
			   }
	  })
	  
	 var test = data.permission_module.split(',');
	 console.log('test',test);
	 for(var i=0;i<=test.length;i++){
	   if(test[i]=='dashboard'){
		 
		 this.setState({dashboard : test[i] })
	   }
	   if(test[i]=='files'){
		 this.setState({files : test[i] })
	   }
	   if(test[i]=='documents'){
		 console.log('test[i]',test[i]);
		 this.setState({documents : test[i] })
	   }
	   if(test[i]=='letters'){
		this.setState({letters : test[i] })
	   }
	   if(test[i]=='communications'){
		 this.setState({
		 communications : test[i]
	   })
	   }
	   if(test[i]=='accounts'){
		 this.setState({
		   accounts : test[i]
		 })
	   }
	   if(test[i]=='users'){
		
		 this.setState({users : test[i] })
	   }
	   if(test[i]=='settings'){
		 this.setState({settings : test[i] })
	   }

	 }
	 console.log('test',this.state.dashboard);
	  localStorage.setItem('array', data.permission_module.toString());
			})

	   
		}
	    }

		filesshow  = () => {
			
		  $('#files').show();
		  $('#lettershow').hide();
		  $('#settingsshow').hide();
		}	

		lettershow  = () => {
			$('#files').hide();
			$('#lettershow').show();
			$('#settingsshow').hide();
			
		  }

		  settingsshow  = () => {
			
			$('#files').hide();
			$('#lettershow').hide();
			$('#settingsshow').show();
		  }
	
  render() {
	console.log(this.state.user,'allstatevalue')
	var logintype = localStorage.getItem('login_type');
    return (	
	<div className="sidebar-menu mobi-close">
	   <div className="main-menu">
			<ul className="menulist">
			{((this.state.dashboard) || (logintype=='admin') )
        ? <li className="active">
					<a title="Dashboard" href="#">
						<i className="dashboard-icon icon"></i><span>Dashboard</span>
					</a>
				</li> : ''
           }

		   {((this.state.files) || (logintype=='admin') )
             ?			
				<li>
					<a title="Files" onClick={this.filesshow} href="javascript:void(0)" className="dropdown-toggle">
						<i className="file-icon icon"></i><span>Files</span>
					</a>
					<ul id='files' className="submenu dropdown-menu">
						<li>
							<a href="/files/add">Create New Files</a>
						</li>
						<li>
							<a href="/files/filestatus">Check File Status</a>
						</li>
					</ul>
				</li>
				: ''
			}	
			{((this.state.documents) || (logintype=='admin') )
             ?
				<li>
					<a title="Documents" href="/files/documents" className="dropdown-toggle">
						<i className="docs-icon icon" aria-hidden="true"></i>
						<span>Documents</span>
					</a>
					{/*<ul className="submenu dropdown-menu">
						<li>
							<a href="/files/documents" title="Upload Docs">Upload Docs</a>
						</li>
						<li>
							<a href="/files/Editdocument" title="Modify Docs">Modify Docs</a>
						</li>
						<li>
							<a href="#" title="Print Docs">Print Docs</a>
						</li>
		           </ul> */}
				</li>
				: ''
			}	
			{((this.state.letters) || (logintype=='admin'))
             ?
				<li>
					<a title="Letters" onClick={this.lettershow} href="javascript:void(0)" className="dropdown-toggle">
						<i className="letter-icon icon"></i>
						<span>Letters</span>
					</a>
					<ul id='lettershow' className="submenu dropdown-menu">
						<li>
							<a href="/letterone" title="Letters 1">Letters 1</a>
						</li>
						<li>
							<a href="#" title="Letters 2">Letters 2</a>
						</li>
						<li>
							<a href="#" title="Letters 3">Letters 3</a>
						</li>
					</ul>
				</li>
				: ''
			}	
			{((this.state.communications) || (logintype=='admin') )
             ?
				<li>
					<a title="Communications" href="#">
						<i className="comm-icon icon" aria-hidden="true"></i>
						<span>Communications</span>
					</a>
				</li>
				: ''
			}
			{((this.state.accounts) || (logintype=='admin') )
             ?	
				<li>
					<a title="Accounts" href="/account">
						<i className="ac-icon icon"></i>
						<span>Accounts</span>
					</a>
				</li>
				: ''
			}	
			{((this.state.user) || (logintype=='admin') )
             ?
				<li>
					<a title="Users" href="/users">
						<i className="user-icon icon"></i>
						<span>Users</span>
					</a>
				</li>	
				: ''
			}	
			{((this.state.settings) || (logintype=='admin') )
             ?			
				<li>
					<a title="Settings" onClick={this.settingsshow} href="javascript:void(0)" className="dropdown-toggle">
						<i className="setting-icon icon" aria-hidden="true"></i>
						<span>Settings</span>
					</a>
					<ul id='settingsshow'  className="submenu dropdown-menu setting-dropdown">
					<li>
					<a title="Hospital Management" href="/hospitals">
						<span>Hospital Management</span>
					</a>
				</li>
				<li>
					<a title="Insurance Management" href="/insurance">
						<span>Insurance Management</span>
					</a>
				</li>
				<li>
					<a title="Disbursement Management" href="/disbursement">
						<span>Disbursement Management</span>
					</a>
				</li>
				<li>
					<a title="Referrals Management" href="/referrals">
						<span>Referrals Management</span>
					</a>
				</li>
					</ul>
				</li>
				: ''
			}	
			{/*	<li>
					<a title="Login" href="/users/Login">
						<i className="ac-icon icon"></i>
						<span>Login</span>
					</a>
			</li> */}
			{((logintype=='admin') )
             ?
			<li>
					<a title="Chat" href="/chat">
						<i className="comm-icon icon"></i>
						<span>Chat Management</span>
					</a>
			</li>
			: <li>
			<a title="Chat" href="/chat">
				<i className="comm-icon icon"></i>
				<span> Group Chat </span>
			</a>
	</li>
		}	 
			</ul>
			
		</div>
	</div>		

	
    );
  }
}

export default MenuSidebar;