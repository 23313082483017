import React, { Component } from 'react';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { Link } from "react-router-dom";
import { API_BASE_URL } from '../config/api';

const axios = require("axios");

class Disbursementdetails extends React.Component {
	
	constructor(props)
	{
	  super(props);	
		this.state = {
			disbursements: [],
			disbursement: {
			  documents_lta_search_fee: '',
			  documents_gia_report_fee: '',
			  documents_police_reports: '',
			  medical_reports: '',
			  medical_report_color_copies: '',
			  medical_report_incidentals:'',
			  services_one:'',
			  services_two:'',
			  disbursement_id:''
			},
			id: 1
		};	
		
	}
	
	
	
	componentDidMount(){
        axios.get(API_BASE_URL+'/disbursements/id/' + this.state.id)
        .then((result) => {
            const data = result.data.data
            this.setState({
                disbursement: { 
                    documents_lta_search_fee: '',
                    documents_gia_report_fee: '',
                    documents_police_reports: '',
                    medical_reports: '',
                    medical_report_color_copies: '',
                    medical_report_incidentals: '',			  
                    services_one: '',			  
                    services_two: '',			  
                    disbursement_id: '',			  
                  }
            })
        })
    }
   
   handleClickSelecet1 = (event) => {
	console.log('1234',this.refs.complete1.checked);
	var selectedIndex = this.refs.complete1.checked;
		if(selectedIndex == true) {
	   axios.get(API_BASE_URL+'/disbursements/id/' + this.state.id)
	   .then((result) => {
		   const data = result.data.data
		   this.setState({
			 disbursement: { 
			 documents_lta_search_fee: data.documents_lta_search_fee,
		   }
		   })
		   localStorage.setItem('disbursement_id',  data.disbursement_id);
		   localStorage.setItem('documents_lta_search_fee',  data.documents_lta_search_fee);
	   })
	   }else{
		   this.setState({
			   disbursement: { 
			   documents_lta_search_fee: '',
			 }
			
		 })
		
   }
  }

  handleClickSelecet2 = (event) => {
	console.log('234',this.refs.complete2.checked);
	var selectedIndex = this.refs.complete2.checked;
		if(selectedIndex == true) {
	   axios.get(API_BASE_URL+'/disbursements/id/' + this.state.id)
	   .then((result) => {
		   const data = result.data.data
		   this.setState({
			 disbursement: { 
				documents_gia_report_fee: data.documents_gia_report_fee,
		   }
		   })
		   localStorage.setItem('disbursement_id',  data.disbursement_id);
		   localStorage.setItem('documents_gia_report_fee',  data.documents_gia_report_fee);
	   })
	   }else{
		   this.setState({
			   disbursement: { 
				documents_gia_report_fee: '',
			 }
			
		 })
		
   }
  }

  handleClickSelecet3 = (event) => {
	console.log('3',this.refs.complete3.checked);
	var selectedIndex = this.refs.complete3.checked;
		if(selectedIndex == true) {
	   axios.get(API_BASE_URL+'/disbursements/id/' + this.state.id)
	   .then((result) => {
		   const data = result.data.data
		   this.setState({
			 disbursement: { 
				documents_police_reports: data.documents_police_reports,
		   }
		   })
		   localStorage.setItem('disbursement_id',  data.disbursement_id);
		   localStorage.setItem('documents_police_reports',  data.documents_police_reports);
	   })
	   }else{
		   this.setState({
			   disbursement: { 
				documents_police_reports: '',
			 }
			
		 })
		
   }
  }
  handleClickSelecet4 = (event) => {
	console.log('4',this.refs.complete4.checked);
	var selectedIndex = this.refs.complete4.checked;
		if(selectedIndex == true) {
	   axios.get(API_BASE_URL+'/disbursements/id/' + this.state.id)
	   .then((result) => {
		   const data = result.data.data
		   this.setState({
			 disbursement: { 
				medical_reports: data.medical_reports,
		   }
		   })
		   localStorage.setItem('disbursement_id',  data.disbursement_id);
		   localStorage.setItem('medical_reports',  data.medical_reports);
	   })
	   }else{
		   this.setState({
			   disbursement: { 
				medical_reports: '',
			 }
			
		 })
		
   }
  }

  handleClickSelecet5 = (event) => {
	console.log('5',this.refs.complete5.checked);
	var selectedIndex = this.refs.complete5.checked;
		if(selectedIndex == true) {
	   axios.get(API_BASE_URL+'/disbursements/id/' + this.state.id)
	   .then((result) => {
		   const data = result.data.data
		   this.setState({
			 disbursement: { 
				medical_report_color_copies: data.medical_report_color_copies,
		   }
		   })
		   localStorage.setItem('disbursement_id',  data.disbursement_id);
		   localStorage.setItem('medical_report_color_copies',  data.medical_report_color_copies);
	   })
	   }else{
		   this.setState({
			   disbursement: { 
				medical_report_color_copies: '',
			 }
			
		 })
		
   }
  }

  handleClickSelecet6 = (event) => {
	console.log('6',this.refs.complete6.checked);
	var selectedIndex = this.refs.complete6.checked;
		if(selectedIndex == true) {
	   axios.get(API_BASE_URL+'/disbursements/id/' + this.state.id)
	   .then((result) => {
		   const data = result.data.data
		   this.setState({
			 disbursement: { 
				medical_report_incidentals: data.medical_report_incidentals,
		   }
		   })
		   localStorage.setItem('disbursement_id',  data.disbursement_id);
		   localStorage.setItem('medical_report_incidentals',  data.medical_report_incidentals);
	   })
	   }else{
		   this.setState({
			   disbursement: { 
				medical_report_incidentals: '',
			 }
			
		 })
		
   }
  }

  handleClickSelecet7 = (event) => {
	console.log('7',this.refs.complete7.checked);
	var selectedIndex = this.refs.complete7.checked;
		if(selectedIndex == true) {
	   axios.get(API_BASE_URL+'/disbursements/id/' + this.state.id)
	   .then((result) => {
		   const data = result.data.data
		   this.setState({
			 disbursement: { 
				services_one: data.services_one,
		   }
		   })
		   localStorage.setItem('disbursement_id',  data.disbursement_id);
		   localStorage.setItem('services_one',  data.services_one);
	   })
	   }else{
		   this.setState({
			   disbursement: { 
				services_one: '',
			 }
			
		 })
		
   }
  }

  handleClickSelecet8 = (event) => {
	console.log('8',this.refs.complete8.checked);
	var selectedIndex = this.refs.complete8.checked;
		if(selectedIndex == true) {
	   axios.get(API_BASE_URL+'/disbursements/id/' + this.state.id)
	   .then((result) => {
		   const data = result.data.data
		   this.setState({
			 disbursement: { 
				services_two: data.services_two,
		   }
		   })
		   localStorage.setItem('disbursement_id',  data.disbursement_id);
		   localStorage.setItem('services_two',  data.services_two);
	   })
	   }else{
		   this.setState({
			   disbursement: { 
				services_two: '',
			 }
			
		 })
		
   }
  }
  /*****************add claim details */
     addclaims = _ => {
		var p_type = localStorage.getItem('p_type');
    let initialState_claim = {
        claim : JSON.parse(localStorage.getItem('claim'))
	   }
	  // var hospital_id = localStorage.getItem('my_hospital_id');
		var plaintiff_id = localStorage.getItem('my_token');
		var inc_val =  parseInt(localStorage.getItem("inc_val_othersdamage"));
		var pushotherarray = [];
	   for (var i = 1; i <= inc_val; i++) {
		let others_data = JSON.parse(localStorage.getItem('append_otherdamges'+[i]));
		console.log('append_otherdamges_data',others_data);
		
		if(others_data.custom_labels!= ''){
			others_data.custom_labels = others_data.custom_labels;
		}else{
			others_data.custom_labels = '';
		}

		if(others_data.other_damages!= ''){
			others_data.other_damages = others_data.other_damages;
		}else{
			others_data.other_damages = '';
		}

		if((others_data.custom_labels!= '') && (others_data.other_damages!= '')){
			pushotherarray.push(others_data);
			console.log('pushotherarray',pushotherarray)
           
		}

	   }
	   localStorage.setItem("pushhh_other_damages", JSON.stringify(pushotherarray))

		var other_damages = localStorage.getItem('pushhh_other_damages');
		console.log('other_damagesaaaa',other_damages)
    const { claim } = initialState_claim
    console.log('claim_list',claim);
    fetch(API_BASE_URL+`/files/addClaimdetails?plaintiff_id=${plaintiff_id}&pI_general_1=${claim.pI_general_1}&pI_general_2=${claim.pI_general_2}&pI_general_3=${claim.pI_general_3}&pI_general_4=${claim.pI_general_4}&pI_speciall_1=${claim.pI_speciall_1}&pI_speciall_2=${claim.pI_speciall_2}&pI_speciall_3=${claim.pI_speciall_3}&pI_speciall_4=${claim.pI_speciall_4}&PD_general_1=${claim.PD_general_1}&PD_general_2=${claim.PD_general_2}&PD_general_3=${claim.PD_general_3}&PD_general_4=${claim.PD_general_4}&PD_speciall_1=${claim.PD_speciall_1}&PD_speciall_2=${claim.PD_speciall_2}&PD_speciall_3=${claim.PD_speciall_3}&PD_speciall_4=${claim.PD_speciall_4}&other_damages=${other_damages}`)
   
	 .catch( err => console.log(err))
	
    }
 

   /*****hospital details*********/

  addHospitals = _ => {
    let initialState_hospitals = {
        hospital : JSON.parse(localStorage.getItem('hospital'))
	   }
	     var hospital_id = localStorage.getItem('my_hospital_id');
		var plaintiff_id = localStorage.getItem('my_token');
		var date_report = localStorage.getItem('date_report');
    const { hospital } = initialState_hospitals
    console.log('hpspital_list',hospital);
    fetch(API_BASE_URL+`/files/addHospitaldetails?plaintiff_id=${plaintiff_id}&hospital_id=${hospital_id}&hospital_name=${hospital.hospital_name}&hospital_address=${hospital.hospital_address}&fax_number=${hospital.fax_number}&tel_number=${hospital.tel_number}&type_report=${hospital.type_report}&date_report=${date_report}&amount=${hospital.amount}&department=${hospital.department}`)
    
    //.then(this.addclaims)
    .catch( err => console.log(err))
		var plaintiff_id = localStorage.getItem('my_token');
		let hospital_check = JSON.parse(localStorage.getItem('appenddata_hospitals'));
		//if(hospital_check!=null){
			var inc_val =  parseInt(localStorage.getItem("inc_val"));
	   for (var i = 1; i <= inc_val; i++) {
		let hospital = JSON.parse(localStorage.getItem('appenddata_hospitals'+[i]));
		   console.log('appenddata_hospitals',hospital);
		   
		   if(hospital.hospital_name!= ''){
			hospital.hospital_name = hospital.hospital_name;
		   }else{
			hospital.hospital_name = '';
		   }

		   if(hospital.hospital_address!= ''){
			hospital.hospital_address = hospital.hospital_address;
		   }else{
			hospital.hospital_address = '';
		   }

		   if(hospital.date_report!= ''){
			hospital.date_report = hospital.date_report;
		   }else{
			hospital.date_report = '';
		   }

		   if(hospital.type_report!= ''){
			hospital.type_report = hospital.type_report;
		   }else{
			hospital.type_report = '';
		   }

		   if(hospital.fax_number!= ''){
			hospital.fax_number = hospital.fax_number;
		   }else{
			hospital.fax_number = '';
		   }

		   if(hospital.tel_number!= ''){
			hospital.tel_number = hospital.tel_number;
		   }else{
			hospital.tel_number = '';
		   }

		   if(hospital.amount!= ''){
			hospital.amount = hospital.amount;
		   }else{
			hospital.amount = '';
		   }

		   if(hospital.department!= ''){
			hospital.department = hospital.department;
		   }else{
			hospital.department = '';
		   }

		   if((hospital.hospital_name!= '') && (hospital.hospital_address!= '') && (hospital.date_report!='') && (hospital.type_report!= '')&& (hospital.fax_number!= '') && (hospital.tel_number!= '') && (hospital.amount!= '')&& (hospital.department!= '')&& (hospital.hospital_id!= '')){
		   fetch(API_BASE_URL+`/files/addHospitaldetails?plaintiff_id=${plaintiff_id}&hospital_id=${hospital.hospital_id}&hospital_name=${hospital.hospital_name}&hospital_address=${hospital.hospital_address}&fax_number=${hospital.fax_number}&tel_number=${hospital.tel_number}&type_report=${hospital.type_report}&date_report=${hospital.date_report}&amount=${hospital.amount}&department=${hospital.department}`)
		 .catch( err => console.log(err))
		   }
	   } //}

     }

    /*-------------------------------------*/
  	 
	adddefendents = _ => {
		var plaintiff_id = localStorage.getItem('my_token');
		var p_type = localStorage.getItem('p_type');
		let initialState_defendent = {
			defendent : JSON.parse(localStorage.getItem('defendent'))
		   }
	   const { defendent } = initialState_defendent
	fetch(API_BASE_URL+`/files/addDefendentdetails?plaintiff_id=${plaintiff_id}&defendent_name=${defendent.defendent_name}&defendent_address=${defendent.defendent_address}&defendent_vehicle_no=${defendent.defendent_vehicle_no}&defendent_nric=${defendent.defendent_nric}&defendent_mobile_number=${defendent.defendent_mobile_number}&defendent_insurance=${defendent.defendent_insurance}`)
	//.then(this.addclaims)
	.catch( err => console.log(err))
	
	var plaintiff_id = localStorage.getItem('my_token');
		let defendent_check = JSON.parse(localStorage.getItem('appenddata_defendents'));
		var idef_inc_val =  parseInt(localStorage.getItem("idef_inc_val"));
		//if(defendent_check!= null){
	   for (var i = 1; i <= idef_inc_val; i++) {
		let defen = JSON.parse(localStorage.getItem('appenddata_defendents'+[i]));
		   console.log('appenddata_defendents',defen);
		   if(defen.defendent_name!= ''){
			defen.defendent_name = defen.defendent_name;
		   }else{
			defen.defendent_name = '';
		   }
		   if(defen.defendent_address!= ''){
			defen.defendent_address = defen.defendent_address;
		   }else{
			defen.defendent_address = '';
		   }
		   if(defen.defendent_vehicle_no!= ''){
			defen.defendent_vehicle_no = defen.defendent_vehicle_no;
		   }else{
			defen.defendent_vehicle_no = '';
		   }
		   if(defen.defendent_nric!= ''){
			defen.defendent_nric = defen.defendent_nric;
		   }else{
			defen.defendent_nric = '';
		   }
		   if(defen.defendent_mobile_number!= ''){
			defen.defendent_mobile_number = defen.defendent_mobile_number;
		   }else{
			defen.defendent_mobile_number = '';
		   }
		   if(defen.defendent_insurance!= ''){
			defen.defendent_insurance = defen.defendent_insurance;
		   }else{
			defen.defendent_insurance = '';
		   }
		   if((defen.defendent_name!= '') && (defen.defendent_address!= '') && (defen.defendent_vehicle_no!='') && (defen.defendent_nric!= '')&& (defen.defendent_mobile_number!= '') && (defen.defendent_insurance!= '')){
			fetch(API_BASE_URL+`/files/addDefendentdetails?plaintiff_id=${plaintiff_id}&defendent_name=${defen.defendent_name}&defendent_address=${defen.defendent_address}&defendent_vehicle_no=${defen.defendent_vehicle_no}&defendent_nric=${defen.defendent_nric}&defendent_mobile_number=${defen.defendent_mobile_number}&defendent_insurance=${defen.defendent_insurance}`)
		 .catch( err => console.log(err))
		   }
	  // }
    }
    //    if(p_type == 'personal_injury'){
	// 	this.addHospitals();
	//   }else{
	// 	this.addclaims();
	//  }
	
	}
	
	
	
	/*---------add disbursement---------------*/

	addDisbursement = _ => {
       
		var plaintiff_id = localStorage.getItem('my_token');
		var disbursement_id = localStorage.getItem('disbursement_id');
		var documents_lta_search_fee = localStorage.getItem('documents_lta_search_fee');
		var documents_gia_report_fee = localStorage.getItem('documents_gia_report_fee');
		var documents_police_reports = localStorage.getItem('documents_police_reports');
		var medical_reports = localStorage.getItem('medical_reports');
		var medical_report_color_copies = localStorage.getItem('medical_report_color_copies');
		var medical_report_incidentals = localStorage.getItem('medical_report_incidentals');
		var services_one = localStorage.getItem('services_one');
		var services_two = localStorage.getItem('services_two');
		var p_type = localStorage.getItem('p_type');
		const { disbursement } = this.state
		console.log('disbursement_list',disbursement);
		fetch(API_BASE_URL+`/files/addDisbursementdetails?disbursement_id=${disbursement_id}&plaintiff_id=${plaintiff_id}&documents_lta_search_fee=${documents_lta_search_fee}&documents_gia_report_fee=${documents_gia_report_fee}&documents_police_reports=${documents_police_reports}&medical_reports=${medical_reports}&medical_report_color_copies=${medical_report_color_copies}&medical_report_incidentals=${medical_report_incidentals}&services_one=${services_one}&services_two=${services_two}`)
		
		.catch( err => console.log(err))

		{this.adddefendents()};
		{this.addclaims()};
		{this.addHospitals()};

        localStorage.clear();
		window.location.href = '/files';
	   
		//localStorage.clear();
		}
	
	 
		documents = _ => {
			{this.addDisbursement()};
		  window.location.href = '/files/documents';
			}

	/*------------------------------------------*/
   
	
	
  render() {
	  const { disbursements, disbursement } = this.state
	  return (
        <div className="wrapper"> 
 
        <Header />
        <MenuSidebar />		
  
      
      <div className="content">	
          <div className="content-wrapper">
              <div className="content-body">
              <div className="form-wrapper">			
				 <div className="title">
					<h4>DISBURSEMENT DETAILS</h4>
				 </div>
                 
        <div className="form-row">
			<div className="form-left">
			<h4 className="sub-title">Documents</h4>

			<div class="form-group">
				<div className="form-group-row">
					<div className="form-group-left">
						<label>LTA Search Fee</label>
						<input name="autofill"  onChange={this.handleClickSelecet1} ref="complete1" type="checkbox" placeholder="" />	
					</div>					
						<div className="form-group-right doller-inputbox">
						<span className="doller">$</span>
						<input type="text" className="form-control"  placeholder="" value={disbursement.documents_lta_search_fee}
				onChange={e => this.setState({ disbursement: {...disbursement, documents_lta_search_fee:e.target.value}})} />
					</div>
				</div>
			</div>
			<div class="form-group">
				<div className="form-group-row">
					<div className="form-group-left">
						<label>GIA Report Fee</label>
						<input name="autofill"  onChange={this.handleClickSelecet2} ref="complete2" type="checkbox" placeholder="" />
					</div>	
					<div className="form-group-right doller-inputbox">
						<span className="doller">$</span>
						<input type="text" className="form-control"   placeholder="" value={disbursement.documents_gia_report_fee}
				onChange={e => this.setState({ disbursement: {...disbursement, documents_gia_report_fee:e.target.value}})} />
					</div>
				</div>
			</div>

			<div class="form-group">
				<div className="form-group-row">
					<div className="form-group-left">
					<label>Police Reports $</label>
					<input name="autofill"  onChange={this.handleClickSelecet3} ref="complete3" type="checkbox" placeholder="" />
					</div>
					<div className="form-group-right doller-inputbox">
						<span className="doller">$</span>
					<input type="text" className="form-control"   placeholder="" value={disbursement.documents_police_reports}
			onChange={e => this.setState({ disbursement: {...disbursement, documents_police_reports:e.target.value}})} />
					</div>
				</div>
			</div>

			</div>
			<div className="form-right">
			<h4 className="sub-title">Medical Reports </h4>

			<div class="form-group">
				<div className="form-group-row">
					<div className="form-group-left">
						<label>Medical Reports</label>
						<input name="autofill"  onChange={this.handleClickSelecet4} ref="complete4" type="checkbox" placeholder="" />
					</div>					
					<div className="form-group-right doller-inputbox">
						<span className="doller">$</span>
					<input type="text" className="form-control"  placeholder="" value={disbursement.medical_reports}
			onChange={e => this.setState({ disbursement: {...disbursement, medical_reports:e.target.value}})} />
					</div>
				</div>
			</div>

			<div class="form-group">
				<div className="form-group-row">
					<div className="form-group-left">
					<label>Colour Copies</label>
					<input name="autofill"  onChange={this.handleClickSelecet5} ref="complete5" type="checkbox" placeholder="" />
					</div>
					<div className="form-group-right doller-inputbox">
						<span className="doller">$</span>
					<input type="text" className="form-control"   placeholder="" value={disbursement.medical_report_color_copies}
			onChange={e => this.setState({ disbursement: {...disbursement, medical_report_color_copies:e.target.value}})} />
					</div>
				</div>
			</div>

			<div class="form-group">
				<div className="form-group-row">
					<div className="form-group-left">
					<label>Incidentals</label>
					<input name="autofill"  onChange={this.handleClickSelecet6} ref="complete6" type="checkbox" placeholder="" />
					</div>
					<div className="form-group-right doller-inputbox">
						<span className="doller">$</span>
					<input type="text" className="form-control"   placeholder="" value={disbursement.medical_report_incidentals}
			onChange={e => this.setState({ disbursement: {...disbursement, medical_report_incidentals:e.target.value}})} />
					</div>
				</div>
			</div>

		</div>
	</div>
			
			 <div className="title">
				<h4>Services</h4>
	         </div>

			<div className="form-row">				
			<div className="form-left">

				<div class="form-group">
					<div className="form-group-row">
						<div className="form-group-left">
						<label>Services 1</label>
						<input name="autofill"  onChange={this.handleClickSelecet7} ref="complete7" type="checkbox" placeholder="" />
						</div>
						<div className="form-group-right doller-inputbox">
							<span className="doller">$</span>
						<input type="text" className="form-control"  placeholder="" value={disbursement.services_one}
				onChange={e => this.setState({ disbursement: {...disbursement, services_one:e.target.value}})} />
						</div>
					</div>
				</div>

			</div>

			<div className="form-right">
			    <div class="form-group">
					<div className="form-group-row">
						<div className="form-group-left">
						<label>Services 2</label>
						<input name="autofill"  onChange={this.handleClickSelecet8} ref="complete8" type="checkbox" placeholder="" />
						</div>
						<div className="form-group-right doller-inputbox">
						<span className="doller">$</span>
						<input type="text" className="form-control"  placeholder="" value={disbursement.services_two}
				onChange={e => this.setState({ disbursement: {...disbursement, services_two:e.target.value}})} />
						</div>
					</div>
				</div>

			</div>
			</div>
			
			<div className="btn-group export">	
				<input type="submit" className="btn" onClick={this.addDisbursement} value="SUBMIT AND GO TO DASHBOARD" />
	  </div>
	  <div className="btn-group export">	
	  <input type="submit" className="btn"  onClick={this.documents}  value="SUBMIT AND UPLOADS DOCUMENTS" />
    </div> 
	 <div className="btn-group next-back-group">	
			<Link className="back" to={"/files/addClaimdetails"}> <i class="arrow icon"></i></Link>
            </div>
			</div>	
        </div>	
		</div>	
	</div>
		 	
    </div>
	  );
  }
}

export default Disbursementdetails;