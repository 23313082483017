import React, { Component } from 'react';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { Link } from "react-router-dom";
import { API_BASE_URL } from '../config/api';

const axios = require("axios");
class Letterone extends Component {
	
	constructor(props)
	{
		super(props);	
		this.state = {
            files: [],
			errors: {},
			errors2: {},
			searchfiles: [],
			deleteRecord: "",
			query : "",
			file: {
			  plaintiff_name:'',
				minor:'',
				plaintiff_ic_number:'',
				plaintiff_mobile_number:'',
				plaintiff_occupation:'',
				plaintiff_dob:'',
				plaintiff_address:'',
				plaintiff_telephone_number:'',
				referral_by:'',
				accident_date:'',
				accident_time:'',
				accident_type:'',
				accident_location:'',
				plaintiff_position:'',
				time_bar:'',
				p_type:'',
				case_file_number:'',
				plaintiff_vehicle_number:'',
				vehicle_in_front:'',
				vehicle_in_back:''
			  			  
            },
            user: {
                name:'',
        address:'',
        password:'',
        email:'',
        department:'',
        status:'',
        confirm_password:''
    },
    case_file_number:'',
    name:'',
    address:''
		};
		
    }


    componentDidMount(){
        this.getfiles()
       }
       
   
     getfiles = _ => {
       fetch(API_BASE_URL+'/files')
       .then((response) => {
           return response.json();
         })
         
         .then(response =>{
           localStorage.setItem('data_hos',JSON.stringify(response.data))   
           this.setState({
               files: [{value: '', case_file_number: 'Case File Reference'}].concat(response.data)
           })})
           .catch(err => console.log(err))
        
   
      }

      
  handleClickSelecet = (event) => {
    const selectedIndex = event.target.options.selectedIndex;
    var key = event.target.options[selectedIndex].getAttribute('data-key');
    console.log('gshiiiii',key);
axios.get(API_BASE_URL+'/files/id/' + key)

 .then((result) => {  
   const data = result.data.data
   console.log('uodatettetet',data);
   
   if(data !== undefined && data !== undefined) {
      this.setState({

          file: {
              plaintiff_name:data.plaintiff_name,
              minor:data.minor,
              plaintiff_ic_number:data.plaintiff_ic_number,
              plaintiff_mobile_number:data.plaintiff_mobile_number,
              plaintiff_occupation:data.plaintiff_occupation,
              plaintiff_dob: data.plaintiff_dob,
              plaintiff_address: data.plaintiff_address,
              plaintiff_telephone_number: data.plaintiff_telephone_number,
              referral_by: data.referral_by,
              accident_date: data.accident_date,
              accident_time:data.accident_time,
              accident_type:data.accident_type,
              accident_location:data.accident_location,
              plaintiff_position:data.plaintiff_position,
              time_bar:data.time_bar,
              p_type:data.p_type,
              case_file_number: data.case_file_number,
              plaintiff_vehicle_number:data.plaintiff_vehicle_number,
              vehicle_in_front:data.vehicle_in_front,
              vehicle_in_back:data.vehicle_in_back,
              plaintiff_id:data.plaintiff_id,
              user_id:data.user_id
                      
          },
          accident_date : this.convert(data.accident_date),
   })

   this.user(data.user_id);
   localStorage.setItem('case_file_numberletter', data.case_file_number)
   localStorage.setItem('accdate', this.convert(data.accident_date))
  }else{

      this.setState({
          file: {
              plaintiff_name:'',
              minor:'',
              plaintiff_ic_number:'',
              plaintiff_mobile_number:'',
              plaintiff_occupation:'',
              plaintiff_dob: '',
              plaintiff_address: '',
              plaintiff_telephone_number: '',
              referral_by: '',
              accident_date: '',
              accident_time:'',
              accident_type:'',
              accident_location:'',
              plaintiff_position:'',
              time_bar:'',
              p_type:'',
              case_file_number: '',
              plaintiff_vehicle_number:'',
              vehicle_in_front:'',
              vehicle_in_back:'',
              plaintiff_id:'',
              user_id:''

                      
          }
      })

    }
})
};

user(user_id) {
    axios.get(API_BASE_URL+'/users/id/' + user_id)
         .then((result) => {
             console.log(result.data);
             const data = result.data.data

             this.setState({
                user: {
                    name:data.name,
                    address:data.address,
                    department:data.department,
                    status:data.status,
                    email:data.email,
                    password:data.password,
                    user_id:data.user_id
                            
				}
             })
             
             localStorage.setItem('username', data.name)
             localStorage.setItem('useraddress', data.address)
             localStorage.setItem('useremail', data.email)
            
			
             })
     

   }

convert(str) {
	var date = new Date(str),
	  mnth = ("0" + (date.getMonth() + 1)).slice(-2),
	  day = ("0" + date.getDate()).slice(-2);
	  var hours = date.getHours();
var minutes = date.getMinutes();
minutes = minutes < 10 ? '0' + minutes : minutes;
var strTime = hours + ':' + minutes;
	//return [date.getFullYear(), mnth, day].join("/");
	return [day, mnth, date.getFullYear()].join("/");

}
convertdob(str) {
	var date = new Date(str),
	  mnth = ("0" + (date.getMonth() + 1)).slice(-2),
	  day = ("0" + date.getDate()).slice(-2);
	  var hours = date.getHours();
var minutes = date.getMinutes();
minutes = minutes < 10 ? '0' + minutes : minutes;
var strTime = hours + ':' + minutes;
	return [date.getFullYear(), mnth, day].join("-");

}   

next = _ => {
   
     window.location.href = '/letters';
               
    }
	
  render() {
    const { files, file,user } = this.state
    console.log('map',files);
    var case_file_number = localStorage.getItem('case_file_numberletter');
    var name = localStorage.getItem('username');
    var address = localStorage.getItem('useraddress');
    var email = localStorage.getItem('useremail');
    var accdate = localStorage.getItem('accdate');
    return (
      <div className="wrapper"> 
 
	  <Header />
	  <MenuSidebar />		

	
	<div className="content">	
		<div className="content-wrapper">
			<div className="content-body">
				<div className="letters-wrap">				
					<div className="title">
						<h4>Thank You Letters</h4>
					</div>
					<div className="letters-row">
						<div className="letters-left">					    
						<div className="letters-left-inner">	
							<div className="form-group">
                            <select  onChange={this.handleClickSelecet}  value={file.case_file_number}  >                    
        {this.state.files.map((files) => <option key={files.plaintiff_id} data-key={files.plaintiff_id} value={files.case_file_number} >{files.case_file_number}</option>)}
	</select>
							</div>
                            <div className="form-group">
								<input type="text" className="form-control" placeholder="Salution" value=""  />
							</div>
                            <div className="form-group">
								<input type="text" className="form-control" placeholder="Client's Name" value={user.name} />
							</div>
                            <div className="form-group">
								<input type="text" className="form-control" placeholder="Client's  Address" value={user.address}  />
							</div>
                            <div className="form-group">
								<input type="text" className="form-control" placeholder="Client's Vehicle Number" value=""  />
							</div>
                            <div className="form-group">
								<input type="text" className="form-control" placeholder="Other Vehicle Number" value={file.plaintiff_vehicle_number}  />
							</div>
                            <div className="form-group">
								<input type="text" className="form-control" placeholder="Date of Accident" value={this.state.accident_date}  />
							</div>
                            <div className="form-group">
								<input type="text" className="form-control" placeholder="Date of Letter" value=""  />
							</div>

							<div class="btn-group center">
								<input type="submit" class="btn" onClick={this.next} value="Generate" />
							</div>
						</div>
						</div>
						
						
						<div className="letters-right">
						   <div className="legal-law-inner">
						   
							   <div className="legal-law-header">
							        <div className="header-left">
										<h3>Centro-Legal Law Corporation</h3>
									</div>
							        <div className="header-right">
										<p>151 Chin Swee Road<br/> #02-21 Manhattan House<br/> Singapore 169<br/>
										Tel: 6235 0633<br/>Fax: 6235 6939</p>
									</div>
							   </div>
							   <h4>ADVOCATES AND SOLICITORS</h4>
							   <div className="letter-text-wrap">
							   <p>Your Ref: To be advised</p>
                               <p>Our Ref: {file.case_file_number}</p>
							   <p>15 MAY-2020</p>
							   <div className="client-addr">
                                  
							   </div>

                               <b>
                                <p>{user.name}</p>
                               <p>{user.address}</p>
                               </b>
                               <br></br> 

                               <p>Dear Salution,</p>
                               <p><b><u> ACCIDENT ON Date({this.state.accident_date}) ALONG PAN ISLAND EPRESSWAY INVOLVING MOTOR 
                               </u></b></p> 
								 <p><b><u> VEHICLES ABC123 AND PC1234A </u></b></p>

                               <p>We thank you for your instructions to act you in the above matter.</p>
                               <p>Kindly forward to us all relevalent documents including latest medical receipts and medical leave
                               certifiactes,if any in your possession,as soon as possible.</p>
							   </div>
						      
						   </div>
						</div>
						
						
						
						
					</div>
				</div>
			</div>	
		</div>	
	</div>
		 	
    </div>
    );
  }
}

export default Letterone;