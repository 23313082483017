import React, { Component } from 'react';
import { Link } from "react-router-dom";
import  Header from '../Layouts/Header';
import  onSubmitMessage from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { API_BASE_URL } from '../config/api';
const axios = require("axios");

class EditProfile extends Component {
	
	constructor(props)
	{
		super(props);	
		this.state = {
            users: [],
            files:[],
            file:[],
            deleteRecord: "",
            errors: {},
            mailmsg: {},
            input: {},
            user: {
				name:'',
                address:'',
                password:'',
                email:'',
                department:'',
                status:'',
                confirm_password:''
            },
            id: 1
        };
        
        this.handleSubmit = this.handleSubmit.bind(this);
		
		
    }

    componentDidMount(){
		
         axios.get(API_BASE_URL+'/login/id/' + this.state.id)
         .then((result) => {
             console.log(result.data);
             const data = result.data.data

             this.setState({
                user: {
                    name:data.admin_name,
                    email:data.email,
                    password:data.password,
                    user_id:data.admin_id
                            
				}
			 })
			
             })

        

		}

    

      handleSubmit(event) {
        event.preventDefault();
        this.validate();
      }
    
      validate(){
    
          let input = this.state.user;

          let errors = {};
          let isValid = true;
          if (typeof input["email"] !== "undefined") {
    
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    
            if (!pattern.test(input["email"])) {
    
              isValid = false;
    
              errors["email"] = "Please enter valid email address.";
    
            }
    
          }

          if (!input["name"]) {
            isValid = false;
            errors["name"] = "Please enter your name.";
          }
    
          if (!input["email"]) {
            isValid = false;
            errors["email"] = "Please enter your email.";
          }

          if (!input["password"]) {
            isValid = false;
            errors["password"] = "Please enter your password.";
          }
          if (!input["confirm_password"]) {
            isValid = false;
            errors["confirm_password"] = "Please enter your confirm password.";
          }
          if (typeof input["password"] !== "undefined" && typeof input["confirm_password"] !== "undefined") {
            if (input["password"] != input["confirm_password"]) {
              isValid = false;
              errors["password"] = "Passwords don't match.";
            }
          } 
    
          this.setState({
            errors: errors
          }); 
          console.log('sdsds',isValid);
         if( isValid ==true){
            this.editProfile();
        }
    
      }

      
      uploadfiless(e) {
        // let reader = new FileReader();
        // let file = e.target.files[0];
  
        //   //   reader.onloadend = () => {
        //   //     this.photo = reader.result.toString();
        //   // };
        //  // this.photo_name = oldFileItem+'.'+'jpg';
        //   var imgFile = new File([file], {type:file.type});
        //   console.log('imageefilesss',file);
        //   reader.readAsDataURL(file);
        e.preventDefault();
       // this.setState({ file:  e.target.files });
        console.log('aaaaffffff',e.target.files);
        const formData = new FormData();
        formData.append('file[]', e.target.files[0]);
    
        console.log('filessprofile',e.target.files[0]);
      axios.post(API_BASE_URL+'/users/upload_file', formData).then(response => {
      if(response.data.status==="success") {
       // this.setState({ file:  response.img_name});
       console.log('filessprofile',response);
       localStorage.setItem('profile_image', response.data.img_name[0].filename);
    
    }
    });
        }
    
      editProfile = _ => {
        const { user } = this.state;
        let mailmsg = {};
        var status = 'Active';
        var profile_image = localStorage.getItem('profile_image');
        fetch(API_BASE_URL+`/login/update?name=${user.name}&email=${user.email}&password=${user.password}&admin_id=${this.state.id}&profile_image=${profile_image}`)
        
        .catch( err => console.log(err))
        mailmsg["msg"] = 'Profile updated successfully.';
              this.setState({
                mailmsg: mailmsg
              }); 
       window.location.href = '/login/edit/' + this.state.id;
        }
	
	
  render() {
    const { users, user} = this.state
    return (
        <div className="wrapper"> 
 
        <Header />
        <MenuSidebar />	  
      
      <div className="content">	
      <div className="content-wrapper">
      <div className="content-body">
          <div className="form-wrapper edit-profile-form">
          <div className="title">
            <h4>Edit Profile</h4>
          </div>
          <div style={{color: "green"}} className="text-danger">{this.state.mailmsg.msg}</div>
          <div className="form-row">
          <div className="form-left">
                
          <div class="form-group">
            <label>Admin Name</label>
            <input type="text" className="form-control" onChange={e => this.setState({ user: {...user, name:e.target.value}})} placeholder="" value={user.name} />
                      <div style={{color: "red"}} className="text-danger">{this.state.errors.name}</div>
          </div>

          <div class="form-group">
            <label>Password</label>
            <input type="password" onChange={e => this.setState({ user: {...user, password:e.target.value}})} placeholder="" value={user.password} className="form-control"  />
                      <div style={{color: "red"}} className="text-danger">{this.state.errors.password}</div>
              </div>
          </div>
          <div className="form-right">
          <div class="form-group">
              <label>Email</label>
              <input type="text"  onChange={e => this.setState({ user: {...user, email:e.target.value}})} placeholder=""  className="form-control" value={user.email} />
                <div style={{color: "red"}} className="text-danger">{this.state.errors.email}</div>
          </div>     
          <div class="form-group">
              <label>Confirm Password </label>
              <input type="password" onChange={e => this.setState({ user: {...user, confirm_password:e.target.value}})} placeholder=""  className="form-control" value={user.confirm_password}/>
                <div style={{color: "red"}} className="text-danger">{this.state.errors.confirm_password}</div>
                    </div>
                    <div className="file-upload">
                     <input type="file"    onChange={this.uploadfiless} />
			    	</div>
          </div>      
          <div className="btn-group export">	
            <input type="submit" onClick={this.handleSubmit} className="btn" value="Update" />
          </div>
          </div>
              
        </div>
		</div>
    </div>		
	  </div>		 	
    </div>
    );
  }
}

export default EditProfile;