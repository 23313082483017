import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { API_BASE_URL } from '../config/api';
 
const axios = require("axios");


class Hospitaldetails extends React.Component {
	
	constructor(props)
	{
		super(props);	
		this.state = {
            hospitals: [],
            hospital_aapend : [],
            selectedhospitals: "",
            hospital : "",
            validationError: "",
      deleteRecord: "",
      setIncVal:0,      
			hospital: {
                hospital_name: '',
                hospital_address: '',
              date_report : '',
              type_report : '',
			          fax_number: '',
			        tel_number: '',
              amount: '',
              department: ''			  
      },
      date_report : '',
    };	
    
    }
	
	componentDidMount(){
     this.getHospitals()
    
    }

    onChangehospitaldate = (date_report) => {  
      this.setState({ date_report })
      var date_report =  this.convert(date_report);
      localStorage.setItem('date_report', date_report) 
   
   }
   
 convert(str) {
   var date = new Date(str),
     mnth = ("0" + (date.getMonth() + 1)).slice(-2),
     day = ("0" + date.getDate()).slice(-2);
     var hours = date.getHours();
 var minutes = date.getMinutes();
 minutes = minutes < 10 ? '0' + minutes : minutes;
 //var strTime = hours + ':' + minutes;
   return [date.getFullYear(), mnth, day].join("-");
 
 }
    
    handleClickSelecet = (event) => {
      const selectedIndex = event.target.options.selectedIndex;
      var key = event.target.options[selectedIndex].getAttribute('data-key');
      console.log('gshiiiii',key);
  axios.get(API_BASE_URL+`/hospitals/search_id?hospital_id=${key}` )

   .then((result) => {  
     const data = result.data[0]
     console.log('uodatettetet',data);
     if(data !== undefined && data !== undefined) {
      var my_id= data.hospital_id;
      console.log('iddd',my_id)
      localStorage.setItem('my_hospital_id', my_id)
     this.setState({
       hospital: {
         hospital_name: data.name,
         hospital_address: data.address,
         fax_number: data.fax,
         tel_number: data.telephone,
         amount: data.amount
         //hospital_id: data.hospital_id			  
       }
     })
    }else{

      this.setState({
        hospital: {
          hospital_name: '',
          hospital_address: '',
          fax_number: '',
          tel_number: '',
          amount: ''
          //hospital_id: data.hospital_id			  
        }
      })

    }
 })

// console.log
   
}

    handleFormSubmit = () => {
		const { hospital } = this.state
    localStorage.setItem('hospital',  JSON.stringify(hospital));
    var value_incc = parseInt(localStorage.getItem("inc_val"));
    //var value_incc_field = parseInt(localStorage.getItem("inc_val"));
    for (var i = 1; i <= value_incc; i++) {
    var name_append = "name_append"+[i];
         var address_append = "address_append"+[i];
         var date_report = "date_report"+[i];
         var type_report = "type_report"+[i];
         var fax_number_append = "fax_number_append"+[i];
         var tel_number_append = "tel_number_append"+[i];
         var amount_append = "amount_append"+[i];
         var department = "department"+[i];
         var hospital_id = "id_append"+[i];
       var name = localStorage.getItem(name_append);
       var address = localStorage.getItem(address_append);
       var date = localStorage.getItem(date_report);
       var type = localStorage.getItem(type_report);
       var fax = localStorage.getItem(fax_number_append);
       var tel = localStorage.getItem(tel_number_append);
       var amount = localStorage.getItem(amount_append);
       var dept = localStorage.getItem(department);
       var hospital_id_id = localStorage.getItem(hospital_id);
         console.log('name_append454545',hospital_id_id);
      if((name!='') && (address!='') && (date!='') && (type!='')&& (fax!='') && (tel!='') && (amount!='')&& (dept!='')&& (hospital_id_id!='')){
         var appenddata = {
                hospital_name: localStorage.getItem((name_append)),
                hospital_address: localStorage.getItem(address_append),
              date_report : localStorage.getItem(date_report),
              type_report : localStorage.getItem(type_report),
                fax_number: localStorage.getItem(fax_number_append),
              tel_number: localStorage.getItem(tel_number_append),
              amount: localStorage.getItem(amount_append),
              department: localStorage.getItem(department),
              hospital_id: localStorage.getItem(hospital_id)					  
      
    };
    console.log('appenddata',appenddata); 
    if((appenddata.hospital_name!= null) && (appenddata.hospital_address!= null) && (appenddata.date_report!=null) && (appenddata.type_report!= null)&& (appenddata.fax_number!= null) && (appenddata.tel_number!= null) && (appenddata.amount!= null)&& (appenddata.department!= null)&& (appenddata.hospital_id!= null)){
    localStorage.setItem("appenddata_hospitals"+[i], JSON.stringify(appenddata));
    }
   } }


	  };

	getHospitals = _ => {
    fetch(API_BASE_URL+'/hospitals')
    .then((response) => {
        return response.json();
      })
      
      .then(response =>{
        localStorage.setItem('data_hos',JSON.stringify(response.data))   
        this.setState({
            hospitals: [{value: '', name: 'Select Your Hospitals'}].concat(response.data)
        })})
        .catch(err => console.log(err))
     

   }

   
  
  handleChangeDelete = e => {
    this.setState({
      deleteRecord: e.target.name
    });
  };
  handleSubmit = e => {
    e.preventDefault();

    var Formdata = {
      deleteRecord: this.state.deleteRecord
    };

    axios.post(API_BASE_URL+'/hospitals/delete', { Formdata }).then(response => {
      console.log(response.data);
      console.log(response);
	 this.getHospitals();
	 
    });
  };
  
	addHospitals = _ => {
    const { hospital } = this.state
    fetch(API_BASE_URL+`/files/add?hospital_name=${hospital.hospital_name}&hospital_address=${hospital.hospital_address}&fax_number=${hospital.fax_number}&tel_number=${hospital.tel_number}&type_report=${hospital.type_report}&date_report=${hospital.date_report}&amount=${hospital.amount}&department=${hospital.department}`)
    .then(this.getHospitals)
    .catch( err => console.log(err))
    }
   
   
    addhospitalChild (inc,e) {
      if (localStorage.getItem("inc_val") > 0){
      var inc_val =  parseInt(localStorage.getItem("inc_val"))+1;
      }else{
        var inc_val =  parseInt(inc)+1; 
      }

      localStorage.setItem("clicked_add", 'yes')
      console.log('inc_val',inc_val);
      localStorage.setItem("inc_val", inc_val)
      var value_incc = parseInt(localStorage.getItem("inc_val"))-1;
      var value_incc_field = parseInt(localStorage.getItem("inc_val"))-1;
      var max_fields = 16;
      var wrapper = $(".hospital-details-wrap");
      var x = 0; 
      e.preventDefault();
      if (inc_val < max_fields) {
        //x++;
        console.log('inc_val',inc_val);
        console.log('max_fields',max_fields);
        //console.log('x',x);
        $(wrapper).append(`
        <div class="form-row hos_part">
        <div class="form-left">
          <div class="form-group">
          <input type="hidden" id='hospital_id`+[inc_val]+`' readOnly name="hospital_id[]" class="form-control hospital_id"  placeholder=""  
              />
            <label>Hospital Name </label>
            <select id='hospital_name`+[inc_val]+`'  name="hospital_name[]" class='hos_name'> \
            <option data-key="" value="">Select Your Hospitals</option>
              </select>
                      
          </div>
           <div class="form-group">
            <label>Hospital Address</label>
            <input type="text" id='hospital_address`+[inc_val]+`' readOnly name="hospital_address[]" class="form-control hospital_address"  placeholder=""  
              />
          </div>
          <div class="form-group">
          <label>Date Of Report</label>
          <input type="date" id='date_report`+[inc_val]+`' name="date_report[]" class="form-control date_report" placeholder="--Select--" value="" />
            </div>
            <div class="form-group">
            <label>Type Of Report</label>
            <input type="text" id='type_report`+[inc_val]+`' name="type_report[]" class="form-control type_report"  placeholder="" />
          </div>
        
        </div>
        <div class="form-right">
          <div class="form-group">
            <label>Fax Number</label>
            <input type="text" id='fax_number`+[inc_val]+`' readOnly name="fax_number[]" maxLength ="8" class="form-control fax_number" placeholder="" 
               />
          </div>
                  <div class="form-group">
            <label>Telephone Number</label>
            <input type="text" id='tel_number`+[inc_val]+`' readOnly name="tel_number[]" maxLength ="8" class="form-control tel_number"  placeholder="" 
              />
          </div>
          <div class="form-group">
            <label>Amount</label>
            <div class="doller-inputbox">
            <span class="doller">$</span>
            <input type="text"  id='amount`+[inc_val]+`' name="amount[]" class="form-control amount" placeholder="" 
              />
        </div>
          </div>
                  <div class="form-group">
            <label>Department</label>
            <input type="text" id='department`+[inc_val]+`'  name="department[]" class="form-control department" placeholder="" 
               />
          </div>
        </div>
        </div>
        `);
        var counter = 0;
       $('select#hospital_name'+[inc_val]).on('click',function (event) {
          console.log('bbbeeerrrrr');
          counter++;
           var data_hos =   JSON.parse(localStorage.getItem('data_hos')); 
           if(counter<2)
            $.each(data_hos, function (index, value) {
                // APPEND OR INSERT DATA TO SELECT ELEMENT.
                $('select#hospital_name'+[inc_val]).append('<option data-key="' + value.hospital_id + '" value="' + value.name + '">' + value.name + '</option>');
               
            });
  
    });
    
    var currentItemclick = 0;
    var append_data_hospitals = [];
    var appenddata = 	[];
    var a = [];
     $('select#hospital_name'+[inc_val]).change(function () {
      var hospital_name = $( "select#hospital_name"+[inc_val] ).val() || [];
      var data =$(this).find(':selected').data('key');
     console.log('ddaawee',data);
     currentItemclick++;

     if(currentItemclick > 1){
      
        $("#tel_number"+[inc_val]).val('');
        $("#amount"+[inc_val]).val('');
        $("#fax_number"+[inc_val]).val('');
        $("#hospital_address"+[inc_val]).val('');
         currentItemclick = 1;
    }
     axios.get(API_BASE_URL+`/hospitals/search_id?hospital_id=${data}` )
     .then((result) => {  
       const data = result.data[0]
       console.log('uodatettetet',data);
       if(data !== undefined && data !== undefined) {
         var my_id= data.hospital_id;
        // console.log('iddd',my_id)
        // localStorage.setItem('my_hospital_id', my_id)
        
        if (currentItemclick < 2) {
        $('#fax_number'+[inc_val]).val($('#fax_number'+[inc_val]).val() + data.fax);
        $('#tel_number'+[inc_val]).val($('#tel_number'+[inc_val]).val() + data.telephone);
        $('#amount'+[inc_val]).val($('#amount'+[inc_val]).val() + data.amount);
        $('#hospital_address'+[inc_val]).val($('#hospital_address'+[inc_val]).val() + data.address);
        localStorage.setItem("fax_number_append"+[inc_val], data.fax)
        localStorage.setItem("tel_number_append"+[inc_val], data.telephone)
        localStorage.setItem("amount_append"+[inc_val], data.amount)
        localStorage.setItem("address_append"+[inc_val], data.address)
        localStorage.setItem("name_append"+[inc_val], data.name)
        localStorage.setItem("id_append"+[inc_val], my_id)
        }
        console.log('currentItemclick',currentItemclick);

        
      }
     
      })
  
    });

   
          $('#department'+[inc_val]).on('change',function (e) {
         var department = $( "#department"+[inc_val] ).val() || [];
         console.log('department'+[inc_val],department);
         localStorage.setItem("department"+[inc_val], department)
        
        });
        $('#date_report'+[inc_val]).on('change',function (e) {
          var date_report = $( "#date_report"+[inc_val] ).val() || [];
          console.log('date_report'+[inc_val],date_report);
          localStorage.setItem("date_report"+[inc_val], date_report)
        
         });

         $('#type_report'+[inc_val]).on('change',function (e) {
          var type_report = $( "#type_report"+[inc_val] ).val() || [];
          console.log('type_report'+[inc_val],type_report);
          localStorage.setItem("type_report"+[inc_val], type_report)
         });
         
        
  }
};


	
  render() {
	  const { hospitals, hospital } = this.state
    console.log('map',hospitals);
    const date_report = this.state.date_report;
    var current = this;
    var inc = 0;
    return (	
		<div className="wrapper"> 
     	  <Header />
	      <MenuSidebar />		
       	<div className="content">	
		<div className="content-wrapper">
			<div className="content-body">
			<div className="form-wrapper">
			 <div className="title">
				<h4>HOSPITAL DETAILS</h4>
             </div>
             
			<div className="hospital-details-wrap">
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Hospital Name </label>
					<select  onChange={current.handleClickSelecet}  value={hospital.hospital_name}>                    
        {this.state.hospitals.map((hospitals) => <option key={hospitals.hospital_id} data-key={hospitals.hospital_id} value={hospitals.name} >{hospitals.name}</option>)}
            </select>
                    
				</div>
       	<div className="form-group">
					<label>Hospital Address</label>
					<input type="text" className="form-control"  placeholder="" value={hospital.hospital_address}
            onChange={e => this.setState({ hospital: {...hospital, hospital_address:e.target.value}})} 
            />
				</div>
        <div className="form-group">
        <label>Date Of Report</label>
          <DatePicker
        type ='date'
        placeholderText='--Select--'
				selected={date_report}
				//value={plaintiff_dob}
				onChange={this.onChangehospitaldate}
				dateFormat="dd/MM/yyyy"
                  />
          </div>
          <div className="form-group">
					<label>Type Of Report</label>
					<input type="text" className="form-control"  placeholder="" value={hospital.type_report}
            onChange={e => this.setState({ hospital: {...hospital, type_report:e.target.value}})} />
				</div>
			
			</div>
			<div className="form-right">
        <div className="form-group">
					<label>Fax Number</label>
					<input type="text" maxLength ="8" className="form-control" placeholder="" value={hospital.fax_number}
            onChange={e => this.setState({ hospital: {...hospital, fax_number:e.target.value}})} />
				</div>
                <div className="form-group">
					<label>Telephone Number</label>
					<input type="text" maxLength ="8" className="form-control"  placeholder="" value={hospital.tel_number}
            onChange={e => this.setState({ hospital: {...hospital, tel_number:e.target.value}})} />
				</div>
				<div className="form-group">
					<label>Amount</label>
					<div className="doller-inputbox">
					<span className="doller">$</span>
					<input type="text" className="form-control" placeholder="" value= {hospital.amount}
            onChange={e => this.setState({ hospital: {...hospital, amount:e.target.value}})} />
			</div>
				</div>
                <div className="form-group">
					<label>Department</label>
					<input type="text" className="form-control" placeholder="" value={hospital.department}
            onChange={e => this.setState({ hospital: {...hospital, department:e.target.value}})} />
				</div>
			</div>
			</div>
			
			</div>
      
      <div className="addmore-row">	
            <Link className="addmore-btn" onClick={this.addhospitalChild.bind(this,inc)} value='add more' > Add another </Link>
    </div>
		<div className="btn-group next-back-group">	
            <Link className="back" to={"/files/add"}> <i class="arrow icon"></i></Link>
            <Link className="next" onClick={this.handleFormSubmit} to={"/files/addDefendentdetails"}> <i class="arrow icon"></i></Link>
			</div>
      </div>	
			</div>

		</div>	
	</div>
		 	
    </div>
    );
  }
}

export default Hospitaldetails;