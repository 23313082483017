import React, { Component } from 'react';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { Link } from "react-router-dom";
import { API_BASE_URL } from '../config/api';

const axios = require("axios");

class EditHospital extends React.Component {
	
	constructor(props)
	{
		super(props);	
		this.state = {
			hospitals: [],
			hospital: {
			  name: '',
			  address: '',
			  email: '',
			  fax: '',
			  telephone: '',
			  amount: 0,
              hospital_id:0			  
			},
			id: this.props.match.params.id
		};	
    }
	
	componentDidMount(){
        axios.get(API_BASE_URL+'/hospitals/id/' + this.state.id)
        .then((result) => {
            const data = result.data.data
            this.setState({
              hospital: {
			  name: data.name,
			  address: data.address,
			  email: data.email,
			  fax: data.fax,
			  telephone: data.telephone,
			  amount: data.amount,
              hospital_id: data.hospital_id			  
			}
            })
        })
    }
	
	
	editHospitals = _ => {
    const { hospital } = this.state
    fetch(API_BASE_URL+`/hospitals/update?name=${hospital.name}&address=${hospital.address}&email=${hospital.email}&fax=${hospital.fax}&telephone=${hospital.telephone}&amount=${hospital.amount}&hospital_id=${hospital.hospital_id}`)
    .then((result) => {
                    console.log(result);
				
                   window.location.href = '/hospitals';
                })
    .catch( err => console.log(err))
    }
	
  render() {
	  const { hospitals, hospital } = this.state
	  return (
        <div className="wrapper"> 
     	<Header />
	    <MenuSidebar />		
       	<div className="content">	
		<div className="content-wrapper">
			<div className="content-body">
			<div className="form-wrapper">
			<div className="title">
				<h4>Edit Hospital</h4>
	         </div>	
				<div className="form-row">
			<div className="form-left">
				<div class="form-group">
					<label>Hospital Name</label>
					<input type="text" className="form-control" placeholder="" value={hospital.name}
            onChange={e => this.setState({ hospital: {...hospital, name:e.target.value}})} />
				</div>
				<div class="form-group">
					<label>Hospital Address</label>
					<input type="text" className="form-control"  placeholder="" value={hospital.address}
            onChange={e => this.setState({ hospital: {...hospital, address:e.target.value}})} />
				</div>
				<div class="form-group">
					<label>Email Address</label>
					<input type="text" className="form-control"  placeholder="" value={hospital.email}
            onChange={e => this.setState({ hospital: {...hospital, email:e.target.value}})} />
				</div>
				<div class="form-group">
					<label>Fax Number</label>
					<input type="text" className="form-control" placeholder="" value={hospital.fax}
            onChange={e => this.setState({ hospital: {...hospital, fax:e.target.value}})} />
				</div>
			</div>
			<div className="form-right">
				<div class="form-group">
					<label>Telephone Number</label>
					<input type="text" className="form-control"  placeholder="" value={hospital.telephone}
            onChange={e => this.setState({ hospital: {...hospital, telephone:e.target.value}})} />
				</div>
				<div class="form-group">
					<label>Amount</label>
					<input type="text" className="form-control" placeholder="" value={hospital.amount}
            onChange={e => this.setState({ hospital: {...hospital, amount:e.target.value}})} />
				</div>
			</div>
			</div>
			<div className="btn-group export">	
				<input type="submit" className="btn" onClick={this.editHospitals} value="Update" />
			</div>
			</div>
		</div>	
		</div>	
	</div>		 	
    </div>
    );
  }
}

export default EditHospital;