import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { API_BASE_URL } from '../config/api';
const axios = require("axios");

class Insurance extends Component {
	
	constructor(props)
	{
		super(props);	
		this.state = {
			insurances: [],
			deleteRecord: "",
			insurance: {
			  name: '',
			  address: '',
			  email: '',
			  fax: '',
			  telephone: '',
			 }
		};	
    }
	
	componentDidMount(){
     this.getInsurances()
    }
	
	getInsurances = _ => {
    fetch(API_BASE_URL+'/insurance')
    .then(response => response.json())
    .then(response => this.setState({ insurances: response.data}))
    .catch(err => console.log(err))
  }
  
  handleChangeDelete = e => {
    e.preventDefault();

    this.setState({
      deleteRecord: e.target.name
    });

    var Formdata = {
      deleteRecord: e.target.name
    };

    axios.post(API_BASE_URL+'/insurance/delete', { Formdata }).then(response => {
      console.log(response.data);
      console.log(response);
	 this.getInsurances();
	 //window.location.reload();
    });
  };
  
	renderInsurance = ({ insurance_id, name, address, email, fax, telephone}) => <><tr key={insurance_id}><td>{name} </td> <td>{address} </td> <td>{email} </td> <td> {fax} </td> <td> {telephone} </td><td>     
          <div className="action">	
		   <Link className="btn edit-btn" to={"/insurance/edit/" + insurance_id}> Edit</Link>                   
		   <button className="btn delete-btn" name={insurance_id} onClick={this.handleChangeDelete}>Delete</button>
		  </div>
		  </td></tr></>
	
	addInsurances = _ => {
    const { insurance } = this.state
    fetch(API_BASE_URL+`/insurance/add?name=${insurance.name}&address=${insurance.address}&email=${insurance.email}&fax=${insurance.fax}&telephone=${insurance.telephone}`)
    .then(this.getInsurances)
    .catch( err => console.log(err))
    }
	
  render() {
	  const { insurances, insurance } = this.state
	  console.log('map',insurances);
    return (	
		<div className="wrapper"> 
     	  <Header />
	      <MenuSidebar />		
       	<div className="content">	
		<div className="content-wrapper">
			<div className="content-body">
			<div className="form-wrapper">
			 <div className="title">
				<h4>Insurance Management</h4>
				<Link className="add-btn" to={"/insurance/add"}> Add</Link>
             </div>
			<style>{"table{border:1px solid black;}"}</style>
			{(insurances !='' && typeof insurances != 'undefined')?<table><tr><th>Company Name</th><th>Company Address</th><th>Email</th><th>Fax</th><th>Telephone</th><th>Actions</th></tr>{insurances.map(this.renderInsurance)}</table>:<div>No data found.</div>}		
	     	</div>
			</div>	
		</div>	
	</div>
		 	
    </div>
    );
  }
}

export default Insurance;