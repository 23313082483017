import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Progress from 'react-progressbar';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import JSZip from 'jszip';
import saveAs from 'file-saver';
import fs from 'fs-react';
import JSZipUtils from "jszip-utils" 
import ReactDOMServer from 'react-dom/server';


import { API_BASE_URL } from '../config/api';

//import 'magnific-popup';

import $ from 'jquery';
//var Progress = require('react-progressbar');
 

const axios = require("axios");


class Documents extends React.Component {
	
	fileObj = [];
	fileArray = [];
	
	constructor(props)
	{
		super(props);	
		this.state = {
			documents: [],
			uploaddocuments: [],
			progress: 0,
			document: {
				document_name: '',
			  amount: '',
			  description: '',
			},
			document2: {
				document_name: '',
			  amount: '',
			  description: '',
			},
			document3: {
				document_name: '',
			  amount: '',
			  description: '',
			},
			document4: {
				document_name: '',
			  amount: '',
			  description: '',
			},
			document5: {
				document_name: '',
			  amount: '',
			  description: '',
			},
			document6: {
				document_name: '',
			  amount: '',
			  description: '',
			},
			document7: {
				document_name: '',
			  amount: '',
			  description: '',
			},
			document8: {
				document_name: '',
			  amount: '',
			  description: '',
			},
			document9: {
				document_name: '',
			  amount: '',
			  description: '',
			},
			document10: {
				document_name: '',
			  amount: '',
			  description: '',
			},document11: {
				document_name: '',
			  amount: '',
			  description: '',
			},
			
			files: [],
			file: [],
			files2: [],
			file2: [],
			files3: [],
			file3: [],
			files4: [],
			file4: [],
			files5: [],
			file5: [],
			files6: [],
			file6: [],
			files7: [],
			file7: [],files8: [],
			file8: [],files9: [],
			file9: [],files10: [],
			file10: [],
			id: 1
		 
		};	
		localStorage.removeItem('file_case_file_number');
	localStorage.removeItem('uploaddocuments');
	$('#show').hide();
    }
	
	componentDidMount(){
		axios.get(API_BASE_URL+'/account/id/' + this.state.id)
        .then((result) => {
            const data = result.data.data
            this.setState({
				document: {
				account_id : data.account_id,
				plaintiff_amount: data.plaintiff_amount,
				plaintiff_type: data.plaintiff_type,
				warrant_amount: data.warrant_amount,
				gia_type: data.gia_type,
				warrant_type: data.warrant_type,
				gia_amount: data.gia_amount,
				police_report_amount: data.police_report_amount,
				police_report_type: data.police_report_type,
				medical_amount: data.medical_amount,
				medical_type: data.medical_type,
				genral_medical_amount: data.genral_medical_amount,
				genral_medical_type: data.genral_medical_type,
				specialist_medical_amount: data.specialist_medical_amount,
				specialist_medical_type: data.specialist_medical_type,
				loss_of_income_amount: data.loss_of_income_amount,
				loss_of_income_type: data.loss_of_income_type,
				accident_type: data.accident_type,
				accident_amount: data.accident_amount,
				photograph_amount: data.photograph_amount,
				photograph_type: data.photograph_type			  
			  }
			})
			localStorage.setItem('account', JSON.stringify( data));
			
		})
		this.getfiles()
		var account = JSON.parse(localStorage.getItem('account' ));
	}
	

	
	getfiles = _ => {
		fetch(API_BASE_URL+'/files')
		.then((response) => {
			return response.json();
		  })
		  
		  .then(response =>{
			localStorage.setItem('data_hos',JSON.stringify(response.data))   
			this.setState({
				files: [{value: '', case_file_number: 'Case File Reference'}].concat(response.data)
			})})
			.catch(err => console.log(err))
		 
	
	   }
 

	 handleChange = (e) => {
		this.setState({ file:  e.target.files })
		console.log('aaaaffffff',e.target.files);
		
	 }
	 handleChange2 = (e) => {
		const file2 = e.target.files[0];
        this.setState({file2: e.target.files}, function(){
			console.log('file22',file2);
		//	localStorage.setItem('upload_file', file2);
			});
      
	 }
	 handleChange3 = (e) => {
		const file3 = e.target.files[0];
        this.setState({file3: e.target.files}, function(){
			console.log('file33',file3);
		//	localStorage.setItem('upload_file', file2);
			});
      
	 }
	 handleChange4 = (e) => {
		const file4 = e.target.files[0];
        this.setState({file4: e.target.files}, function(){
			console.log('file44',file4);
		//	localStorage.setItem('upload_file', file2);
			});
      
	 }
	 handleChange5 = (e) => {
		const file5 = e.target.files[0];
        this.setState({file5: e.target.files}, function(){
			console.log('file55',file5);
		//	localStorage.setItem('upload_file', file2);
			});
      
	 }
	 handleChange6 = (e) => {
		const file6 = e.target.files[0];
        this.setState({file6: e.target.files}, function(){
			console.log('file66',file6);
		//	localStorage.setItem('upload_file', file2);
			});
      
	 }
	 handleChange7 = (e) => {
		const file7 = e.target.files[0];
        this.setState({file7: e.target.files}, function(){
			console.log('file77',file7);
		//	localStorage.setItem('upload_file', file2);
			});
      
	 }

	 handleChange8 = (e) => {
		const file8 = e.target.files[0];
        this.setState({file8: e.target.files}, function(){
			console.log('files88',file8);
		//	localStorage.setItem('upload_file', file2);
			});
      
	 }

	 handleChange9 = (e) => {
		const file9 = e.target.files[0];
        this.setState({file9: e.target.files}, function(){
			console.log('file99',file9);
		//	localStorage.setItem('upload_file', file2);
			});
      
	 }

	 handleChange10 = (e) => {
		const file10 = e.target.files[0];
        this.setState({file10: e.target.files}, function(){
			console.log('file10',file10);
		//	localStorage.setItem('upload_file', file2);
			});
      
	 }
	 
	 
	//  submitFile = () => {
	// 	}
	 uploadFile = () => {
		if((this.state.document !='')){
			/*upload file in folder function*/
			const formData = new FormData();
			[].forEach.call(this.state.file, function (file) {
				formData.append('file[]', file);
			});
			console.log('dffffffffffsssssssssssssbbbbbbbbbbb',this.state.file);
			axios.post(API_BASE_URL+'/files/upload_file', formData).then(response => {
			if(response.data.status==="success") {
		/*upload file in database*/
			console.log('sttaette',this.state.document);
			const doc_data = this.state.document;
			localStorage.setItem('doc_data',  JSON.stringify(doc_data));
			var account = JSON.parse(localStorage.getItem('account' ));
		var Formdata = {
			doc_data : JSON.parse(localStorage.getItem('doc_data')),
			file : response.data.img_name,
			doc_name : 'Plaintiff NRIC',
			amount : account.plaintiff_amount,
			case_file_number:localStorage.getItem('file_case_file_number')
		  };
		  console.log('state11',Formdata );
		axios.post(API_BASE_URL+'/files/upload_documents',{Formdata}  )
		 .then(response => {
			console.log(response.data);
			console.log(response);
		  });

		}
	  });
		 
		}
	
		localStorage.removeItem('image_name');
		localStorage.removeItem('doc_data');
		localStorage.setItem('status',  'doc1');
		localStorage.setItem('first_doc',  'yes');
		$(".success-modal").show();
		if(localStorage.getItem('status') == 'doc1'){
			this.uploadFile2();
			}
			
	};
	uploadFile2 = () => {
		
	if((this.state.document2 !='')){
		/*upload file in folder function*/
		const formData = new FormData();
		[].forEach.call(this.state.file2, function (file) {
			formData.append('file[]', file);
		});
		//formData.append('file', this.state.file2[0]);
		axios.post(API_BASE_URL+'/files/upload_file', formData).then(response => {
		if(response.data.status==="success") {
			//localStorage.setItem('image_name',  response.data.img_name);
		
	/*upload file in database*/
		console.log('sttaette22',this.state.document2);
		const doc_data = this.state.document2;
		localStorage.setItem('doc_data',  JSON.stringify(doc_data));
		var account = JSON.parse(localStorage.getItem('account' ));
		const file = this.state.file2[0];  
	var Formdata = {
		doc_data : JSON.parse(localStorage.getItem('doc_data')),
		file : response.data.img_name,
		doc_name : 'Warrant To Act',
		amount : account.warrant_amount,
		case_file_number:localStorage.getItem('file_case_file_number')
	  };
	  console.log('4422',Formdata );
	axios.post(API_BASE_URL+'/files/upload_documents',{Formdata}  )
	 .then(response => {
		console.log(response.data);
		console.log(response);
	  });
	}
   });
	}
 
	  localStorage.removeItem('image_name2');
	  localStorage.removeItem('doc_data');
	   localStorage.removeItem('status');
	   localStorage.removeItem('first_doc');
	   localStorage.setItem('status',  'doc2');
	   if(localStorage.getItem('status') == 'doc2'){
		this.uploadFile3();
	}
	
};

uploadFile3 = () => {
		
	if((this.state.document3 !='')){
		/*upload file in folder function*/
		const formData = new FormData();
		[].forEach.call(this.state.file3, function (file) {
			formData.append('file[]', file);
		});
		//formData.append('file', this.state.file3[0]);
		axios.post(API_BASE_URL+'/files/upload_file', formData).then(response => {
		if(response.data.status==="success") {
			//localStorage.setItem('image_name',  response.data.img_name);
		
	/*upload file in database*/
		console.log('sttaette22',this.state.document3);
		const doc_data = this.state.document3;
		localStorage.setItem('doc_data',  JSON.stringify(doc_data));
		var account = JSON.parse(localStorage.getItem('account' ));
		//const file = this.state.file[0];  
	var Formdata = {
		doc_data : JSON.parse(localStorage.getItem('doc_data')),
		file : response.data.img_name,
		doc_name : 'GIA Report',
		amount : account.gia_amount,
		case_file_number: localStorage.getItem('file_case_file_number')
	  };
	  console.log('442233',Formdata );
	axios.post(API_BASE_URL+'/files/upload_documents',{Formdata}  )
	 .then(response => {
		console.log(response.data);
		console.log(response);
	  });
	}
   });
	}
 
	  //localStorage.removeItem('image_name2');
	  localStorage.removeItem('doc_data');
	   localStorage.removeItem('status');
	   localStorage.setItem('status',  'doc4');
	   if(localStorage.getItem('status') == 'doc4'){
		this.uploadFile4();
	}
	
};

uploadFile4 = () => {
		
	if((this.state.document4 !='')){
		/*upload file in folder function*/
		const formData = new FormData();
		[].forEach.call(this.state.file4, function (file) {
			formData.append('file[]', file);
		});
		//formData.append('file', this.state.file4[0]);
		axios.post(API_BASE_URL+'/files/upload_file', formData).then(response => {
		if(response.data.status==="success") {
			//localStorage.setItem('image_name',  response.data.img_name);
		
	/*upload file in database*/
		console.log('sttaette22',this.state.document4);
		const doc_data = this.state.document4;
		localStorage.setItem('doc_data',  JSON.stringify(doc_data));
		var account = JSON.parse(localStorage.getItem('account' ));
		//const file = this.state.file[0];  
	var Formdata = {
		doc_data : JSON.parse(localStorage.getItem('doc_data')),
		file : response.data.img_name,
		doc_name : 'Police Report',
		amount : account.police_report_amount,
		case_file_number: localStorage.getItem('file_case_file_number')
	  };
	  console.log('442233',Formdata );
	axios.post(API_BASE_URL+'/files/upload_documents',{Formdata}  )
	 .then(response => {
		console.log(response.data);
		console.log(response);
	  });
	}
   });
	}
 
	  //localStorage.removeItem('image_name2');
	  localStorage.removeItem('doc_data');
	   localStorage.removeItem('status');
	   localStorage.setItem('status',  'doc5');
	   if(localStorage.getItem('status') == 'doc5'){
		this.uploadFile5();
	}
	
};



uploadFile5 = () => {
		
	if((this.state.document5 !='')){
		/*upload file in folder function*/
		const formData = new FormData();
		[].forEach.call(this.state.file5, function (file) {
			formData.append('file[]', file);
		});
		//formData.append('file', this.state.file5[0]);
		axios.post(API_BASE_URL+'/files/upload_file', formData).then(response => {
		if(response.data.status==="success") {
	/*upload file in database*/
		console.log('sttaette22',this.state.document5);
		const doc_data = this.state.document5;
		localStorage.setItem('doc_data',  JSON.stringify(doc_data));
		var account = JSON.parse(localStorage.getItem('account' ));
	var Formdata = {
		doc_data : JSON.parse(localStorage.getItem('doc_data')),
		file : response.data.img_name,
		doc_name : 'Medical Receipt',
		amount : account.medical_amount,
		case_file_number: localStorage.getItem('file_case_file_number')
	  };
	  console.log('442233',Formdata );
	axios.post(API_BASE_URL+'/files/upload_documents',{Formdata}  )
	 .then(response => {
		console.log(response.data);
		console.log(response);
	  });
	}
   });
	}
	  localStorage.removeItem('doc_data');
	   localStorage.removeItem('status');
	   localStorage.setItem('status',  'doc6');
	   if(localStorage.getItem('status') == 'doc6'){
		this.uploadFile6();
	}
	
};

uploadFile6 = () => {
		
	if((this.state.document6 !='')){
		/*upload file in folder function*/
		const formData = new FormData();
		[].forEach.call(this.state.file6, function (file) {
			formData.append('file[]', file);
		});
		//formData.append('file', this.state.file6[0]);
		axios.post(API_BASE_URL+'/files/upload_file', formData).then(response => {
		if(response.data.status==="success") {
	/*upload file in database*/
		console.log('sttaette66',this.state.document6);
		const doc_data = this.state.document6;
		localStorage.setItem('doc_data',  JSON.stringify(doc_data));
		var account = JSON.parse(localStorage.getItem('account' ));
	var Formdata = {
		doc_data : JSON.parse(localStorage.getItem('doc_data')),
		file : response.data.img_name,
		doc_name : 'General Medical Receipt',
		amount : account.genral_medical_amount,
		case_file_number: localStorage.getItem('file_case_file_number')
	  };
	  console.log('4422356',Formdata );
	axios.post(API_BASE_URL+'/files/upload_documents',{Formdata}  )
	 .then(response => {
		console.log(response.data);
		console.log(response);
	  });
	}
   });
	}
	  localStorage.removeItem('doc_data');
	   localStorage.removeItem('status');
	   localStorage.setItem('status',  'doc7');
	   if(localStorage.getItem('status') == 'doc7'){
		this.uploadFile7();
	}
	
};


//7

uploadFile7 = () => {
		
	if((this.state.document7 !='')){
		/*upload file in folder function*/
		const formData = new FormData();
		[].forEach.call(this.state.file7, function (file) {
			formData.append('file[]', file);
		});
		//formData.append('file', this.state.file7[0]);
		axios.post(API_BASE_URL+'/files/upload_file', formData).then(response => {
		if(response.data.status==="success") {
	/*upload file in database*/
		console.log('sttaette77',this.state.document7);
		const doc_data = this.state.document7;
		localStorage.setItem('doc_data',  JSON.stringify(doc_data));
		var account = JSON.parse(localStorage.getItem('account' ));
	var Formdata = {
		doc_data : JSON.parse(localStorage.getItem('doc_data')),
		file : response.data.img_name,
		doc_name : 'Specialist Medical Receipt',
		amount : account.specialist_medical_amount,
		case_file_number: localStorage.getItem('file_case_file_number')
	  };
	  console.log('44223567',Formdata );
	axios.post(API_BASE_URL+'/files/upload_documents',{Formdata}  )
	 .then(response => {
		console.log(response.data);
		console.log(response);
	  });
	}
   });
	}
	  localStorage.removeItem('doc_data');
	   localStorage.removeItem('status');
	   localStorage.setItem('status',  'doc8');
	   if(localStorage.getItem('status') == 'doc8'){
		this.uploadFile8();
	}
	
};
//8

uploadFile8 = () => {
		
	if((this.state.document8 !='')){
		/*upload file in folder function*/
		const formData = new FormData();
		[].forEach.call(this.state.file8, function (file) {
			formData.append('file[]', file);
		});
		//formData.append('file', this.state.file8[0]);
		axios.post(API_BASE_URL+'/files/upload_file', formData).then(response => {
		if(response.data.status==="success") {
	/*upload file in database*/
		console.log('sttaette77',this.state.document8);
		const doc_data = this.state.document8;
		localStorage.setItem('doc_data',  JSON.stringify(doc_data));
		var account = JSON.parse(localStorage.getItem('account' ));
	var Formdata = {
		doc_data : JSON.parse(localStorage.getItem('doc_data')),
		file : response.data.img_name,
		doc_name : 'Loss Of Income',
		amount : account.loss_of_income_amount,
		case_file_number: localStorage.getItem('file_case_file_number')
	  };
	  console.log('442235678',Formdata );
	axios.post(API_BASE_URL+'/files/upload_documents',{Formdata}  )
	 .then(response => {
		console.log(response.data);
		console.log(response);
	  });
	}
   });
	}
	  localStorage.removeItem('doc_data');
	   localStorage.removeItem('status');
	   localStorage.setItem('status',  'doc9');
	   if(localStorage.getItem('status') == 'doc9'){
		this.uploadFile9();
	}
	
};

//9
uploadFile9 = () => {
		
	if((this.state.document9 !='')){
		/*upload file in folder function*/
		const formData = new FormData();
		[].forEach.call(this.state.file9, function (file) {
			formData.append('file[]', file);
		});
		//formData.append('file', this.state.file9[0]);
		axios.post(API_BASE_URL+'/files/upload_file', formData).then(response => {
		if(response.data.status==="success") {
	/*upload file in database*/
		console.log('sttaette77',this.state.document9);
		const doc_data = this.state.document9;
		localStorage.setItem('doc_data',  JSON.stringify(doc_data));
		var account = JSON.parse(localStorage.getItem('account' ));
	var Formdata = {
		doc_data : JSON.parse(localStorage.getItem('doc_data')),
		file : response.data.img_name,
		doc_name : 'Accident Video',
		amount : account.accident_amount,
		case_file_number: localStorage.getItem('file_case_file_number')
	  };
	  console.log('4422356789',Formdata );
	axios.post(API_BASE_URL+'/files/upload_documents',{Formdata}  )
	 .then(response => {
		console.log(response.data);
		console.log(response);
	  });
	}
   });
	}
	  localStorage.removeItem('doc_data');
	   localStorage.removeItem('status');
	   localStorage.setItem('status',  'doc10');
	   if(localStorage.getItem('status') == 'doc10'){
		this.uploadFile10();
	}
	
};

//10

uploadFile10 = () => {
		
	if((this.state.document10 !='')){
		/*upload file in folder function*/
		const formData = new FormData();
		[].forEach.call(this.state.file10, function (file) {
			formData.append('file[]', file);
		});
		//formData.append('file', this.state.file10[0]);
		axios.post(API_BASE_URL+'/files/upload_file', formData).then(response => {
		if(response.data.status==="success") {
	/*upload file in database*/
		console.log('sttaette77',this.state.document10);
		const doc_data = this.state.document10;
		localStorage.setItem('doc_data',  JSON.stringify(doc_data));
		var account = JSON.parse(localStorage.getItem('account' ));
	var Formdata = {
		doc_data : JSON.parse(localStorage.getItem('doc_data')),
		file : response.data.img_name,
		doc_name : 'Photographs',
		amount : account.photograph_amount,
		case_file_number: localStorage.getItem('file_case_file_number')
	  };
	  console.log('442235678910',Formdata );
	axios.post(API_BASE_URL+'/files/upload_documents',{Formdata}  )
	 .then(response => {
		if(response.data.status != ''){
			localStorage.removeItem('doc_data');
			localStorage.removeItem('status');
			localStorage.setItem('status',  'doc10');
			localStorage.removeItem('status');
			
			var value_incc = parseInt(localStorage.getItem("inc_val_doc_upload"));
			if((value_incc!='null')&&(value_incc!='')){
			for (var i = 1; i <= value_incc; i++) {
				var document_name = "document_name"+[i];
				var amount = "amount"+[i];
				var description = "description"+[i];
				var file_upload = "file_upload"+[i];
		
				var document_name_append = localStorage.getItem(document_name);
			   var amount_append = localStorage.getItem(amount);
			   var description_append = localStorage.getItem(description);
			   var file_upload_append = localStorage.getItem(file_upload);
			   console.log('file_uploadsas',file_upload);
		
			   if((document_name_append!='') && (amount_append!='')&& (description_append!='')&& (file_upload_append!='')){
				 
					var Formdata = {
						doc_data : { description: localStorage.getItem((description)) },
						file : localStorage.getItem((file_upload)),
						doc_name : localStorage.getItem(document_name),
						amount : localStorage.getItem(amount),
						case_file_number: localStorage.getItem('file_case_file_number')
					  };
					  console.log('upploaadddd',Formdata );
					axios.post(API_BASE_URL+'/files/upload_documents',{Formdata}  )
					 .then(response => {
						console.log(response.data);
						console.log(response);
					  });
		
			  }
			} }
			//localStorage.clear();
			localStorage.removeItem('inc_val_doc_upload');
			setTimeout(function(){ 
				$(".success-modal").hide();	
			}, 50000);
		
			this.loadingpage();
		}
		console.log(response.data);
		console.log(response);
	  });
	 
	  
	}
   });
	}
};


  
loadingpage = () => { 
	window.location.href = '/files/documents';
};

	
handleChangeDeletedocument = e => {
    e.preventDefault();

    this.setState({
      deleteRecord: e.target.name
	});
	console.log('datadocuments',e.target.name);
    var Formdata = {
	  /*deleteRecord: this.state.deleteRecord*/
	  deleteRecord: e.target.name
    };
	
	axios.post(API_BASE_URL+'/files/delete_folder_doc', { Formdata }).then(response => {
      console.log(response.data);
      console.log(response);
	});
	axios.post(API_BASE_URL+'/files/delete_images', { Formdata }).then(response => {
      console.log(response.data);
      console.log(response);
    });
    axios.post(API_BASE_URL+'/files/delete_documents', { Formdata }).then(response => {
      console.log(response.data);
      console.log(response);
	 this.getfiles();
	 alert('Documents and douments files are deleted successfully... ');
	 window.location.reload();
    });
  };
 

uploadFiledoccmt = () => {
		
		var value_incc = parseInt(localStorage.getItem("inc_val_doc_upload"));
		if((value_incc!='null')&&(value_incc!='')){
		for (var i = 1; i <= value_incc; i++) {
			var document_name = "document_name"+[i];
			var amount = "amount"+[i];
			var description = "description"+[i];
			var file_upload = "file_upload"+[i];
	
			var document_name_append = localStorage.getItem(document_name);
		   var amount_append = localStorage.getItem(amount);
		   var description_append = localStorage.getItem(description);
		   var file_upload_append = localStorage.getItem(file_upload);
		   console.log('file_uploadsas',file_upload);
	
		   if((document_name_append!='') && (amount_append!='')&& (description_append!='')&& (file_upload_append!='')){
			 
				var Formdata = {
					doc_data : { description: localStorage.getItem((description)) },
					file : localStorage.getItem((file_upload)),
					doc_name : localStorage.getItem(document_name),
					amount : localStorage.getItem(amount),
					case_file_number: localStorage.getItem('file_case_file_number')
				  };
				  console.log('upploaadddd',Formdata );
				axios.post(API_BASE_URL+'/files/upload_documents',{Formdata}  )
				 .then(response => {
					console.log(response.data);
					console.log(response);
				  });
	
		  }
		} }
		//localStorage.clear();
		localStorage.removeItem('inc_val_doc_upload');
		alert("Files Uploaded successfully.");
	
		this.loadingpage4();
	
};


  
loadingpage4 = () => { 
	window.location.href = '/files/documents';
};


addChild (inc,e) {
	if (localStorage.getItem("inc_val_doc_upload") > 0){
		var inc_val =  parseInt(localStorage.getItem("inc_val_doc_upload"))+1;
		}else{
		  var inc_val =  parseInt(inc)+1; 
		}
  
		localStorage.setItem("clicked_add_doc_upload", 'yes')
		console.log('inc_val_doc_upload',inc_val);
		localStorage.setItem("inc_val_doc_upload", inc_val)
	e.preventDefault();
	 $("table").append(`
	 <tr>
		<td>
			<input type="text" id='document_name`+[inc_val]+`' placeholder="custom labels" value="" />
		</td>
		<td>
			<input type="text" id='amount`+[inc_val]+`'   placeholder="" value="" />
		</td>
		<td>
			<input type="text" id='description`+[inc_val]+`' placeholder="" value="" />
		</td>
		<td>
		<div className="file-upload">
			<input type="file"  id='file_upload`+[inc_val]+`' multiple />
		</div>
		</td>
	 </tr>
	 `);

	 $('#document_name'+[inc_val]).on('change',function (e) {
		var document_name = $( "#document_name"+[inc_val] ).val() || [];
		console.log('document_name'+[inc_val],document_name);
		localStorage.setItem("document_name"+[inc_val], document_name)
	   
	   });
	   $('#amount'+[inc_val]).on('change',function (e) {
		 var amount = $( "#amount"+[inc_val] ).val() || [];
		 console.log('amount'+[inc_val],amount);
		 localStorage.setItem("amount"+[inc_val], amount)
	   
		});

		$('#description'+[inc_val]).on('change',function (e) {
			var description = $( "#description"+[inc_val] ).val() || [];
			console.log('description'+[inc_val],description);
			localStorage.setItem("description"+[inc_val], description)
		  
		   });
		   $('#file_upload'+[inc_val]).on('change',function (e) {
		console.log('file_upload',e.target.files);
		const formData = new FormData();
			[].forEach.call(e.target.files, function (file) {
				formData.append('file[]', file);
			});
			console.log('dffffffffffsssssssssssssbbbbbbbbbbb',e.target.files);
			axios.post(API_BASE_URL+'/files/upload_file', formData).then(response => {
			if(response.data.status==="success") {
		   localStorage.setItem("file_upload"+[inc_val],response.data.img_name)
				
			}
		})
	  });
  }	

     
  handleClickSelecet = (event) => {
	
    const selectedIndex = event.target.options.selectedIndex;
    var key = event.target.options[selectedIndex].getAttribute('data-key');
    console.log('docccyyy',key);
axios.get(API_BASE_URL+'/files/id/' + key)
 .then((result) => {  
   const data = result.data.data
   if(data == undefined) {
	localStorage.removeItem('file_case_file_number')
	localStorage.removeItem('uploaddocuments')
   }else{
	localStorage.setItem('file_case_file_number',data.case_file_number)
   }
   console.log('uodatettetet',data);
   
   if(data !== undefined && data !== undefined) {
      this.setState({

          file: {
              case_file_number: data.case_file_number,
              plaintiff_id:data.plaintiff_id,
             
                      
          }
   })

   if(localStorage.getItem('file_case_file_number') !=''){
	this.handleSelecetcase();
	}
   
  }else{

      this.setState({
          file: {
              case_file_number: '',
              plaintiff_id:'',
            
          }
      })

	}
	
})

};



actionload = (id) => {
	var doc = [];
	axios.get(API_BASE_URL+'/files/image_id/' + id)
	.then((result) => {
		var data = result.data.data
		var img = []
		data.forEach(function(data) {

			img.push(data);
		 })
		 console.log('image_url',img);
		 localStorage.setItem('image' +[id], JSON.stringify(img));
		 localStorage.setItem('urlimage', JSON.stringify(result.data.image_url));
	
})
	
	var id_img = "image"+[id];
	var urlimage = JSON.parse(localStorage.getItem('urlimage'));
	var tags = [];
	console.log('doc[i]urlimage',urlimage);
	var img_files = JSON.parse(localStorage.getItem(id_img));
		var doc = img_files;
		console.log('doc[i]urlimage',img_files);
		//doc.forEach(function(doc) {
	if(((JSON.parse(localStorage.getItem(id_img)) !== null))){
	for (var i = 0; i <= (doc.length -1); i++) {
		//if( doc[i]!=undefined){
		var doc_files1 = doc[i]['upload_files'];
		// const  doc_files2 =   doc_files1.replace('[]', '')
		// const  doc_files3 =   doc_files2.replace('-', '[]-')
		var upload_files = doc[i]['upload_files'];
		tags.push( <p className="doc-file"><a href={urlimage+'\\'+upload_files} name={doc[i]['image_id']} target="_blank" >{upload_files}</a><span className="remove" data-key={doc[i]['document_id']} id={doc[i]['image_id']} name={doc[i]['image_id']} onClick={this.handleChangeDelete}>X</span></p>
		);
	 }}
	 return (tags);
}




handleChangeDelete = (e) => {
	e.preventDefault();
    console.log(e.target.id);
	this.setState({
	  deleteRecord: e.target.id
	});
	console.log('datafilesss', e.target.id);
	var Formdata = {
	  deleteRecord:  e.target.id
	};
	
	
	  console.log('Formdata',Formdata);
	  axios.post(API_BASE_URL+'/files/delete_folder_doc_imgeess', { Formdata }).then(response => {
		console.log(response.data);
		console.log(response);
	  });

	  axios.post(API_BASE_URL+'/files/delete_images_separate', { Formdata }).then(response => {
	  console.log(response.data);
	  console.log(response);
	  alert("Document File deleted successfully.");
	  window.location.reload();
	  
		
	});
  };


	handleSelecetcase = _ => {
    var key = localStorage.getItem('file_case_file_number');
    console.log('file_case_file_number',key);
   axios.get(API_BASE_URL+`/files/caseid?case_file_id='`+key+`'`)
 .then((result) => {  
   const data = result.data.data
   if(result.data.data === ''){
	localStorage.removeItem('uploaddocuments')
	
   }
   console.log('caseid',data);
   if(data !== undefined && data !== undefined) {
	if(data !== ''){
	$('#show').show();
	$('#show2').show();
	$('#show3').show();
	}
	console.log('show3',data);
	localStorage.setItem('uploaddocuments',JSON.stringify(data))
      this.setState({
		uploaddocuments: JSON.stringify(data)
   })
  }
 })
};
	

// create_zip= (e) => {
// 	e.preventDefault();
//     var key = e.target.id;
// 	var doc = [];
// 	axios.get(API_BASE_URL+'/files/image_id/' + e.target.name)
// 	.then((result) => {
// 		var data = result.data.data
// 		var img = []
// 		console.log('checkinngngg',data);
// 		data.forEach(function(data) {
// 			img.push(data.upload_files);
// 			var doc_files1 = data.upload_files
// 		var upload_files = doc_files1;
// 		var data_image = result.data.image_url+'\\'+upload_files
// 		console.log('data_image',img);
// 		axios.get(API_BASE_URL+'/files/zipfile/' + upload_files)
// 		.then((result) => {
// 				console.log('data_image',data_image);
// 				console.log('upload_files',result.data.image);
// 				var zip = new JSZip();
// 			zip.file(`${result.data.image}`,result.data.image);
// 			zip.generateAsync({type:"blob"})
// 				.then(function(content) {
// 					zip.loadAsync(content)
// 					saveAs(content, key+".zip");
// 				});
//           	})
// 		 })
// 	 })
//   }





create_zip= (e) => {
	e.preventDefault();
	var key = e.target.id;
  var zip = new JSZip();
var count = 0;
var zipFilename = key+".zip";
axios.get(API_BASE_URL+'/files/image_id/' + e.target.name)
.then((result) => {
	var urls = result.data.data
	console.log('urls',result.data.data);
	var i=0;
urls.forEach(function(url,i){
var doc_files1 = url.upload_files
		var upload_files = doc_files1;
		var data_image = result.data.image_url+'\\'+upload_files
		var filename = urls[i].upload_files;
		console.log('doc_files1',doc_files1);
		console.log('data_image',data_image);
		console.log('url',url);
		console.log('filename',filename);
		console.log('i',i);

zip.file(filename, data_image, {binary:true});
count++;
if (count == urls.length) {
zip.generateAsync({type:'blob'}).then(function(content) {
saveAs(content, zipFilename);
});
}
});
})
}

// create_zip= (e) => {
// 	const s = document.createElement('script');
// 	s.src = 'https://cdnjs.cloudflare.com/ajax/libs/jszip-utils/0.1.0/jszip-utils.min.js';
// 	s.src = 'https://cdnjs.cloudflare.com/ajax/libs/jszip/3.5.0/jszip.min.js';
// 	s.src = 'http://cdn.jsdelivr.net/g/filesaver.js';
// 	s.type = 'text/javascript';
	
// 	console.log('TEST');
//   var zip = new JSZip();
//   var count = 0;
//   e.preventDefault();
//   	var key = e.target.id;
//   var zipFilename = key+".zip";
  
//   axios.get(API_BASE_URL+'/files/image_id/' + e.target.name)
//   .then((result) => {
//   	var links = result.data.data
//   	console.log('urls',result.data.data);
//   	var i=0;
//   links.forEach(function (url, i) {
//     var filename = links[i].upload_files;
//     filename = filename.replace(/[\/\*\|\:\<\>\?\"\\]/gi, '');

//     //.replace("httpsi.imgur.com","");
// 	// loading a file and add it in a zip file
	
	  
//     JSZipUtils.getBinaryContent(links[i].upload_files, function (err, data) {
//       if (err) {
//         throw err; // or handle the error
//       }
//       zip.file(filename, data, { binary: true });
//       count++;
//       if (count == links.length) {
//         zip.generateAsync({
// 			type: "blob",
// 			compression: "DEFLATE",
// 			compressionOptions: {
// 				level: 9
// 			}
// 		}
// 	).then(function (content) {
		
// 		FileSaver.saveAs(result.data.image_url+'\\'+links[i].upload_files, zipFilename);
//         });
// 		}
// 		});
// 		})
// 	})
// }




  render() {
	const { files,tags } = this.state
	const { documents,progress, document11,document,file,document2 ,document3 ,document4 ,document5 ,document6 ,document7 ,document8 ,document9 ,document10 } = this.state
	var inc = 0;
	var uploaddocuments = JSON.parse(localStorage.getItem('uploaddocuments')) ?JSON.parse(localStorage.getItem('uploaddocuments')) :[] ;
        if(uploaddocuments==''){
				$('#show').hide();
				$('#show2').hide();
				$('#show3').hide();
		}   
    var key = localStorage.getItem('file_case_file_number');
	return (	
		<div className="wrapper"> 
     	  <Header />
	      <MenuSidebar />		
       	<div className="content">	
		<div className="content-wrapper">
			<div className="content-body">
			<div className="form-wrapper">
			<div className="uplod-documents-wrapper">	
			 <div className="title">
				<h4>UPLOAD DOCUMENTS</h4>
             </div>

			<div className="modal success-modal" style={{display:"none"}}>
				<div className="modal_content">
				<span className="close" onClick={this.handleClickclose}>&times;</span>
				<p>Uploading files</p>
				<Progress completed={75} />
				<div className="btn-group next-back-group">	
				<button onClick={this.uploadFile} className="upbutton"> Files Upload Complete</button>
				</div>
				</div>
			</div> 

			 <div className="form-group">
			 <label >Case File Number</label>
                            <select  onChange={this.handleClickSelecet}  value={file.case_file_number}  >                    
        {this.state.files.map((files) => <option key={files.plaintiff_id} data-key={files.plaintiff_id} value={files.case_file_number} >{files.case_file_number}</option>)}
	    </select>
			</div>
			{ uploaddocuments == '' && 
				
				<>
			 <table ><tr><th width="300"> Document Name</th><th  width="250"> Amount</th><th  width="400"> Description</th><th  width="200"  > Upload Files</th></tr>
			
				 <tr>
					<td ><label name='document.document_name' >Plaintiff NRIC</label>
					<input type="hidden"   placeholder="" value='Plaintiff NRIC'
                     onChange={e => this.setState({ document: {...document, document_name:e.target.value}})} />
					</td>
					<td>
				     <input type="text" readOnly name='document.amount'   placeholder="Not Applicable" 
            onChange={e => this.setState({ document: {...document, amount:e.target.value}})} />
				   </td>
				   <td>
			    	<input type="text"  name='document.description' placeholder="" value={document.description}
            onChange={e => this.setState({ document: {...document, description:e.target.value}})} />
				 </td>
			    	
					<td>
					<div className="file-upload">
                     <input name='document.up_file' type="file" ref='doc1' multiple  onChange={this.handleChange} />
				</div>
					</td>
				</tr>
				<tr>
					<td ><label>Warrant To Act</label>
					<input type="hidden" name='document2.document_name'  placeholder="" value='Warrant To Act'
                     onChange={e2 => this.setState({ document2: {...document2, document_name:e2.target.value}})} />
					</td>
					<td>
				     <input type="text" name='document2.amount' readOnly placeholder="" value={document.warrant_amount}
            onChange={e2 => this.setState({ document2: {...document2, amount:e2.target.value}})} />
				   </td>
				   <td>
			    	<input type="text" name='document2.description' placeholder="" value={document2.description}
            onChange={e2 => this.setState({ document2: {...document2, description:e2.target.value}})} />
				 </td>
			    	
					<td>
					<div className="file-upload">
                     <input type="file" name='document2.up_file' ref='doc2' multiple  onChange={this.handleChange2} />
				</div>
					</td>
				</tr>
				<tr>
					<td ><label>GIA Report</label>
					<input type="hidden"   placeholder="" value='GIA Report'
                     onChange={e2 => this.setState({ document3: {...document3, document_name:e2.target.value}})} />
					</td>
					<td>
				     <input type="text"   placeholder="" readOnly value={document.gia_amount}
            onChange={e2 => this.setState({ document3: {...document3, amount:e2.target.value}})} />
				   </td>
				   <td>
			    	<input type="text" placeholder="" value={document3.description}
            onChange={e2 => this.setState({ document3: {...document3, description:e2.target.value}})} />
				 </td>
			    	
					<td>
					<div className="file-upload">
                     <input type="file" multiple   onChange={this.handleChange3} />
				</div>
					</td>
				</tr>
				<tr>
					<td ><label>Police Report</label>
					<input type="hidden"   placeholder="" value='Police Report'
                     onChange={e2 => this.setState({ document4: {...document, document_name:e2.target.value}})} />
					</td>
					<td>
				     <input type="text"   placeholder="" readOnly value={document.police_report_amount}
            onChange={e2 => this.setState({ document4: {...document4, amount:e2.target.value}})} />
				   </td>
				   <td>
			    	<input type="text" placeholder="" value={document4.description}
            onChange={e2 => this.setState({ document4: {...document4, description:e2.target.value}})} />
				 </td>
			    	
					<td>
					<div className="file-upload">
                     <input type="file" multiple    onChange={this.handleChange4} />
				</div>
					</td>
				</tr>

				<tr>
					<td ><label>Medical Receipt</label>
					<input type="hidden"   placeholder="" value='Medical Receipt'
                     onChange={e2 => this.setState({ document3: {...document5, document_name:e2.target.value}})} />
					</td>
					<td>
				     <input type="text"   placeholder="" readOnly value={document.medical_amount}
            onChange={e2 => this.setState({ document5: {...document5, amount:e2.target.value}})} />
				   </td>
				   <td>
			    	<input type="text" placeholder="" value={document5.description}
            onChange={e2 => this.setState({ document5: {...document5, description:e2.target.value}})} />
				 </td>
			    	
					<td>
					<div className="file-upload">
                     <input type="file"  multiple   onChange={this.handleChange5} />
				</div>
					</td>
				</tr>

				
				<tr>
					<td ><label>General Medical Receipt</label>
					<input type="hidden"   placeholder="" value='General Medical Receipt'
                     onChange={e2 => this.setState({ document6: {...document6, document_name:e2.target.value}})} />
					</td>
					<td>
				     <input type="text"   placeholder="" readOnly value={document.genral_medical_amount}
            onChange={e2 => this.setState({ document6: {...document6, amount:e2.target.value}})} />
				   </td>
				   <td>
			    	<input type="text" placeholder="" value={document6.description}
            onChange={e2 => this.setState({ document6: {...document6, description:e2.target.value}})} />
				 </td>
			    	
					<td>
					<div className="file-upload">
                     <input type="file"  multiple   onChange={this.handleChange6} />
				</div>
					</td>
				</tr>
				

				<tr>
					<td ><label>Specialist Medical Receipt</label>
					<input type="hidden"   placeholder="" value='Specialist Medical Receipt'
                     onChange={e2 => this.setState({ document7: {...document7, document_name:e2.target.value}})} />
					</td>
					<td>
				     <input type="text"   placeholder="" readOnly value={document.specialist_medical_amount}
            onChange={e2 => this.setState({ document7: {...document7, amount:e2.target.value}})} />
				   </td>
				   <td>
			    	<input type="text" placeholder="" value={document7.description}
            onChange={e2 => this.setState({ document7: {...document7, description:e2.target.value}})} />
				 </td>
			    	
					<td>
					<div className="file-upload">
                     <input type="file"  multiple  onChange={this.handleChange7} />
				</div>
					</td>
				</tr>

				
				<tr>
					<td ><label>Loss Of Income</label>
					<input type="hidden"   placeholder="" value='Loss Of Income'
                     onChange={e2 => this.setState({ document8: {...document8, document_name:e2.target.value}})} />
					</td>
					<td>
				     <input type="text"   placeholder=""  readOnly value={document.loss_of_income_amount}
            onChange={e2 => this.setState({ document8: {...document8, amount:e2.target.value}})} />
				   </td>
				   <td>
			    	<input type="text" placeholder="" value={document8.description}
            onChange={e2 => this.setState({ document8: {...document8, description:e2.target.value}})} />
				 </td>
			    	
					<td>
					<div className="file-upload">
                     <input type="file" multiple   onChange={this.handleChange8} />
				</div>
					</td>
				</tr>

                <tr>
					<td ><label>Accident Video</label>
					<input type="hidden"   placeholder="" value='Accident Video'
                     onChange={e2 => this.setState({ document9: {...document9, document_name:e2.target.value}})} />
					</td>
					<td>
				     <input type="text"  readOnly  placeholder="Not Applicable" 
            onChange={e2 => this.setState({ document9: {...document9, amount:e2.target.value}})} />
				   </td>
				   <td>
			    	<input type="text" placeholder="" value={document9.description}
            onChange={e2 => this.setState({ document9: {...document9, description:e2.target.value}})} />
				 </td>
			    	
					<td>
					<div className="file-upload">
                     <input type="file" multiple   onChange={this.handleChange9} />
				</div>
					</td>
				</tr>
               
				<tr>
					<td ><label>Photographs</label>
					<input type="hidden"   placeholder="" value='Photographs'
                     onChange={e2 => this.setState({ document10: {...document10, document_name:e2.target.value}})} />
					</td>
					<td>
				     <input type="text" readOnly  placeholder="Not Applicable" 
            onChange={e2 => this.setState({ document10: {...document10, amount:e2.target.value}})} />
				   </td>
				   <td>
			    	<input type="text" placeholder="" value={document10.description}
            onChange={e2 => this.setState({ document10: {...document10, description:e2.target.value}})} />
				 </td>
			    	
					<td>
					<div className="file-upload">
                     <input type="file" multiple   onChange={this.handleChange10} />
				</div>
					</td>
				</tr>
				</table>
				<div className="addmore-row">		
			<Link className="addmore-btn" value='add more' onClick={this.addChild.bind(this,inc)}> Add another </Link>
		</div>
		<div className="btn-group next-back-group">	
			<button onClick={this.uploadFile} className="upbutton"> Upload</button>
		</div>
				</>}
				</div>
				<div className="case-file-list">
				{ (uploaddocuments !== '') && 
				<>
	
				
			<table id='show3' style={{display : 'none'}} >
				<tr><th width="300"> Document Name</th><th  width="200"> Amount</th><th  width="200"> Description</th><th  width="300"  > Uploaded Files List</th><th  width="100"  > Actions</th></tr>
			{uploaddocuments.map((files) =>
			<tr key={files.document_id}><td>{files.document_name} </td> <td>{files.amount} </td> <td>{files.description} </td> <td >
			{this.actionload(files.document_id)}	
			 	 {tags}
		 </td> <td> 
			<div className="action">	
		   <Link className="btn edit-btn" to={"/files/edit_documents/" + files.document_id}>Edit</Link> 
			<button className="btn delete-btn" name={files.document_id} onClick={this.handleChangeDeletedocument}>Delete</button>
			<button  className="btn download-btn" name={files.document_id} id={files.document_name} onClick={this.create_zip}>Download</button> 
			</div>
			</td></tr>
			)}</table>
         
         <div id='show' style={{display : 'none'}}  className="addmore-row">
			<Link className="addmore-btn" value='add more' onClick={this.addChild.bind(this,inc)}> Add another </Link>
		</div>
		<div id='show2' style={{display : 'none'}}  className="btn-group next-back-group ">	
			<button onClick={this.uploadFiledoccmt} className="upbutton"> Upload</button>
			</div>  
				</>}
				</div>
				{/*	<div  className="btn-group next-back-group ">	
						<button className="Print"> Print Docs</button>
			</div>  */}
			</div>

			</div>

		</div>	
	</div>
		 	
    </div>
    );
  }
}

export default Documents;