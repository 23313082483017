import React, { Component } from 'react';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { Link } from "react-router-dom";
import { API_BASE_URL } from '../config/api';
import $ from 'jquery';

const axios = require("axios");

class Letters extends Component {
	
	constructor(props)
	{
		super(props);	
		this.state = {
            files: [],
			errors: {},
			errors2: {},
			searchfiles: [],
			deleteRecord: "",
			query : "",
			file: {
			  plaintiff_name:'',
				minor:'',
				plaintiff_ic_number:'',
				plaintiff_mobile_number:'',
				plaintiff_occupation:'',
				plaintiff_dob:'',
				plaintiff_address:'',
				plaintiff_telephone_number:'',
				referral_by:'',
				accident_date:'',
				accident_time:'',
				accident_type:'',
				accident_location:'',
				plaintiff_position:'',
				time_bar:'',
				p_type:'',
				case_file_number:'',
				plaintiff_vehicle_number:'',
				vehicle_in_front:'',
				vehicle_in_back:''
			  			  
            },
            user: {
                name:'',
        address:'',
        password:'',
        email:'',
        department:'',
        status:'',
        confirm_password:''
    },
    case_file_number:'',
    name:'',
	address:'',
	mailmsg: {},
	sendername:'',
	senderemail:'',
	currentDateTime: Date()
		};
		
	}
	
	sendemail = _ => {
		const { user,senderemail,sendername } = this.state;
		const formData = new FormData();
		let mailmsg = {};
		var case_file_number = localStorage.getItem('case_file_numberletter');
		var name = localStorage.getItem('username');
		var address = localStorage.getItem('useraddress');
		var email = localStorage.getItem('useremail');
		var accdate = localStorage.getItem('accdate');
		if(localStorage.getItem('login_type') == 'user'){
		var fromemail = localStorage.getItem('user_login_id');
		this.user(fromemail);
		}else{
		var fromemail = '1';
		this.admin(fromemail);
		}
		var d = new Date().toString();
		var index = d.lastIndexOf(':') +3
		var currentDateTime = d.substring(0, index);
		console.log(d.substring(0, index))
		mailmsg["msg"] = 'Mail Sending.....'
		this.setState({
			mailmsg: mailmsg
		  }); 
		var Formdata = {
		case_file_number : localStorage.getItem('case_file_numberletter'),
		 name : localStorage.getItem('username'),
		address : localStorage.getItem('useraddress'),
		 email : localStorage.getItem('useremail'),
		 fromemail : senderemail,
		 accdate : localStorage.getItem('accdate')
		};
		console.log('Formdata',Formdata);

		$(".email-modal").show();

		axios.post(API_BASE_URL+'/users/send/lettermail', { Formdata })
		.then((result) => {
		  const data = result.data
		  console.log('asas',data);
		  if(data == 'success'){
			
		setTimeout(function(){ 
			$(".email-modal").hide();	
		}, 4000);
			mailmsg["msg"] = 'Email was sent to the client ('+email+') on '+currentDateTime+' by @'+this.state.sendername;
			this.setState({
			  users: [],
			  user: {
				  name:'',
				  address:'',
				  password:'',
				  confirm_password:'',
				  email:'',
				  department:'',
				  status:''
			  },
			  errors1: '',
			  mailmsg: mailmsg
			}); 
		  }
	   })
	}


	user(user_id) {
		axios.get(API_BASE_URL+'/users/id/' + user_id)
			 .then((result) => {
				 console.log(result.data);
				 const data = result.data.data
	
				 this.setState({
					  senderemail:data.email,
						sendername:data.name,
						
			  	 })
				
				 })
		 
	
	   }

	   admin(login_id) {
		axios.get(API_BASE_URL+'/login/id/' + login_id)
			 .then((result) => {
				 console.log(result.data);
				 const data = result.data.data
	
				 this.setState({
					  senderemail:data.email,
						sendername:data.admin_name,
						
			  	 })
				
				 })
		 
	
	   }

 printDiv() 
{
  var divToPrint=document.getElementById('DivIdToPrint');

  var newWin=window.open('','Print-Window');

  newWin.document.open();

  newWin.document.write('<html><body onload="window.print()">'+divToPrint.innerHTML+'</body></html>');

  newWin.document.close();

  setTimeout(function(){newWin.close();},10);

}
	
  render() {
	const { files, file,user } = this.state
    console.log('map',files);
    var case_file_number = localStorage.getItem('case_file_numberletter');
    var name = localStorage.getItem('username');
    var address = localStorage.getItem('useraddress');
	var email = localStorage.getItem('useremail');
	var accdate = localStorage.getItem('accdate');
    return (
      <div className="wrapper"> 
 
	  <Header />
	  <MenuSidebar />		

	
	<div className="content">	
		<div className="content-wrapper">
			<div className="content-body">
				<div className="letters-wrap">	

				
			<div className="modal success-modal email-modal" style={{display:"none"}}>
				<div className="modal_content">
				<span className="close" onClick={this.handleClickclose}>&times;</span>
				<p>Email Sent to {email} Successfully</p>
				</div>
			</div> 


					<div className="title">
						<h4>Thank You Letters</h4>
					</div>
					<div className="letters-row">
						<div className="letters-left">					    
						<div className="letters-left-inner">	
							<div className="form-group">
								<input type="text" className="form-control" placeholder="Client's Email Address" value={email} />
							</div>
							<div class="btn-group center">
								<input type="submit" class="btn" onClick={this.sendemail} value="Sent Email" />
							</div>
							<div class="btn-group center">
								<input type="submit" class="btn" value="Print Letter" id='btn' onClick={this.printDiv} />					
							</div>								
							<div className="btn-group next-back-group">	
								<Link className="next"> <i class="arrow icon"></i></Link>
						    </div>

							<div style={{color: "#43425D"}} className="text-danger">{this.state.mailmsg.msg}</div>
						</div>
						</div>
						
						
						<div id='DivIdToPrint' className="letters-right">
						   <div className="legal-law-inner">
						   
							   <div className="legal-law-header">
							        <div className="header-left">
										<h3>Centro-Legal Law Corporation</h3>
									</div>
							        <div className="header-right">
										<p>151 Chin Swee Road<br/> #02-21 Manhattan House<br/> Singapore 169<br/>
										Tel: 6235 0633<br/>Fax: 6235 6939</p>
									</div>
							   </div>
							   <h4>ADVOCATES AND SOLICITORS</h4>
							   <div className="letter-text-wrap">
							   <p>Your Ref: To be advised</p>
                               <p>Our Ref: {case_file_number}</p>
							   <p>15 MAY-2020</p>
							   <div className="client-addr">
                                  
							   </div>

                               <b>
                                <p>{name}</p>
                               <p>{address}</p>
                               </b>
                               <br></br> 

                               <p>Dear Salution,</p>
                               <p><b><u> ACCIDENT ON Date({accdate}) ALONG PAN ISLAND EPRESSWAY INVOLVING MOTOR 
                                   </u></b></p> 
								 <p><b><u>VEHICLES ABC123 AND PC1234A  </u></b></p>

                               <p>We thank you for your instructions to act you in the above matter.</p>
                               <p>Kindly forward to us all relevalent documents including latest medical receipts and medical leave
                               certifiactes,if any in your possession,as soon as possible.</p>
							   </div>
						      
						   </div>
						</div>
						
					</div>
				</div>
			</div>	
		</div>	
	</div>
		 	
    </div>
    );
  }
}

export default Letters;