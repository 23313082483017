import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { API_BASE_URL } from '../config/api';
const axios = require("axios");

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      errors1: {},
      mailmsg: {},
      mailmsg1: {},
      errors: {},
      user: {
        client_name: '',
        password: '',
        rememberMe: false
      },
      id: 1
    };
    this.setInitialStateFromLocalStorage();
  }

  setInitialStateFromLocalStorage = () => {
    const rememberMe = localStorage.getItem('rememberMe') === 'yes';
    this.setState({
      rememberMe: rememberMe,
      user: {
        client_name: rememberMe ? localStorage.getItem('client_name') : '',
        password: rememberMe ? localStorage.getItem('password') : '',
      }
    });
  };

  toggleRememberMe = () => {
    const rememberMe = !this.state.rememberMe;
    this.setState({ rememberMe });
    localStorage.setItem('rememberMe', rememberMe ? 'yes' : 'no');
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState(prevState => ({
      user: {
        ...prevState.user,
        [name]: value
      }
    }));
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.validate(); // Validation
  };

  validate = () => {
    let input = this.state.user;
    let errors = {};
    let isValid = true;

    if (!input["client_name"]) {
      isValid = false;
      errors["client_name"] = "Please enter a user name.";
    }

    if (!input["password"]) {
      isValid = false;
      errors["password"] = "Please enter a password.";
    }

    this.setState({
      errors: errors
    });

    if (isValid) {
      this.handleLogin();
    }
  };

  handleLogin = () => {
    const { user } = this.state;
    axios.get(API_BASE_URL + `/login/admin?client_name=${user.client_name}&password=${user.password}&rememberMe=${this.state.rememberMe ? 'yes' : 'no'}&logged_in='yes'`)
      .then((result) => {
        const data = result.data;
        localStorage.setItem('user_login_id', this.state.id);
      
        if (data === 'success') {
          
          localStorage.setItem('login_type', 'admin');
      
          this.setState({
            mailmsg: { login_msg: 'Logged in successfully..' },
            mailmsg1: {}
          });
          window.location.href = '/';
        } else {
          this.setState({
            mailmsg1: { msg: 'Your Login Name or Password is invalid' },
            mailmsg: {}
          });
        }
      });
  };

  render() {
    const { user } = this.state;
    return (
      <div className="login-sec">
        <div style={{ color: "green" }} className="text-danger">{this.state.mailmsg.login_msg}</div>
        <div style={{ color: "red" }} className="text-danger">{this.state.mailmsg1.msg}</div>
        <div className="login-form">
          <div className="title">
            <h4>CLLC System Login</h4>
          </div>
          <div className="form-group">
            <input type="text" name="client_name" placeholder="Enter User Name" className="form-control" onChange={this.handleInputChange} value={user.client_name} />
            <div style={{ color: "red" }} className="text-danger">{this.state.errors.client_name}</div>
          </div>
          <div className="form-group">
            <input type="password" name="password" placeholder="Enter Password" className="form-control" onChange={this.handleInputChange} value={user.password} autoComplete="new-password" />
            <div style={{ color: "red" }} className="text-danger">{this.state.errors.password}</div>
          </div>
          <div className="form-group">
            <input type="checkbox" id="rememberMe" checked={this.state.rememberMe} onChange={this.toggleRememberMe} />
            <label htmlFor="rememberMe">Remember me</label>
          </div>
          <div className="form-group">
            <input type="button" value='Login' onClick={this.handleSubmit} />
          </div>
          <div className="forgot-group">
            <a href="/forgot" title="Forgot Password">Forgot Password?</a>
            <Link to={"/users/Login"}>Users Login</Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
