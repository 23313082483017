import React, { Component } from 'react';


import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import Login from '../Login/Login';
import { API_BASE_URL } from '../config/api';


class Loginhome extends Component {
	
	constructor(props)
	{
		super(props);	
		this.state = {
			
		};
		
    }
	
	
	
  render() {
    return (
      <div className="wrapper"> 
	  
	  {/*<Header />
	  <MenuSidebar /> */}		

		<div className="content-wrapper">			
			<Login />	
	    </div>
		 	
    </div>
    );
  }
}

export default Loginhome;