import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { API_BASE_URL } from '../config/api';


const axios = require("axios");

class Filestatus extends React.Component {
	
	constructor(props)
	{
		super(props);	
		this.state = {
			files: [],
			errors: {},
			errors2: {},
			searchfiles: [],
			deleteRecord: "",
			query : "",
			file: {
			  plaintiff_name:'',
				minor:'',
				plaintiff_ic_number:'',
				plaintiff_mobile_number:'',
				plaintiff_occupation:'',
				plaintiff_dob:'',
				plaintiff_address:'',
				plaintiff_telephone_number:'',
				referral_by:'',
				accident_date:'',
				accident_time:'',
				accident_type:'',
				accident_location:'',
				plaintiff_position:'',
				time_bar:'',
				p_type:'',
				case_file_number:'',
				plaintiff_vehicle_number:'',
				vehicle_in_front:'',
				vehicle_in_back:''
			  			  
			}
			
		};	
    }
	
	componentDidMount(){
     this.getfiles()
    }
	

  getfiles = _ => {
    fetch(API_BASE_URL+'/files')
    .then((response) => {
        return response.json();
      })
      
      .then(response =>{
        localStorage.setItem('data_hos',JSON.stringify(response.data))   
        this.setState({
            files: [{value: '', case_file_number: 'Select Case Number'}].concat(response.data)
        })})
        .catch(err => console.log(err))
     

   }
  
  
  handleClickSelecet = (event) => {
      const selectedIndex = event.target.options.selectedIndex;
      var key = event.target.options[selectedIndex].getAttribute('data-key');
      console.log('gshiiiii',key);
  axios.get(API_BASE_URL+'/files/id/' + key)

   .then((result) => {  
     const data = result.data.data
	 console.log('uodatettetet',data);
	 
     if(data !== undefined && data !== undefined) {
		this.setState({

			file: {
				plaintiff_name:data.plaintiff_name,
				minor:data.minor,
				plaintiff_ic_number:data.plaintiff_ic_number,
				plaintiff_mobile_number:data.plaintiff_mobile_number,
				plaintiff_occupation:data.plaintiff_occupation,
				plaintiff_dob: data.plaintiff_dob,
				plaintiff_address: data.plaintiff_address,
				plaintiff_telephone_number: data.plaintiff_telephone_number,
				referral_by: data.referral_by,
				accident_date: data.accident_date,
				accident_time:data.accident_time,
				accident_type:data.accident_type,
				accident_location:data.accident_location,
				plaintiff_position:data.plaintiff_position,
				time_bar:data.time_bar,
				p_type:data.p_type,
				case_file_number: data.case_file_number,
				plaintiff_vehicle_number:data.plaintiff_vehicle_number,
				vehicle_in_front:data.vehicle_in_front,
				vehicle_in_back:data.vehicle_in_back,
				plaintiff_id:data.plaintiff_id
						
			}
	 })
	 localStorage.setItem('file_list', JSON.stringify(data))
	}else{

		this.setState({
			file: {
				plaintiff_name:'',
				minor:'',
				plaintiff_ic_number:'',
				plaintiff_mobile_number:'',
				plaintiff_occupation:'',
				plaintiff_dob: '',
				plaintiff_address: '',
				plaintiff_telephone_number: '',
				referral_by: '',
				accident_date: '',
				accident_time:'',
				accident_type:'',
				accident_location:'',
				plaintiff_position:'',
				time_bar:'',
				p_type:'',
				case_file_number: '',
				plaintiff_vehicle_number:'',
				vehicle_in_front:'',
				vehicle_in_back:'',
				plaintiff_id:''
						
			}
		})
  
	  }
 })
};

  
addfilessingle = _ => {
	let input = this.state.file;
    console.log('this.state.file',this.state.file);
	let errors = {};
	let isValid = true;
	if ((!input["case_file_number"]) && (input["case_file_number"] == '')) {
		isValid = false;
		errors["case_file_number"] = "Please enter  case file number.";
	  }
	  this.setState({
		errors: errors
	  }); 
	  if( isValid ==true){
		window.location.href = '/files/filestatussingle';
	}
	
	}

	handleFormSubmit = (e) => {
		const case_file_number = e.target.value;
        const  doc_case_files = "'" +case_file_number + "'";
			console.log('asuploooddd',doc_case_files);
			const  doc_files1 =   doc_case_files.replace("'", "")
			const  doc_files2 =   doc_files1.replace("'", "")
		var doc_files = doc_files2.split(',');
		
		var doc = doc_files;
		var case_doc_files = []
		for (var i = 0; i <= [doc.length]-1; i++) {
			console.log('asddd',doc[i]);
			var case_files = doc[i];
			axios.get(API_BASE_URL+`/files/search_caseid?case_file_id='${case_files}'`)
			.then((result) => {  
			 // const data = result.data.data
			 const data = result.data[0]
			  console.log('uodatettetet',data);
			  if(data !== undefined && data !== undefined) {
				case_doc_files.push(data);
				this.setState({

					file:doc_case_files
			 })
				localStorage.setItem('file_list',  JSON.stringify(case_doc_files));
			 }
		  })
	   
         }
		
		console.log('demmoooo_ffileee',doc_files);
    
	  };
	
addfilesmultiple = _ => {
	let input = this.state.file;
	console.log('this.state.file',this.state.file);
	let errors2 = {};
	let isValid = true;
	if ((!input["case_file_number"]) && (input["case_file_number"] == '')) {
		isValid = false;
		errors2["case_file_number"] = "Please enter  case file number.";
	  }
	  this.setState({
		errors2: errors2
	  }); 
	  if( isValid ==true){
		window.location.href = '/files/filestatusmultiple';
	}
	
	}
	
  render() {
	  const { files, file } = this.state
	  console.log('map',files);
    return (	
		<div className="wrapper"> 
     	  <Header />
	      <MenuSidebar />		
       	<div className="content">	
		<div className="content-wrapper">
			<div className="content-body">
			<div className="form-wrapper file-status-wrap">
			 <div className="title">
				<h4>CHECK STATUS OF FILE</h4>
             </div>
			<div className="form-row">
				<div class="form-group">
			<select  onChange={this.handleClickSelecet}  value={file.case_file_number}  >                    
        {this.state.files.map((files) => <option key={files.plaintiff_id} data-key={files.plaintiff_id} value={files.case_file_number} >{files.case_file_number}</option>)}
	</select>
	<div style={{color: "red"}} className="text-danger">{this.state.errors.case_file_number}</div> 
				</div>
				<div className="btn-group ">	
				<input type="submit" className="btn" onClick={this.addfilessingle} value="Submit" />
			</div>
			</div>
			

            <div className="title">
				<h4>CHECK STATUS OF MULTIPLE FILES</h4>
             </div>
			<div className="form-row">
				<div class="form-group">
					<textarea type="text" className="form-control" placeholder="Enter casefile numbers separated by commas" 
            onChange={this.handleFormSubmit} />
			<div style={{color: "red"}} className="text-danger">{this.state.errors2.case_file_number}</div> 
				</div>
				<div className="btn-group ">	
				<input type="submit" className="btn" onClick={this.addfilesmultiple} value="Submit" />
			</div>
			</div>
			
			</div>
			</div>	
		</div>	
	</div>
		 	
    </div>
    );
  }
}

export default Filestatus;