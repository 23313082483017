import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { API_BASE_URL } from '../config/api';
const axios = require("axios");

class Referrals extends Component {
	
	constructor(props)
	{
		super(props);	
		this.state = {
			referrals: [],
			deleteRecord: "",
			referral: {
			  referrals_name: '',
			  referrals_company: '',
			  referrals_address: '',
			  referrals_contact: '',
			  referrals_email: '',
			}
		};	
    }
	
	componentDidMount(){
		this.getReferrals()
    }
	
	getReferrals = _ => {
    fetch(API_BASE_URL+'/referrals')
    .then(response => response.json())
    .then(response => this.setState({ referrals: response.data}))
    .catch(err => console.log(err))
  }
  
  handleChangeDelete = e => {
	  e.preventDefault();
    this.setState({
      deleteRecord: e.target.name
    });
	
    var Formdata = {
      deleteRecord: e.target.name
    };

    axios.post(API_BASE_URL+'/referrals/delete', { Formdata }).then(response => {
      console.log(response.data);
      console.log(response);
	  this.getReferrals();
	});
  };

  
	renderReferrals = ({ referrals_id, referrals_name, referrals_company, referrals_address, referrals_contact, referrals_email}) => <><tr key={referrals_id}><td>{referrals_name}</td> <td>{referrals_company}</td><td>{referrals_address}</td><td>{referrals_contact}</td><td>{referrals_email}</td><td>
            <div className="action">
			<Link className="btn edit-btn" to={"/referral/edit/" + referrals_id}>Edit</Link>
			<button className="btn delete-btn" name={referrals_id} onClick={this.handleChangeDelete} >Delete</button>
		    </div>
			</td></tr></>
	
	addReferrals = _ => {
    const { referral } = this.state
    fetch(API_BASE_URL+`/referrals/add?referrals_name=${referral.referrals_name}&referrals_company=${referral.referrals_company}&referrals_address=${referral.referrals_address}&referrals_contact=${referral.referrals_contact}&referrals_email=${referral.referrals_email}`)
    .then(this.getReferrals)
    .catch( err => console.log(err))
    }
	
  render() {
	  const { referrals, referral } = this.state
	  console.log('map',referrals);
    return (	
		<div className="wrapper"> 
     	  <Header />
	      <MenuSidebar />		
       	<div className="content">	
		<div className="content-wrapper">
			<div className="content-body">
			<div className="form-wrapper">			
				 <div className="title">
					<h4>Referrals Management</h4>
					<Link className="add-btn" to={"/referral/add"}> Add</Link>
				 </div>
			{(referrals !='' && typeof referrals != 'undefined')?<table><tr><th width="140">Referral Name</th><th>Referral Company</th><th>Referral Address</th><th>Referral Contact</th><th>Referral Email</th><th>Actions</th></tr>{referrals.map(this.renderReferrals)}</table>:<div>No data found.</div>}
		
	     	</div>
			</div>	
		</div>	
	</div>
		 	
    </div>
    );
  }
}

export default Referrals;