import React, { Component } from 'react';
import { Link } from "react-router-dom";
import  Header from '../Layouts/Header';
import  onSubmitMessage from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { API_BASE_URL } from '../config/api';
const axios = require("axios");

class AddUsers extends Component {
	
	constructor(props)
	{
		super(props);	
		this.state = {
            users: [],
            deleteRecord: "",
            errors: {},
            errors1: {},
            mailmsg: {},
            input: {},
            user: {
				        name:'',
                address:'',
                password:'',
                confirm_password:'',
                email:'',
                department:'',
                status:''
            }
        };
        this.handleSubmit = this.handleSubmit.bind(this);
		    let array=[];
    }

    
    handleClickSelecet = (event) => {
      let array=[];
      
        if((event.target.checked === true) && (event.target.name === 'dashboard')){
            var value = event.target.name;
            var existing = localStorage.getItem('array');
            existing = existing ? existing.split(',') : [];
            existing.push(value);
            localStorage.setItem('array', existing.toString());
         
         }else  if((event.target.checked === false) && (event.target.name === 'dashboard')){ 
          var existing = localStorage.getItem('array');
          existing = existing.replace('dashboard', '');
          console.log('dashboardpushh',existing);
          localStorage.setItem('array', existing);
         }
         if((event.target.checked === true) && (event.target.name === 'files')){
          var value = event.target.name;
          var existing = localStorage.getItem('array');
          existing = existing ? existing.split(',') : [];
          existing.push(value);
          localStorage.setItem('array', existing.toString());
         
        }else  if((event.target.checked === false) && (event.target.name === 'files')){ 
          var existing = localStorage.getItem('array');
          existing = existing.replace('files', '');
          console.log('dashboardpushh',existing);
          localStorage.setItem('array', existing);
         }
        if((event.target.checked === true) && (event.target.name === 'documents')){
          var value = event.target.name;
          var existing = localStorage.getItem('array');
          existing = existing ? existing.split(',') : [];
          existing.push(value);
          localStorage.setItem('array', existing.toString());
        }else  if((event.target.checked === false) && (event.target.name === 'documents')){ 
          var existing = localStorage.getItem('array');
          existing = existing.replace('documents', '');
          console.log('dashboardpushh',existing);
          localStorage.setItem('array', existing);
         }
        if((event.target.checked === true) && (event.target.name === 'letters')){
         var value = event.target.name;
         var existing = localStorage.getItem('array');
         existing = existing ? existing.split(',') : [];
         existing.push(value);
         localStorage.setItem('array', existing.toString());
        }else  if((event.target.checked === false) && (event.target.name === 'letters')){ 
          var existing = localStorage.getItem('array');
          existing = existing.replace('letters', '');
          console.log('dashboardpushh',existing);
          localStorage.setItem('array', existing);
         }
        if((event.target.checked === true) && (event.target.name === 'communications')){
          var value = event.target.name;
          var existing = localStorage.getItem('array');
          existing = existing ? existing.split(',') : [];
          existing.push(value);
          localStorage.setItem('array', existing.toString());
        }else  if((event.target.checked === false) && (event.target.name === 'communications')){ 
          var existing = localStorage.getItem('array');
          existing = existing.replace('communications', '');
          console.log('dashboardpushh',existing);
          localStorage.setItem('array', existing);
         }
        if((event.target.checked === true) && (event.target.name === 'accounts')){
          var value = event.target.name;
          var existing = localStorage.getItem('array');
          existing = existing ? existing.split(',') : [];
          existing.push(value);
          localStorage.setItem('array', existing.toString());
        }else  if((event.target.checked === false) && (event.target.name === 'accounts')){ 
          var existing = localStorage.getItem('array');
          existing = existing.replace('accounts', '');
          console.log('dashboardpushh',existing);
          localStorage.setItem('array', existing);
         }
        if((event.target.checked === true) && (event.target.name === 'users')){
          var value = event.target.name;
          var existing = localStorage.getItem('array');
          existing = existing ? existing.split(',') : [];
          existing.push(value);
          localStorage.setItem('array', existing.toString());
        }else  if((event.target.checked === false) && (event.target.name === 'users')){ 
          var existing = localStorage.getItem('array');
          existing = existing.replace('users', '');
          console.log('dashboardpushh',existing);
          localStorage.setItem('array', existing);
         }
        if((event.target.checked === true) && (event.target.name === 'settings')){
          var value = event.target.name;
          var existing = localStorage.getItem('array');
          existing = existing ? existing.split(',') : [];
          existing.push(value);
          localStorage.setItem('array', existing.toString());
        }else  if((event.target.checked === false) && (event.target.name === 'settings')){ 
          var existing = localStorage.getItem('array');
          existing = existing.replace('settings', '');
          console.log('dashboardpushh',existing);
          localStorage.setItem('array', existing);
         }
            
      
    }

    getUsers = _ => {
        fetch(API_BASE_URL+'/users')
        .then(response => response.json())
        .then(response => this.setState({ user: response.data}))
        .catch(err => console.log(err))
      }

      handleSubmit(event) {
        event.preventDefault();
        this.validate();//validationnn
      }
    //validationnn for all pwd ,confrm passwrd and email and alll fields.......
      validate(){
    
          let input = this.state.user;

          let errors = {};
          let isValid = true;
          if (typeof input["email"] !== "undefined") {
    
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    
            if (!pattern.test(input["email"])) {
    
              isValid = false;
    
              errors["email"] = "Please enter valid email address.";
    
            }
    
          }

          if (!input["name"]) {
            isValid = false;
            errors["name"] = "Please enter your name.";
          }
    
          if (!input["email"]) {
            isValid = false;
            errors["email"] = "Please enter your email.";
          }

          if (!input["department"]) {
            isValid = false;
            errors["department"] = "Please enter your department.";
          }

          if (!input["address"]) {
            isValid = false;
            errors["address"] = "Please enter your address.";
          }
    
          if (!input["password"]) {
            isValid = false;
            errors["password"] = "Please enter your password.";
          }
          if (!input["confirm_password"]) {
            isValid = false;
            errors["confirm_password"] = "Please enter your confirm password.";
          }
          if (typeof input["password"] !== "undefined" && typeof input["confirm_password"] !== "undefined") {
            if (input["password"] != input["confirm_password"]) {
              isValid = false;
              errors["password"] = "Passwords don't match.";
            }
          } 
    
          this.setState({
            errors: errors
          }); 
          console.log('sdsds',isValid);
         if( isValid ==true){
            this.email();
        }
    
      }

      ///duplicate emaill checkigg............
      email = _ => {
        const { user } = this.state;
        var email = user.email;
        let errors1 = {};
        let mailmsg = {};
        axios.get(API_BASE_URL+'/users/duplicate_email?email='+email)
        .then((result) => {
          const data = result.data
          console.log('asas',data);
          if(data == 'unique one'){
            this.addUsers();
          }else{
            errors1["email"] = data;
            mailmsg["msg"] = 'Submitting data...';
            this.setState({
              mailmsg: mailmsg,
              errors1: errors1
            }); 
          }
          })
        }
  
        //sending mail for the user...
        sendemail = _ => {
          const { user } = this.state;
          var email = user.email;
          let mailmsg = {};
          axios.get(API_BASE_URL+'/users/send/mail?to='+email)
          .then((result) => {
            const data = result.data
            console.log('asas',data);
            if(data == 'success'){
              mailmsg["msg"] = 'Mail Sent successfully for the user';
              this.setState({
                users: [],
                files : [],
                file: [],
                
                user: {
                    name:'',
                    address:'',
                    password:'',
                    confirm_password:'',
                    email:'',
                    department:'',
                    status:''
                },
                errors1: '',
                mailmsg: mailmsg
              }); 
            }
         })
      }

      uploadfiless(e) {
        // let reader = new FileReader();
        // let file = e.target.files[0];
  
        //   //   reader.onloadend = () => {
        //   //     this.photo = reader.result.toString();
        //   // };
        //  // this.photo_name = oldFileItem+'.'+'jpg';
        //   var imgFile = new File([file], {type:file.type});
        //   console.log('imageefilesss',file);
        //   reader.readAsDataURL(file);
        e.preventDefault();
       // this.setState({ file:  e.target.files });
        console.log('aaaaffffff',e.target.files);
        const formData = new FormData();
        formData.append('file[]', e.target.files[0]);
    
        console.log('filessprofile',e.target.files[0]);
      axios.post(API_BASE_URL+'/users/upload_file', formData).then(response => {
      if(response.data.status==="success") {
       // this.setState({ file:  response.img_name});
       console.log('filessprofile',response);
       localStorage.setItem('profile_image', response.data.img_name[0].filename);
    
    }
    });
        }
    
    addUsers = _ => {
        const { user } = this.state;
        var permission_module = localStorage.getItem('array');
        let mailmsg = {};
        var status = 'Active';
        var profile_image = localStorage.getItem('profile_image');
        fetch(API_BASE_URL+`/users/add?name=${user.name}&address=${user.address}&email=${user.email}&department=${user.department}&status=${status}&password=${user.password}&permission_module=${permission_module}&profile='${profile_image}'`)
        .then(this.getUsers)
        .then(this.sendemail) //sending mail for the user...
        .catch( err => console.log(err))
        mailmsg["msg"] = 'Submitting data...';
            this.setState({
              mailmsg: mailmsg
              
            });
            localStorage.removeItem('array');
        //localStorage.clear();
        // window.location.href = '/users';
        }
	
	
  render() {
    const { users, user} = this.state
    return (
        <div className="wrapper"> 
 
        <Header />
        <MenuSidebar />		
  
      
      <div className="content">	
      <div className="content-wrapper">
      <div className="content-body">
			<div className="form-wrapper edit-profile-form">
      <div style={{color: "green"}} className="text-danger">{this.state.mailmsg.msg}</div>
			<div className="title">
				<h4>Add Users</h4>
			</div>
			<div className="form-row">
			<div className="form-left">
				<div class="form-group">
					<label>User Name</label>
					<input type="text" className="form-control" onChange={e => this.setState({ user: {...user, name:e.target.value}})} placeholder="" value={user.name} />
                    <div style={{color: "red"}} className="text-danger">{this.state.errors.name}</div>
                </div>
				<div class="form-group">
					<label>Password</label>
					<input type="password" name='password' id='password' onChange={e => this.setState({ user: {...user, password:e.target.value}})} placeholder="" value={user.password} className="form-control"  />
                    <div style={{color: "red"}} className="text-danger">{this.state.errors.password}</div>
                </div>
                <div class="form-group">
					<label>Department</label>
					<input type="text" className="form-control"  onChange={e => this.setState({ user: {...user, department:e.target.value}})} placeholder=""  className="form-control" value={user.department} />
                    <div style={{color: "red"}} className="text-danger">{this.state.errors.department}</div>
                </div>
			</div>
			<div className="form-right">
            <div class="form-group">
					<label>Email</label>
					<input type="text" name='email' id='email' onChange={e => this.setState({ user: {...user, email:e.target.value}})} placeholder=""  className="form-control" value={user.email} />
                    <div style={{color: "red"}} className="text-danger">{this.state.errors.email}</div>
                    <div style={{color: "red"}} className="text-danger">{this.state.errors1.email}</div>
                </div>
				<div class="form-group">
					<label>Confirm Password </label>
					<input type="password" name='confirm_password' id='confirm_password' onChange={e => this.setState({ user: {...user, confirm_password:e.target.value}})} placeholder=""  className="form-control" value={user.confirm_password}/>
                    <div style={{color: "red"}} className="text-danger">{this.state.errors.confirm_password}</div>
                </div>
				<div class="form-group">
					<label>Address</label>
					<input type="text"onChange={e => this.setState({ user: {...user, address:e.target.value}})} placeholder=""  className="form-control" value={user.address} />
				    <div style={{color: "red"}} className="text-danger">{this.state.errors.address}</div>
        </div>	
        <div className="file-upload">
                     <input type="file"    onChange={this.uploadfiless} />
				</div>		
			</div>

      
      <div className="module-access">
        <div className="title">
          <h4>Module Access Permission</h4>
        </div>
        <div className="module-access-row">
        <div > <input name="dashboard"  onChange={this.handleClickSelecet} ref="dashboard" type="checkbox" placeholder="" /> <span>Dashboard</span></div>
        <div > <input name="files"  onChange={this.handleClickSelecet} ref="files" type="checkbox" placeholder="" /> <span>Files</span></div>
        <div > <input name="documents"  onChange={this.handleClickSelecet} ref="documents" type="checkbox" placeholder="" /> <span>Documents</span></div>
        <div > <input name="letters"  onChange={this.handleClickSelecet} ref="letters" type="checkbox" placeholder="" /> <span>Letters</span></div>
        <div > <input name="communications"  onChange={this.handleClickSelecet} ref="communications" type="checkbox" placeholder="" /> <span>Communications</span></div>
        <div > <input name="accounts"  onChange={this.handleClickSelecet} ref="accounts" type="checkbox" placeholder="" /> <span>Accounts</span></div>
        <div > <input name="users"  onChange={this.handleClickSelecet} ref="users" type="checkbox" placeholder="" /> <span>Users</span></div>
        <div > <input name="settings"  onChange={this.handleClickSelecet} ref="settings" type="checkbox" placeholder="" /> <span>Settings</span></div>
            
        <div className="btn-group export">	
          <input type="submit" onClick={this.handleSubmit} className="btn" value="Submit" />
        </div>
        </div>      
      </div>

			</div>

			</div>
      </div>	
		</div>	
	</div>
		 	
    </div>
    );
  }
}

export default AddUsers;