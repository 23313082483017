import React, { Component } from 'react';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { Link } from "react-router-dom";
import { API_BASE_URL } from '../config/api';

const axios = require("axios");

class EditDisbursement extends React.Component {
	
	constructor(props)
	{
		super(props);	
		this.state = {
			disbursements: [],
			disbursement: {
			  documents_lta_search_fee: '',
			  documents_gia_report_fee: '',
			  documents_police_reports: '',
			  medical_reports: '',
			  medical_report_color_copies: '',
			  medical_report_incidentals:'',
			  services_one:'',
			  services_two:'',
			  disbursement_id:''
			},
			id: 1
		};	
    }
	
	componentDidMount(){
        axios.get(API_BASE_URL+'/disbursements/id/' + this.state.id)
        .then((result) => {
            const data = result.data.data
            this.setState({
              disbursement: {
			  documents_lta_search_fee: data.documents_lta_search_fee,
			  documents_gia_report_fee: data.documents_gia_report_fee,
			  documents_police_reports: data.documents_police_reports,
			  medical_reports: data.medical_reports,
			  medical_report_color_copies: data.medical_report_color_copies,
			  medical_report_incidentals: data.medical_report_incidentals,			  
			  services_one: data.services_one,			  
			  services_two: data.services_two,			  
			  disbursement_id: data.disbursement_id,			  
			}
            })
        })
    }
	
	
	editDisbursements = _ => {
    const { disbursement } = this.state
    fetch(API_BASE_URL+`/disbursements/update?documents_lta_search_fee=${disbursement.documents_lta_search_fee}&documents_gia_report_fee=${disbursement.documents_gia_report_fee}&documents_police_reports=${disbursement.documents_police_reports}&medical_reports=${disbursement.medical_reports}&medical_report_color_copies=${disbursement.medical_report_color_copies}&medical_report_incidentals=${disbursement.medical_report_incidentals}&services_one=${disbursement.services_one}&services_two=${disbursement.services_two}&disbursement_id=${disbursement.disbursement_id}`)
    .then((result) => {
                    console.log(result);
				
                   window.location.href = '/disbursement';
                })
    .catch( err => console.log(err))
    }
	
  render() {
	  const { disbursements, disbursement } = this.state
	  return (
	  <>
        <div className="form-row">
			<div className="form-left">
			<h3>Documents</h3>
				<div class="form-group">
					<label>LTA Search Fee</label>
					<input type="text" className="form-control" placeholder="" value={disbursement.documents_lta_search_fee}
            onChange={e => this.setState({ disbursement: {...disbursement, documents_lta_search_fee:e.target.value}})} />
				</div>
				<div class="form-group">
					<label>GIA Report Fee</label>
					<input type="text" className="form-control"  placeholder="" value={disbursement.documents_gia_report_fee}
            onChange={e => this.setState({ disbursement: {...disbursement, documents_gia_report_fee:e.target.value}})} />
				</div>
				<div class="form-group">
					<label>Police Reports</label>
					<input type="text" className="form-control"  placeholder="" value={disbursement.documents_police_reports}
            onChange={e => this.setState({ disbursement: {...disbursement, documents_police_reports:e.target.value}})} />
				</div>
			</div>
			<div className="form-right">
			<h3>Medical Reports</h3>
			    <div class="form-group">
					<label>Medical Reports</label>
					<input type="text" className="form-control" placeholder="" value={disbursement.medical_reports}
            onChange={e => this.setState({ disbursement: {...disbursement, medical_reports:e.target.value}})} />
				</div>
				<div class="form-group">
					<label>Colour Copies</label>
					<input type="text" className="form-control"  placeholder="" value={disbursement.medical_report_color_copies}
            onChange={e => this.setState({ disbursement: {...disbursement, medical_report_color_copies:e.target.value}})} />
				</div>
				<div class="form-group">
					<label>Incidentals</label>
					<input type="text" className="form-control"  placeholder="" value={disbursement.medical_report_incidentals}
            onChange={e => this.setState({ disbursement: {...disbursement, medical_report_incidentals:e.target.value}})} />
				</div>
			   </div>
			</div>
			
			 <div className="title">
				<h4>Services</h4>
	         </div>
			<div className="form-row">
			<div className="form-left">
				<div class="form-group">
					<label>Services 1</label>
					<input type="text" className="form-control" placeholder="" value={disbursement.services_one}
            onChange={e => this.setState({ disbursement: {...disbursement, services_one:e.target.value}})} />
				</div>
			</div>
			<div className="form-right">
			    <div class="form-group">
					<label>Services 2</label>
					<input type="text" className="form-control" placeholder="" value={disbursement.services_two}
            onChange={e => this.setState({ disbursement: {...disbursement, services_two:e.target.value}})} />
				</div>
			</div>
			</div>
			
			<div className="btn-group export">	
				<input type="submit" className="btn" onClick={this.editDisbursements} value="Update" />
			</div>
		</>
	  );
  }
}

export default EditDisbursement;