import React, { Component } from 'react';
import { Link } from "react-router-dom";
import  Header from '../Layouts/Header';
import  onSubmitMessage from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { API_BASE_URL,API_KEY_CHAT,REGION_CHAT,APP_ID_CHAT } from '../config/api';
import { Widget , addResponseMessage ,addUserMessage } from 'react-chat-widget';
import { CometChat } from "@cometchat-pro/chat" 
import $ from 'jquery';
import 'react-chat-widget/lib/styles.css';
 
const axios = require("axios");



class usergroupadd extends Component {
	
	constructor(props)
	{
		super(props);	
		this.state = {
			id: this.props.match.params.id 
		};
        
        
    }
	componentDidMount(){
        var appID = APP_ID_CHAT;
        var region = REGION_CHAT;
        var appSetting = new CometChat.AppSettingsBuilder().subscribePresenceForAllUsers().setRegion(region).build();
        CometChat.init(appID, appSetting).then(
        () => {
            console.log("Initialization completed successfully");
            this.userslist();
            // You can now call login function.
        },
        error => {
            console.log("Initialization failed with error:", error);
            // Check the reason for error and take appropriate action.
        }
        );
    }
   

      back = () => {
        window.location.href = '/chat';
        
      };

      userslist = () => {

        var limit = 50;
        var usersRequest = new CometChat.UsersRequestBuilder().setLimit(limit).build();
        
        usersRequest.fetchNext().then(
          userList => {
            /* userList will be the list of User class. */
            console.log("User list received:", userList);
            localStorage.setItem('contact_list',JSON.stringify(userList))
            /* retrived list can be used to display contact list. */
          },
          error => {
            console.log("User list fetching failed with error:", error);
          }
        );

      }


      addmember = (e) => {
        e.preventDefault();
        console.log(e.target.id);
        let GUID = this.state.id;
        var userid = e.target.id;
        let membersList = [
          new CometChat.GroupMember(userid, CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT)
        ];
        
        CometChat.addMembersToGroup(GUID, membersList, []).then(
          response => {
            console.log("response", response);
            if(response[userid]=='success'){
                alert("Memeber Added Sucessfully..");
                window.location.reload();
                
            }else{
                alert(response[userid]);
                window.location.reload();
            }
           
          },
          error => {
            console.log("Something went wrong", error);
            alert(error.message);
            window.location.reload();
          }
        );

      }

  render() {
    var contact_list = JSON.parse(localStorage.getItem('contact_list')) ?JSON.parse(localStorage.getItem('contact_list')) :[];
   
    return (	
		<div className="wrapper"> 
     	  <Header />
	      <MenuSidebar  />		
       	<div className="content">	
		<div className="content-wrapper">
			<div className="content-body">
			<div className="form-wrapper">	
			<div className="title">
            <div id="cometchat"></div>
				<h4>Add Members in group</h4>
             </div>
             <h4>Users List </h4>	
             {(contact_list !='' )?	
          <table><tr><th width='300px'>Users Name</th><th width='300px'>Status</th><th width='100px'> Actions</th></tr>
             {contact_list.map((contact_list) =>
             <tr key={contact_list.uid} ><td>{contact_list.name}</td> <td>{contact_list.status}</td><td width='100px'>
              
               <span  id={contact_list.uid} name={contact_list.uid} onClick={this.addmember}>Add Member</span> 
                 </td></tr >
             )}
             </table>:<div>No Contact List found.</div>}
        
	     	</div>
             <div className="btn-group export">	
				<input type="submit" className="btn" onClick={this.back} value="Back" />
			</div>
			</div>	
		</div>	
	</div>
		 	
    </div>
    );
  }
}

export default usergroupadd;