import React, { Component } from 'react';
import { API_BASE_URL } from '../config/api';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { Link } from "react-router-dom";


const axios = require("axios");

class EditAccount extends React.Component {
	
	constructor(props)
	{
		super(props);	
		this.state = {
			accounts: [],
			account: {
				account_id : '',
				plaintiff_amount: '',
				plaintiff_type: '',
				warrant_amount: '',
				gia_type: '',
				warrant_type: '',
				gia_amount: '',
				police_report_amount: '',
				police_report_type: '',
				medical_amount: '',
				medical_type: '',
				genral_medical_amount: '',
				genral_medical_type: '',
				specialist_medical_amount: '',
				specialist_medical_type: '',
				loss_of_income_amount: '',
				loss_of_income_type: '',
				accident_type: '',
				accident_amount: '',
				photograph_amount: '',
				photograph_type: ''
			},
			id: 1
		};	
    }
	
	componentDidMount(){
        axios.get(API_BASE_URL+'/account/id/' + this.state.id)
        .then((result) => {
            const data = result.data.data
            this.setState({
              account: {
				account_id : data.account_id,
				plaintiff_amount: data.plaintiff_amount,
				plaintiff_type: data.plaintiff_type,
				warrant_amount: data.warrant_amount,
				gia_type: data.gia_type,
				warrant_type: data.warrant_type,
				gia_amount: data.gia_amount,
				police_report_amount: data.police_report_amount,
				police_report_type: data.police_report_type,
				medical_amount: data.medical_amount,
				medical_type: data.medical_type,
				genral_medical_amount: data.genral_medical_amount,
				genral_medical_type: data.genral_medical_type,
				specialist_medical_amount: data.specialist_medical_amount,
				specialist_medical_type: data.specialist_medical_type,
				loss_of_income_amount: data.loss_of_income_amount,
				loss_of_income_type: data.loss_of_income_type,
				accident_type: data.accident_type,
				accident_amount: data.accident_amount,
				photograph_amount: data.photograph_amount,
				photograph_type: data.photograph_type			  
			  }
			})
			
	var plaintiff_type = localStorage.setItem('plaintiff_type',data.plaintiff_type);
	var warrant_type = localStorage.setItem('warrant_type',data.warrant_type);
	var gia_type = localStorage.setItem('gia_type',data.gia_type);
	var police_report_type = localStorage.setItem('gia_type',data.police_report_type);
	var medical_type = localStorage.setItem('medical_type',data.medical_type);
	var genral_medical_type = localStorage.setItem('genral_medical_type',data.genral_medical_type);
	var specialist_medical_type = localStorage.setItem('specialist_medical_type',data.specialist_medical_type);
	var loss_of_income_type = localStorage.setItem('loss_of_income_type',data.loss_of_income_type);
	var accident_type = localStorage.setItem('accident_type',data.accident_type);
	var photograph_type = localStorage.setItem('photograph_type',data.photograph_type);
		})
	}
	
	onValueChangetype = (event) => {
		//this.props.onChange(this.props.myValue)
		console.log('plaintiff_type',event.target.value);
		if(event.target.value != ''){
			var plaintiff_type = event.target.value;
		}
	localStorage.setItem('plaintiff_type', plaintiff_type);
	this.setState({
		plaintiff_type: event.target.value
		});
        
	  }
	  onValueChangetype2 = (event) => {
		if(event.target.value != ''){
			var warrant_type = event.target.value;
		}
	localStorage.setItem('warrant_type', warrant_type);
	this.setState({
		warrant_type: event.target.value
		});
        
	  }
	  onValueChangetype3 = (event) => {
		if(event.target.value != ''){
			var gia_type = event.target.value;
		}
	localStorage.setItem('gia_type', gia_type);
	this.setState({
		gia_type: event.target.value
		});
        
	  }

	  onValueChangetype4 = (event) => {
		if(event.target.value != ''){
			var police_report_type = event.target.value;
		}
	localStorage.setItem('police_report_type', police_report_type);
	this.setState({
		police_report_type: event.target.value
		});
        
	  }
	  onValueChangetype5 = (event) => {
		if(event.target.value != ''){
			var medical_type = event.target.value;
		}
	localStorage.setItem('medical_type', medical_type);
	this.setState({
		medical_type: event.target.value
		});
        
	  }

	  onValueChangetype6 = (event) => {
		if(event.target.value != ''){
			var genral_medical_type = event.target.value;
		}
	localStorage.setItem('genral_medical_type', genral_medical_type);
	this.setState({
		genral_medical_type: event.target.value
		});
        
	  }

	  onValueChangetype7 = (event) => {
		if(event.target.value != ''){
			var specialist_medical_type = event.target.value;
		}
	localStorage.setItem('specialist_medical_type', specialist_medical_type);
	this.setState({
		specialist_medical_type: event.target.value
		});
        
	  }
	  onValueChangetype8 = (event) => {
		if(event.target.value != ''){
			var loss_of_income_type = event.target.value;
		}
	localStorage.setItem('loss_of_income_type', loss_of_income_type);
	this.setState({
		loss_of_income_type: event.target.value
		});
        
	  }


	  onValueChangetype9 = (event) => {
		if(event.target.value != ''){
			var accident_type = event.target.value;
		}
	localStorage.setItem('accident_type', accident_type);
	this.setState({
		accident_type: event.target.value
		});
        
	  }

	  onValueChangetype10 = (event) => {
		if(event.target.value != ''){
			var photograph_type = event.target.value;
		}
	localStorage.setItem('photograph_type', photograph_type);
	this.setState({
		photograph_type: event.target.value
		});
        
	  }

	
	
	editaccount = _ => {
	const { account } = this.state
	var plaintiff_type = localStorage.getItem('plaintiff_type');
	var warrant_type = localStorage.getItem('warrant_type');
	var gia_type = localStorage.getItem('gia_type');
	var police_report_type = localStorage.getItem('police_report_type');
	var medical_type = localStorage.getItem('medical_type');
	var genral_medical_type = localStorage.getItem('genral_medical_type');
	var specialist_medical_type = localStorage.getItem('specialist_medical_type');
	var loss_of_income_type = localStorage.getItem('loss_of_income_type');
	var accident_type = localStorage.getItem('accident_type');
	var photograph_type = localStorage.getItem('photograph_type');
	
	console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaa',account);
    fetch(API_BASE_URL+`/account/update?plaintiff_amount=${account.plaintiff_amount}&plaintiff_type=${plaintiff_type}&genral_medical_amount=${account.genral_medical_amount}&medical_type=${medical_type}&specialist_medical_type=${specialist_medical_type}&loss_of_income_amount=${account.loss_of_income_amount}&photograph_amount=${account.photograph_amount}&photograph_type=${photograph_type}&accident_amount=${account.accident_amount}&accident_type=${accident_type}&loss_of_income_type=${loss_of_income_type}&specialist_medical_amount=${account.specialist_medical_amount}&genral_medical_type=${genral_medical_type}&medical_amount=${account.medical_amount}&police_report_type=${police_report_type}&police_report_amount=${account.police_report_amount}&warrant_amount=${account.warrant_amount}&gia_type=${gia_type}&warrant_type=${warrant_type}&gia_amount=${account.gia_amount}&account_id=${account.account_id}`)
    .then((result) => {
                    console.log(result);
                 window.location.href = '/account';
                })
    .catch( err => console.log(err))
    }
	
  render() {
	  const { accounts, account } = this.state
	  return (
	  <>
	  <div className="account-update-wrap">
			<div className="form-row">
				<div className="title">
				<h4> Plaintiff NRIC</h4>
				</div>
				<div className="form-left">
				<div class="form-group">
						<select className="form-control" id="lang" onChange={this.onValueChangetype.bind(this)} value={this.state.plaintiff_type}>
					<option  value="select">Select a Type</option>
					<option selected={account.plaintiff_type == 'Applicable'} value="Applicable">Applicable</option>
					<option  selected={account.plaintiff_type == 'Not Applicable'} value='Not Applicable'>Not Applicable</option>
					</select>
				</div>	
				</div>			
				<div className="form-right">
				<div class="form-group">
						<input type="text" className="form-control" placeholder="" value={account.plaintiff_amount}
				onChange={e => this.setState({ account: {...account, plaintiff_amount:e.target.value}})} />
					
				</div>
				</div>
			</div>
			<div className="form-row">	   
				<div className="title">
				<h4> Warrant To Act</h4>
				</div>
				<div className="form-left">
				<div class="form-group">
						<select className="form-control" id="lang" onChange={this.onValueChangetype2.bind(this)} value={this.state.warrant_type}>
					<option  value="select">Select a Type</option>
					<option selected={account.warrant_type == 'Applicable'} value="Applicable">Applicable</option>
					<option  selected={account.warrant_type == 'Not Applicable'} value='Not Applicable'>Not Applicable</option>
					</select>
				</div>	
				</div>			
				<div className="form-right">
				<div class="form-group">
						<input type="text" className="form-control" placeholder="" value={account.warrant_amount}
				onChange={e => this.setState({ account: {...account, warrant_amount:e.target.value}})} />
					
					</div>
				</div>		
			</div>
			<div className="form-row">
			<div className="title">
			<h4> GIA Report</h4>
			</div>
				<div className="form-left">
				<div class="form-group">
						<select className="form-control" id="lang" onChange={this.onValueChangetype3.bind(this)} value={this.state.gia_type}>
					<option  value="select">Select a Type</option>
					<option selected={account.gia_type == 'Applicable'} value="Applicable">Applicable</option>
					<option  selected={account.gia_type == 'Not Applicable'} value='Not Applicable'>Not Applicable</option>
					</select>
				</div>	
				</div>			
				<div className="form-right">
				<div class="form-group">
						<input type="text" className="form-control" placeholder="" value={account.gia_amount}
				onChange={e => this.setState({ account: {...account, gia_amount:e.target.value}})} />
					
					</div>
				</div>
			
			</div>
			<div className="form-row">           
			<div className="title">
					<h4> Police Report</h4>
			</div>
				<div className="form-left">
				<div class="form-group">
						<select className="form-control" id="lang" onChange={this.onValueChangetype4.bind(this)} value={this.state.police_report_type}>
					<option  value="select">Select a Type</option>
					<option selected={account.police_report_type == 'Applicable'} value="Applicable">Applicable</option>
					<option  selected={account.police_report_type == 'Not Applicable'} value='Not Applicable'>Not Applicable</option>
					</select>
				</div>	
				</div>	
				<div className="form-right">
				<div class="form-group">
						<input type="text" className="form-control" placeholder="" value={account.police_report_amount}
				onChange={e => this.setState({ account: {...account, police_report_amount:e.target.value}})} />
					
					</div>
				</div>		
			</div>
			<div className="form-row">
				<div className="title">
					<h4> Medical Receipt</h4>
			</div>
				<div className="form-left">
				<div class="form-group">
						<select className="form-control" id="lang" onChange={this.onValueChangetype5.bind(this)} value={this.state.medical_type}>
					<option  value="select">Select a Type</option>
					<option selected={account.medical_type == 'Applicable'} value="Applicable">Applicable</option>
					<option  selected={account.medical_type == 'Not Applicable'} value='Not Applicable'>Not Applicable</option>
					</select>
				</div>	
				</div>	
				<div className="form-right">
				<div class="form-group">
						<input type="text" className="form-control" placeholder="" value={account.medical_amount}
				onChange={e => this.setState({ account: {...account, medical_amount:e.target.value}})} />
				</div>
				</div>		
			</div>
			<div className="form-row">           
				<div className="title">
					<h4>General M.R</h4>
			</div>
				<div className="form-left">
				<div class="form-group">
				<select className="form-control" id="lang" onChange={this.onValueChangetype6.bind(this)} value={this.state.genral_medical_type}>
					<option  value="select">Select a Type</option>
					<option selected={account.genral_medical_type == 'Applicable'} value="Applicable">Applicable</option>
					<option  selected={account.genral_medical_type == 'Not Applicable'} value='Not Applicable'>Not Applicable</option>
					</select>
				</div>	
				</div>	
				<div className="form-right">
				<div class="form-group">
						<input type="text" className="form-control" placeholder="" value={account.genral_medical_amount}
				onChange={e => this.setState({ account: {...account, genral_medical_amount:e.target.value}})} />
				</div>
				</div>
				</div>
			<div className="form-row">
				<div className="title">
					<h4>Specialist M.R</h4>
			</div>
				<div className="form-left">
				<div class="form-group">
						<select className="form-control" id="lang" onChange={this.onValueChangetype7.bind(this)} value={this.state.specialist_medical_type}>
					<option  value="select">Select a Type</option>
					<option selected={account.specialist_medical_type == 'Applicable'} value="Applicable">Applicable</option>
					<option  selected={account.specialist_medical_type == 'Not Applicable'} value='Not Applicable'>Not Applicable</option>
					</select>
				</div>	
				</div>	
				<div className="form-right">
				<div class="form-group">
						<input type="text" className="form-control" placeholder="" value={account.specialist_medical_amount}
				onChange={e => this.setState({ account: {...account, specialist_medical_amount:e.target.value}})} />
				</div>
				</div>
				</div>
			<div className="form-row">
				<div className="title">
					<h4>Loss Of Income</h4>
			</div>
				<div className="form-left">
				<div class="form-group">
						<select className="form-control" id="lang" onChange={this.onValueChangetype8.bind(this)} value={this.state.loss_of_income_type}>
					<option  value="select">Select a Type</option>
					<option selected={account.loss_of_income_type == 'Applicable'} value="Applicable">Applicable</option>
					<option  selected={account.loss_of_income_type == 'Not Applicable'} value='Not Applicable'>Not Applicable</option>
					</select>
				</div>	
				</div>	
				<div className="form-right">
				<div class="form-group">
						<input type="text" className="form-control" placeholder="" value={account.loss_of_income_amount}
				onChange={e => this.setState({ account: {...account, loss_of_income_amount:e.target.value}})} />
				</div>
				</div>
				</div>
			<div className="form-row">
				<div className="title">
					<h4>Accident Video</h4>
			</div>
				<div className="form-left">
				<div class="form-group">
						<select className="form-control" id="lang" onChange={this.onValueChangetype9.bind(this)} value={this.state.accident_type}>
					<option  value="select">Select a Type</option>
					<option selected={account.accident_type == 'Applicable'} value="Applicable">Applicable</option>
					<option  selected={account.accident_type == 'Not Applicable'} value='Not Applicable'>Not Applicable</option>
					</select>
				</div>	
				</div>	
				<div className="form-right">
				<div class="form-group">
						<input type="text" className="form-control" placeholder="" value={account.accident_amount}
				onChange={e => this.setState({ account: {...account, accident_amount:e.target.value}})} />
				</div>
				</div>
				</div>
			<div className="form-row">
				<div className="title">
					<h4>Photographs</h4>
				</div>
				<div className="form-left">
				<div class="form-group">
						<select className="form-control" id="lang" onChange={this.onValueChangetype10.bind(this)} value={this.state.photograph_type}>
					<option  value="select">Select a Type</option>
					<option selected={account.photograph_type == 'Applicable'} value="Applicable">Applicable</option>
					<option  selected={account.photograph_type == 'Not Applicable'} value='Not Applicable'>Not Applicable</option>
					</select>
				</div>	
				</div>	
				<div className="form-right">
				<div class="form-group">
						<input type="text" className="form-control" placeholder="" value={account.photograph_amount}
				onChange={e => this.setState({ account: {...account, photograph_amount:e.target.value}})} />
				</div>
				</div>
			</div>
			<div className="btn-group export">	
				<input type="submit" className="btn" onClick={this.editaccount} value="Update" />
			</div>
		</div>
		</>
	  );
  }
}

export default EditAccount;