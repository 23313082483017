import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { API_BASE_URL } from '../config/api';
const axios = require("axios");

class AddInsurance extends React.Component {
	
	constructor(props)
	{
		super(props);	
		this.state = {
			insurance: [],
			deleteRecord: "",
			insurance: {
			  name: '',
			  address: '',
			  email: '',
			  fax: '',
			  telephone: '',
			}
		};	
    }
	
	componentDidMount(){
     this.getInsurance()
    }
	
	getInsurance = _ => {
    fetch(API_BASE_URL+'/insurance')
    .then(response => response.json())
    .then(response => this.setState({ insurance: response.data}))
    .catch(err => console.log(err))
  }
  
  handleChangeDelete = e => {
    this.setState({
      deleteRecord: e.target.name
    });
  };
  handleSubmit = e => {
    e.preventDefault();

    var Formdata = {
      deleteRecord: this.state.deleteRecord
    };

    axios.post(API_BASE_URL+'/insurance/delete', { Formdata }).then(response => {
      console.log(response.data);
      console.log(response);
	 this.getInsurance();
	  //this.setState({ insurance: this.getInsurance});
      //window.location.reload();
    });
  };
  
	addInsurance = _ => {
    const { insurance } = this.state
    fetch(API_BASE_URL+`/insurance/add?name=${insurance.name}&address=${insurance.address}&email=${insurance.email}&fax=${insurance.fax}&telephone=${insurance.telephone}`)
    .then(this.getInsurance)
    .catch( err => console.log(err))
	 window.location.href = '/insurance';
    }
	
  render() {
	  const { insurances, insurance } = this.state
	  console.log('map',insurances);
    return (	
		<div className="wrapper"> 
     	  <Header />
	      <MenuSidebar />		
       	<div className="content">	
		<div className="content-wrapper">
			<div className="content-body">
			<div className="form-wrapper">
			 <div className="title">
				<h4>Add Insurance</h4>
             </div>
			<div className="form-row">
			<div className="form-left">
				<div class="form-group">
					<label>Company Name</label>
					<input type="text" className="form-control" placeholder="" value={insurance.name}
            onChange={e => this.setState({ insurance: {...insurance, name:e.target.value}})} />
				</div>
				<div class="form-group">
					<label>Company Address</label>
					<input type="text" className="form-control"  placeholder="" value={insurance.address}
            onChange={e => this.setState({ insurance: {...insurance, address:e.target.value}})} />
				</div>
				<div class="form-group">
						<label>Email Address</label>
						<input type="text" className="form-control"  placeholder="" value={insurance.email}
				onChange={e => this.setState({ insurance: {...insurance, email:e.target.value}})} />
					</div>
			    
			</div>
			<div className="form-right">
				<div class="form-group">
						<label>Fax Number</label>
						<input type="text" className="form-control" placeholder="" value={insurance.fax}
				onChange={e => this.setState({ insurance: {...insurance, fax:e.target.value}})} />
					</div>	
                   <div class="form-group">
					<label>Telephone Number</label>
					<input type="text" className="form-control"  placeholder="" value={insurance.telephone}
            onChange={e => this.setState({ insurance: {...insurance, telephone:e.target.value}})} />
				</div>					
			</div>
			</div>
			<div className="btn-group export">	
				<input type="submit" className="btn" onClick={this.addInsurance} value="Submit" />
			</div>
			</div>
			</div>	
		</div>	
	</div>
		 	
    </div>
    );
  }
}

export default AddInsurance;