import React, { Component } from 'react';


import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import Login from '../Login/Login';
import { API_BASE_URL } from '../config/api';


class Pagelogin extends Component {
	
	constructor(props)
	{
		super(props);	
		this.state = {
			
		};
		
    }
	
	
	handleSubmit(event) {
        window.location.href = '/login';
      }

      handleSubmituser(event) {
        window.location.href = '/users/Login';
      }
  render() {
    return (
      <div className="login-sec">            
        <div className="login-type-wrap">
            <div className="title">
                <h4>CLLC System - Choose Login Type</h4>
            </div>
            <div className="form-group">
                <input type="button" value='Login as Admin' onClick={this.handleSubmit} />
            </div>
            <div className="form-group">
                <input type="button" value=' Login as User' onClick={this.handleSubmituser} />
            </div> 	
      </div>
      </div>
   
    );
  }
}

export default Pagelogin;