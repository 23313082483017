

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';
//import AddForm from "./component/AddForm";
import $ from "jquery";

import * as serviceWorker from './serviceWorker';

import './common/css/font-awesome.min.css';
import Home from './components/Home/Home';
import Login from './components/Login/Login-home';
import Hospitals from './components/Hospitals/Hospitals';
import AddHospital from './components/Hospitals/AddHospital';
import EditHospitalForm from './components/Hospitals/EditHospital';
import Insurance from './components/Insurance/Insurance';
import AddInsurance from './components/Insurance/AddInsurance';
import EditInsuranceForm from './components/Insurance/EditInsurance';
import Referrals from './components/Referrals/Referrals';
import AddReferral from './components/Referrals/AddReferral';
import EditReferralForm from './components/Referrals/EditReferral';
import Disbursement from './components/Disbursement/Disbursement';
import Plaintiff from './components/plaintiff/Plaintiffs';
import AddPlaintiff from './components/plaintiff/AddPlaintiff';
import EditPlaintiff from './components/plaintiff/EditPlaintiff';
import Hospitaldetails from './components/plaintiff/Hospitaldetails';
import Defendentdetails from './components/plaintiff/Defendentdetails';
import Disbursementdetails from './components/plaintiff/Disbursementdetails';
import Claimdetails from './components/plaintiff/Claimdetails';
import Documents from './components/plaintiff/Documents';
import Account from './components/Account/Account';
import EditDocuments from './components/plaintiff/EditDocuments';
import Editdoc from './components/plaintiff/EditDocs';
import AddUsers from './components/Users/AddUser';
import Users from './components/Users/Users';
import EditUsers from './components/Users/EditUser';
import ForgetPassword from './components/Login/Forget';
import ResetPassword from './components/Login/Reset';
import UsersLogin from './components/Users/Login-home';
import UsersForgetPassword from './components/Users/Forget';
import UsersResetPassword from './components/Users/Reset';
import EditProfile from './components/Login/EditProfile';
import Letters from './components/Letters/Letters';
import Filestatus from './components/plaintiff/Filestatus';
import Filestatussingle from './components/plaintiff/Filesingledisplay';
import Filestatusmultiple from './components/plaintiff/Filemultipledisplay';
import Letterone from './components/Letters/Letterone';
import Pagelogin from './components/Login/page-login';
import Chat  from './components/Users/Chat';
import Creategroup  from './components/Users/Creategroup';
import Usergroupadd  from './components/Users/usergroupadd';
import UsergroupList  from './components/Users/usergroupList';

import './common/css/custom.css';

ReactDOM.render(
		<Router>
			<Switch>
			     <Route exact path='/Login' component={Login} />
				<Route exact path='/' component={Home}/>
				<Route exact path='/home' component={Home} />
				<Route exact path='/hospitals' component={Hospitals} />		
				<Route exact path='/hospitals/add' component={AddHospital} />				
				<Route exact path='/hospitals/edit/:id' component={EditHospitalForm} />
                <Route exact path='/insurance' component={Insurance} />		
				<Route exact path='/insurance/add' component={AddInsurance} />				
				<Route exact path='/insurance/edit/:id' component={EditInsuranceForm} />
				<Route exact path='/referrals' component={Referrals} />		
				<Route exact path='/referral/add' component={AddReferral} />				
				<Route exact path='/referral/edit/:id' component={EditReferralForm} />				
				<Route exact path='/disbursement' component={Disbursement} />
				<Route exact path='/files' component={Plaintiff} />		
				<Route exact path='/files/add' component={AddPlaintiff} />				
				<Route exact path='/files/edit/:id' component={EditPlaintiff} />	
				<Route exact path='/files/addHospitaldetails' component={Hospitaldetails} />
				<Route exact path='/files/addDefendentdetails' component={Defendentdetails} />	
				<Route exact path='/files/addDisbursementdetails' component={Disbursementdetails} />
				<Route exact path='/files/addClaimdetails' component={Claimdetails} />		
				<Route exact path='/files/documents' component={Documents} />	
				<Route exact path='/account' component={Account} />	
				<Route exact path='/files/Editdocument' component={EditDocuments} />
				<Route exact path='/files/edit_documents/:id' component={Editdoc} />
				<Route exact path='/users/add' component={AddUsers} />
				<Route exact path='/users' component={Users} />	
				<Route exact path='/users/edit/:id' component={EditUsers} />
				<Route exact path='/forgot' component={ForgetPassword} />
				<Route exact path='/reset/:id' component={ResetPassword} />	
				<Route exact path='/users/Login' component={UsersLogin} />	
				<Route exact path='/users/forgot' component={UsersForgetPassword} />
				<Route exact path='/users/reset/:id' component={UsersResetPassword} />
				<Route exact path='/login/edit/:id' component={EditProfile} />	
				<Route exact path='/letters' component={Letters} />	
				<Route exact path='/letterone' component={Letterone} />	
				<Route exact path='/files/filestatus' component={Filestatus} />	
				<Route exact path='/files/filestatussingle' component={Filestatussingle} />
				<Route exact path='/files/filestatusmultiple' component={Filestatusmultiple} />	
				<Route exact path='/page-login' component={Pagelogin} />
				<Route exact path='/chat' component={Chat} />
				<Route exact path='/creategroup' component={Creategroup} />
				<Route exact path='/usergroupadd/:id' component={Usergroupadd} />
				<Route exact path='/UsergroupList/:id' component={UsergroupList} />
			</Switch>
		</Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

