import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { API_BASE_URL } from '../config/api';


const axios = require("axios");

class AddHospital extends React.Component {
	
	constructor(props)
	{
		super(props);	
		this.state = {
			hospitals: [],
			deleteRecord: "",
			hospital: {
			  name: '',
			  address: '',
			  email: '',
			  fax: '',
			  telephone: '',
			  amount: ''			  
			}
		};	
    }
	
	
	
  
  handleChangeDelete = e => {
    this.setState({
      deleteRecord: e.target.name
    });
  };
  handleSubmit = e => {
    e.preventDefault();

    var Formdata = {
      deleteRecord: e.target.name
    };

    axios.post(API_BASE_URL+'/hospitals/delete', { Formdata }).then(response => {
      console.log(response.data);
      console.log(response);
    });
  };
  
	addHospitals = _ => {
    const { hospital } = this.state
    fetch(API_BASE_URL+`/hospitals/add?name=${hospital.name}&address=${hospital.address}&email=${hospital.email}&fax=${hospital.fax}&telephone=${hospital.telephone}&amount=${hospital.amount}`)
     .catch( err => console.log(err))
	 window.location.href = '/hospitals';
    }
	
  render() {
	  const { hospitals, hospital } = this.state
	  console.log('map',hospitals);
    return (	
		<div className="wrapper"> 
     	  <Header />
	      <MenuSidebar />		
       	<div className="content">	
		<div className="content-wrapper">
			<div className="content-body">
			<div className="form-wrapper">
			 <div className="title">
				<h4>Add Hospital</h4>
             </div>
			<div className="form-row">
			<div className="form-left">
				<div class="form-group">
					<label>Hospital Name</label>
					<input type="text" className="form-control" placeholder="" value={hospital.name}
            onChange={e => this.setState({ hospital: {...hospital, name:e.target.value}})} />
				</div>
				<div class="form-group">
					<label>Hospital Address</label>
					<input type="text" className="form-control"  placeholder="" value={hospital.address}
            onChange={e => this.setState({ hospital: {...hospital, address:e.target.value}})} />
				</div>
				<div class="form-group">
					<label>Email Address</label>
					<input type="text" className="form-control"  placeholder="" value={hospital.email}
            onChange={e => this.setState({ hospital: {...hospital, email:e.target.value}})} />
				</div>
				<div class="form-group">
					<label>Fax Number</label>
					<input type="text" className="form-control" placeholder="" value={hospital.fax}
            onChange={e => this.setState({ hospital: {...hospital, fax:e.target.value}})} />
				</div>
			</div>
			<div className="form-right">
				<div class="form-group">
					<label>Telephone Number</label>
					<input type="text" className="form-control"  placeholder="" value={hospital.telephone}
            onChange={e => this.setState({ hospital: {...hospital, telephone:e.target.value}})} />
				</div>
				<div class="form-group">
					<label>Amount</label>
					<input type="text" className="form-control" placeholder="" value={hospital.amount}
            onChange={e => this.setState({ hospital: {...hospital, amount:e.target.value}})} />
				</div>
			</div>
			</div>
			<div className="btn-group export">	
				<input type="submit" className="btn" onClick={this.addHospitals} value="Submit" />
			</div>
			</div>
			</div>	
		</div>	
	</div>
		 	
    </div>
    );
  }
}

export default AddHospital;