import React, { Component } from 'react';
import { Link } from "react-router-dom";
import  Header from '../Layouts/Header';
import { API_BASE_URL } from '../config/api';
import  onSubmitMessage from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
const axios = require("axios");
class Reset extends Component {
	
	constructor(props)
	{
		super(props);	
		this.state = {
            users: [],
            deleteRecord: "",
            errors: {},
            errors1: {},
            mailmsg: {},
            mailmsg1: {},
            input: {},
          password:'',
          confirm_password:''
               
        };
        this.handleSubmit = this.handleSubmit.bind(this);
		
    }

      handleSubmit(event) {
        event.preventDefault();
        this.validate();//validationnn
      }
    //validationnn for all pwd ,confrm passwrd and email and alll fields.......
      validate(){
    
          let input = this.state;

          let errors = {};
          let isValid = true;
          if (!input["password"]) {
            isValid = false;
            errors["password"] = "Please enter your password.";
          }
          if (!input["confirm_password"]) {
            isValid = false;
            errors["confirm_password"] = "Please enter your confirm password.";
          }
          if (typeof input["password"] !== "undefined" && typeof input["confirm_password"] !== "undefined") {
            if (input["password"] != input["confirm_password"]) {
              isValid = false;
              errors["password"] = "Passwords don't match.";
            }
          } 

          this.setState({
            errors: errors
          }); 
          console.log('sdsds',isValid);
         if( isValid ==true){
            let mailmsg = {};
           this.sendpwd();
           mailmsg["msg"] = 'Loading....';
              this.setState({
                mailmsg: mailmsg
              });
        }
    
      }
        sendpwd = _ => {
          let urlElements = window.location.href.split('/')
          console.log('dddd',urlElements)
          let urlElelement = (urlElements[5])
          var key = urlElelement;
          var password = this.state.password;
          console.log('key',key);
          let mailmsg = {};
          let mailmsg1 = {};
          axios.get(API_BASE_URL+`/users/reset?password=${password}&reset_key=${key}`)
          .then((result) => {
            const data = result.data
            console.log('asas',data);
            if(data == 'success'){
              mailmsg["msg"] = 'Success! Your password has been changed.';
              this.setState({
                email:'',
                errors1: '',
                mailmsg: mailmsg
              }); 
            }else if(data == 'invalid'){
                mailmsg1["msg"] = 'Password reset token is invalid or has expired.';
                this.setState({
                  errors1: '',
                  mailmsg1: mailmsg1
                }); 
            }
         })
      }
    
	
  render() {
    const { password,confirm_password} = this.state
    return (
        <div className="wrapper">
       {/*    <Header />
	  <MenuSidebar /> 	  */}
      <div className="content">	
     
          <div className="content-wrapper">
              <div className="content-body">
			<div className="form-wrapper">
      <div style={{color: "green"}} className="text-danger">{this.state.mailmsg.msg}</div>
      <div style={{color: "red"}} className="text-danger">{this.state.mailmsg1.msg}</div>
			
			<div className="form-row">
            <div className="form-left">
            <div className="title">
				<h4>Reset password</h4>
			</div>
      <div class="form-group">
					<label>Enter new Password</label>
					<input type="password" name='password' id='password' onChange={e => this.setState( {password:e.target.value})} placeholder="" value={password} className="form-control"  />
                    <div style={{color: "red"}} className="text-danger">{this.state.errors.password}</div>
          </div>
          <div class="form-group">
					<label>New Password Confirmation</label>
					<input type="password" name='confirm_password' id='confirm_password' onChange={e => this.setState({ confirm_password:e.target.value})} placeholder=""  className="form-control" value={confirm_password}/>
                    <div style={{color: "red"}} className="text-danger">{this.state.errors.confirm_password}</div>
                </div>
                <Link  to={"/users/login"}> <b>Back to login</b></Link>
			<div className="btn-group export">	
				<input type="submit" onClick={this.handleSubmit} className="btn" value="Submit" />
			</div>
            </div>
			</div>
     
            </div>	
            </div>
            </div>
    </div>
		 	
    </div>
    );
  }
}

export default Reset;