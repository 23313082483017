import React, { Component } from 'react';
import { Link } from "react-router-dom";
import  Header from '../Layouts/Header';
import  onSubmitMessage from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { API_BASE_URL,API_KEY_CHAT,REGION_CHAT,APP_ID_CHAT } from '../config/api';
import { Widget , addResponseMessage ,addUserMessage } from 'react-chat-widget';
import { CometChat } from "@cometchat-pro/chat" 
import $ from 'jquery';
import 'react-chat-widget/lib/styles.css';
 
const axios = require("axios");



class Chat extends Component {
	
	constructor(props)
	{
		super(props);	
		this.state = {
			id: 1
		};	
        localStorage.setItem('user_chat_id','')
        
        
    }
	componentDidMount(){
    var logintype = localStorage.getItem('login_type');
        var appID = APP_ID_CHAT;
        var region = REGION_CHAT;
        var appSetting = new CometChat.AppSettingsBuilder().subscribePresenceForAllUsers().setRegion(region).build();
        CometChat.init(appID, appSetting).then(
        () => {
            console.log("Initialization completed successfully");
            this.loginchatuser();
            // You can now call login function.
        },
        error => {
            console.log("Initialization failed with error:", error);
            // Check the reason for error and take appropriate action.
        }
        );
    }
    loginchatuser = _ => {
      var login_user_id = localStorage.getItem('user_login_id');
       
        let apiKey = API_KEY_CHAT; //rest api key from common caht dashboard
        
     /*********************************** */
     var logintype = localStorage.getItem('login_type');
     if((logintype=='admin')){
     axios.get(API_BASE_URL+'/login/id/' + this.state.id)
       .then((result) => {
         const data = result.data.data
         var uid = JSON.stringify(data.admin_id);
         var name = data.admin_name;
           console.log('uid',uid);
           console.log('name',name);
         var user = new CometChat.User(uid);
          
         user.setName(name);
 
         CometChat.createUser(user, apiKey).then(
             user => {
                 console.log("user created", user);
                 
             },error => {
                 console.log("error", error);
             }
         )
       
       })
       this.loginchatadmincall(login_user_id,apiKey);
     }else if((logintype=='user')){
       
      axios.get(API_BASE_URL+'/users/id/' + login_user_id)
        .then((result) => {
         const data = result.data.data
        var uid = JSON.stringify(data.user_id);
        var name = data.name;
          console.log('uid',uid);
          console.log('name',name);
        var user = new CometChat.User(uid);
         
        user.setName(name);

        CometChat.createUser(user, apiKey).then(
            user => {
                console.log("user created", user);
                
            },error => {
                console.log("error", error);
            }
        )
     })
     
     this.loginchatusercall(login_user_id,apiKey);
     }


     /*********************************** */

     
     this.userslist();
     this.grouplist();
     
    }
    
    

    getusersid = (e) => {
        e.preventDefault();
        console.log(e.target.id);
        $('#chat').show();
        localStorage.setItem('chat_with_user_name','')
        localStorage.setItem('user_chat_id',e.target.id)

        var user_chat_id = localStorage.getItem('user_chat_id');
        
        if(user_chat_id != ''){

            var UID = user_chat_id;
            CometChat.getUser(user_chat_id).then(
            user => {
            console.log("User details fetched for user:", user);
            this.chat_with_user_name(user.name); 
            localStorage.setItem('chat_with_user_name',user.name)
            },
            error => {
            console.log("User details fetching failed with error:", error);
            }
            );
        this.receiver();
        this.retriverecievermsg();
        this.retrivesendermsg();
        }
      };

      

     getgroupid = (e) => {
      e.preventDefault();
      console.log(e.target.id);
      $('#chatgroup').show();
      localStorage.setItem('GUID_group',e.target.id)

       var user_chat_id = localStorage.getItem('GUID_group');
      
      if(user_chat_id != ''){

          var GUID  = e.target.id;
          CometChat.getGroup(GUID).then(
          group => {
          console.log("Group details fetched for Group:", group);
          },
          error => {
          console.log("Group details fetching failed with error:", error);
          }
          );
       this.receivergroup();
      this.retriverecievermsggroup();
      this.retrivesendermsggroup();
       }
    };

    // getgroupiddefault = (id) => {
    //   var id = id;
    //   $('#chatgroup').show();
    //   localStorage.setItem('GUID_group',id)

    //    var user_chat_id = localStorage.getItem('GUID_group');
      
    //   if(user_chat_id != ''){

    //       var GUID  = id;
    //       CometChat.getGroup(GUID).then(
    //       group => {
    //       console.log("Group details fetched for Group:", group);
    //       },
    //       error => {
    //       console.log("Group details fetching failed with error:", error);
    //       }
    //       );
    //    this.receivergroup();
    //   this.retriverecievermsggroup();
    //   this.retrivesendermsggroup();
    //    }
    // };


      chat_with_user_name = (userchatname) => {
        console.log("chat_with_user_namefffffffffff", userchatname);
        var chat_with_user_name = userchatname;
        localStorage.setItem('chat_with_user_name',chat_with_user_name)
        //return chat_with_user_name;
    }
    
    
    loginchatusercall = (uid,apiKey) => {
      var login_user_id = localStorage.getItem('user_login_id');

        var logintype = localStorage.getItem('login_type');
        console.log('logintype',logintype);
   
      axios.get(API_BASE_URL+'/users/id/' + login_user_id)
      .then((result) => {
       const data = result.data.data
      var uid = JSON.stringify(data.user_id);
      var name = data.name;
        console.log('uid',uid);
        console.log('name',name);
        localStorage.setItem('user_chat_name',name)
     
   
      console.log("uid", uid);
      var userid = uid;
      var api_key = apiKey;
      CometChat.login(uid, apiKey).then(
          user => {
            console.log("Login Successful:", { user }); 
            localStorage.setItem('user_chat_status',user['status'])
            
          }
        , error => {
          console.log("User login failed with error:", error);
          //Check the reason for error and take appropriate action.
      });

   })
      
    }


    loginchatadmincall = (uid,apiKey) => {
      var login_user_id = localStorage.getItem('user_login_id');

        var logintype = localStorage.getItem('login_type');
        console.log('logintype',logintype);
     if((logintype=='admin')){
     axios.get(API_BASE_URL+'/login/id/' + login_user_id)
       .then((result) => {
        const data = result.data.data
        var uid = JSON.stringify(data.admin_id);
        var name = data.admin_name;
          console.log('uid',uid);
          console.log('name',name);
          localStorage.setItem('user_chat_name',name)
     
        console.log("uid", uid);
        var userid = uid;
        var api_key = apiKey;
        CometChat.login(uid, apiKey).then(
            user => {
              console.log("Login Successful:", { user }); 
              localStorage.setItem('user_chat_status',user['status'])
              
            }
          , error => {
            console.log("User login failed with error:", error);
            //Check the reason for error and take appropriate action.
        });
       
       })
     }
       
    }

    /*--------------Group--------------------*/

    handleNewUserMessagegroup = (newMessage) => {
      console.log(`New message incoming! ${newMessage}`);
      // Now send the message throught the backend API
      var login_group_id = localStorage.getItem('GUID_group');
      var user_chat_id = localStorage.getItem('user_chat_id');
      var receiverID = login_group_id;
      var messageText = newMessage;
      var receiverType = CometChat.RECEIVER_TYPE.GROUP;
      var textMessage = new CometChat.TextMessage(
      receiverID,
      messageText,
      receiverType
      );

      CometChat.sendMessage(textMessage).then(
      message => {
          console.log("group Message sent successfully:", message);
        
      },
      error => {
          console.log("group Message sending failed with error:", error);
      }
      );

    };


    receivergroup = () => {
      var login_user_id = localStorage.getItem('user_chat_id');
      var login_group_id = localStorage.getItem('GUID_group');
      var listenerID = login_group_id;
      CometChat.addMessageListener(
      listenerID,
      new CometChat.MessageListener({
          onTextMessageReceived: textMessage => {
          console.log("Text message received successfully", textMessage);
          var text = '   ' + textMessage.sender['name'] + '  \n  '  +  '   ' + textMessage.text;
          addResponseMessage(text);
          },
          onMediaMessageReceived: mediaMessage => {
          console.log("Media message received successfully", mediaMessage);
          // Handle media message
          },
          onCustomMessageReceived: customMessage => {
          console.log("Custom message received successfully", customMessage);
          // Handle custom message
          }
      })
      );
    
    };


    
   retriverecievermsggroup = () => {
    var login_user_id = localStorage.getItem('user_login_id');
    var login_group_id = localStorage.getItem('GUID_group');
let messagesRequest = new CometChat.MessagesRequestBuilder()
  .setLimit(30)
  .build();
messagesRequest.fetchPrevious().then(
  messages => {
      console.log("Messages for thread fetched successfully", messages);
      var i=0;
      messages.forEach(element => {
         
          if( (element.receiver['guid'] == login_group_id) && (element.sender['uid']  != login_user_id) && (element.data['text']  != undefined) ){
              var text = '   ' + element.sender['name'] + '  \n  '  +  '   ' + element.data['text'];
            addResponseMessage(text);
              //addResponseMessage(element);
          }
          
          i++;
      });
  },
  error => {
      console.log("Message fetching failed with error:", error);
  }
);
    
    };


    retrivesendermsggroup = () => {
      var login_user_id = localStorage.getItem('user_login_id');
      var login_group_id = localStorage.getItem('GUID_group');
      let messagesRequest = new CometChat.MessagesRequestBuilder()
        .setLimit(100)
        .build();
     messagesRequest.fetchPrevious().then(
        messages => {
            console.log("Messages for thread fetched successfully", messages);
            var i=0;
            messages.forEach(element => {
              //   console.log("sendermsg", element.sender['uid']);
              //   console.log("login_user_id", login_user_id);
              //   console.log("element.data['text']", element.data['text']);&& (element.receiver['uid']  == user_chat_id)
                if((element.receiver['guid'] == login_group_id )  && (element.sender['uid']  == login_user_id) && (element.data['text']  != undefined ) ){
                    //addUserMessage(element.data['text']);
                    addUserMessage(element.data['text']);
                }
                
                i++;
            });
        },
        error => {
            console.log("Message fetching failed with error:", error);
        }
    );
    
    };



    /***************************************/


    handleNewUserMessage = (newMessage) => {
        console.log(`New message incoming! ${newMessage}`);
        // Now send the message throught the backend API
        var user_chat_id = localStorage.getItem('user_chat_id');
        var receiverID = user_chat_id;
        var messageText = newMessage;
        var receiverType = CometChat.RECEIVER_TYPE.USER;
        var textMessage = new CometChat.TextMessage(
        receiverID,
        messageText,
        receiverType
        );

        CometChat.sendMessage(textMessage).then(
        message => {
            console.log("Message sent successfully:", message);
          
        },
        error => {
            console.log("Message sending failed with error:", error);
        }
        );

      };


      receiver = () => {
        var login_user_id = localStorage.getItem('user_login_id');
        var listenerID = login_user_id;
        CometChat.addMessageListener(
        listenerID,
        new CometChat.MessageListener({
            onTextMessageReceived: textMessage => {
            console.log("Text message received successfully", textMessage);
            addResponseMessage(textMessage.text);
            },
            onMediaMessageReceived: mediaMessage => {
            console.log("Media message received successfully", mediaMessage);
            // Handle media message
            },
            onCustomMessageReceived: customMessage => {
            console.log("Custom message received successfully", customMessage);
            // Handle custom message
            }
        })
        );
      
      };


      
     retriverecievermsg = () => {
        var login_user_id = localStorage.getItem('user_login_id');
        var user_chat_id = localStorage.getItem('user_chat_id');
  let messagesRequest = new CometChat.MessagesRequestBuilder()
    .setLimit(30)
    .build();
 messagesRequest.fetchPrevious().then(
    messages => {
        console.log("Messages for thread fetched successfully", messages);
        var i=0;
        messages.forEach(element => {
           
            if((element.receiver['uid'] == login_user_id) && (element.sender['uid']  == user_chat_id) && (element.data['text']  != undefined) ){
                addResponseMessage(element.data['text']);
                //addResponseMessage(element.data.text);
            }
            
            i++;
        });
    },
    error => {
        console.log("Message fetching failed with error:", error);
    }
);
      
      };


      retrivesendermsg = () => {
        var login_user_id = localStorage.getItem('user_login_id');
        var user_chat_id = localStorage.getItem('user_chat_id');
        let messagesRequest = new CometChat.MessagesRequestBuilder()
          .setLimit(100)
          .build();
       messagesRequest.fetchPrevious().then(
          messages => {
              console.log("Messages for thread fetched successfully", messages);
              var i=0;
              messages.forEach(element => {
                //   console.log("sendermsg", element.sender['uid']);
                //   console.log("login_user_id", login_user_id);
                //   console.log("element.data['text']", element.data['text']);&& (element.receiver['uid']  == user_chat_id)
                  if((element.sender['uid']  == login_user_id) && (element.receiver['uid']  == user_chat_id) && (element.data['text']  != undefined ) ){
                      //addUserMessage(element.data['text']);
                      addUserMessage(element.data['text']);
                  }
                  
                  i++;
              });
          },
          error => {
              console.log("Message fetching failed with error:", error);
          }
      );
      
      };

      userslist = () => {

        var limit = 50;
        var usersRequest = new CometChat.UsersRequestBuilder().setLimit(limit).build();
        
        usersRequest.fetchNext().then(
          userList => {
            /* userList will be the list of User class. */
            console.log("User list received:", userList);
            localStorage.setItem('contact_list',JSON.stringify(userList))
            /* retrived list can be used to display contact list. */
          },
          error => {
            console.log("User list fetching failed with error:", error);
          }
        );

      }

      /********Group creation*******/

      CreateGroup = ()=>{
        window.location.href = '/creategroup';
      }

      grouplist = () => {
        var limit = 50;

        var groupsRequest = new CometChat.GroupsRequestBuilder()
          .setLimit(limit)
          .build();
        
        groupsRequest.fetchNext().then(
          groupList => {
            /* groupList will be the list of Group class */
            console.log("Groups list fetched successfully", groupList);
            localStorage.setItem('group_list',JSON.stringify(groupList))
            //this.getgroupiddefault(groupList[0].guid);
            /* you can display the list of groups available using groupList */
          },
          error => {
            console.log("Groups list fetching failed with error", error);
          }
        );

      }

      deletegroup = (e) => {
        e.preventDefault();
        console.log(e.target.id);
        let GUID = e.target.id;
       /**********check the admin to delete group**********/
          var limit = 100;
          var groupMemberRequest = new CometChat.GroupMembersRequestBuilder(GUID)
            .setLimit(limit)
            .build();
          
          groupMemberRequest.fetchNext().then(
            groupMembers => {
              console.log("Group Member list fetched successfully:", groupMembers);
              localStorage.setItem('group_members_list',JSON.stringify(groupMembers))
            },
            error => {
              console.log("Group Member list fetching failed with exception:", error);
            }
          );

      var group_members_list = JSON.parse(localStorage.getItem('group_members_list')) ?JSON.parse(localStorage.getItem('group_members_list')) :[];
        group_members_list.forEach(usermem => {
          if(usermem.scope == 'admin'){
            console.log("usermem", usermem.uid);
            localStorage.setItem('usermem',usermem.uid)
          }
        });
       /****************************************/
       var usermem = localStorage.getItem('usermem');
       var user_login_id = localStorage.getItem('user_login_id');
       if(usermem == user_login_id){
        CometChat.deleteGroup(GUID).then(
          response => {
            console.log("Groups deleted successfully:", response);
            alert("Groups deleted successfully..");
            window.location.reload();
          },
          error => {
            console.log("Group delete failed with exception:", error);
            alert("Group delete failed with exception..");
            window.location.reload();
          }
        );
       }else{
        alert("OOPS!..Sorry...Group will be deleting only by the admin...");
       }
      }

      imageshow = (id) => {
        var tags = [];
        if(id==1){
          axios.get(API_BASE_URL+'/login/id/' + id)
          .then((result) => {
            var data = result.data
           if(data.data.profile_image!=''){
            var  tags = API_BASE_URL+'/images/profile/' +data.data.profile_image;
           }else if(data.data.profile_image=='' || data.data.profile_image==undefined){
            var  tags = process.env.PUBLIC_URL + '/user.png';
           }
           
           localStorage.setItem('prof_img' +[data.data.admin_id] , tags);
          //console.log("kkkkk", tags);
          
         })

        }else{
          axios.get(API_BASE_URL+'/users/id/' + id)
          .then((result) => {
            var data = result.data
           if(data.data.profile!=''){
            var  tags = API_BASE_URL+'/images/profile/' +data.data.profile;
           }else if(data.data.profile=='' || data.data.profile==undefined){
            var  tags = process.env.PUBLIC_URL + '/user.png';
           }
           
           localStorage.setItem('prof_img' +[data.data.user_id] , tags);
          //console.log("kkkkk", tags);
          
         })

        }
      
       var prof_img = localStorage.getItem('prof_img'+[id]);

       return prof_img;
      
      }
     
  render() {
    const { tags } = this.state
    var logintype = localStorage.getItem('login_type');
    var login_user_name = localStorage.getItem('user_chat_name');
    var user_chat_status = localStorage.getItem('user_chat_status');
    var chat_with_user_name = localStorage.getItem('chat_with_user_name');
    var contact_list = JSON.parse(localStorage.getItem('contact_list')) ?JSON.parse(localStorage.getItem('contact_list')) :[];
    var group_list = JSON.parse(localStorage.getItem('group_list')) ?JSON.parse(localStorage.getItem('group_list')) :[];
   
    // const   getCustomLauncher = (handleToggle) =>
    //{process.env.PUBLIC_URL + '/user.png'}
    //     <button onClick={handleToggle}>Open chat</button>
    return (	
		<div className="wrapper"> 
     	  <Header />
	      <MenuSidebar  />		
       	<div className="content">	
		<div className="content-wrapper">
			<div className="content-body">
			<div className="form-wrapper">	
			<div className="title">
            <div id="cometchat"></div>
				<h4>Private Chat System</h4>
             </div>
             <h4>Users List for private chat</h4>	
             {(contact_list !='' )?	
          <table style={{width : '70%',height : '50%',padding: '20px'}}><tr><th width='100px'>Users Details</th><th width='50px'> Actions</th></tr>
             {contact_list.map((contact_list) =>
             <tr key={contact_list.uid} >
               <td width='100px'>
               <pre>
               <img  src={this.imageshow(contact_list.uid)}  style={{width : '20%',height : '50%',borderRadius: '100%'}} />
               <b  style={{fontSize :'30px'}}>{contact_list.name}</b>
               <p style={{fontSize :'20px'}}>{contact_list.status}</p>
                 </pre>
                </td> 
             <td width='50px'>
               <span  id={contact_list.uid} name={contact_list.uid} onClick={this.getusersid}>Chat</span>  
              </td>
              </tr >
             )}
             </table>:<div>No Contact List found...Refresh Your window to load data's..</div>}
             <div style={{display:'none'}} className='showchat' id='chat'>
			<Widget 
            handleNewUserMessage={this.handleNewUserMessage}
            title="Welcome To CLLC Chat System"
            subtitle= {"Hii "+login_user_name + "..."}
           // launcher={handleToggle => getCustomLauncher(handleToggle)}
            
            />
          </div>
          <div className="title">
            <div id="cometchat"></div>
				<h4>Group Chat System</h4>
        {((logintype=='admin') )
             ?
        <Link  onClick={this.CreateGroup}> Create Group</Link>
        : ''
			}	
             </div>
            
             <h4>Group List </h4>	
            
             {(group_list !='' )?	
          <table><tr><th width='300px'>Group Name</th><th width='300px'> Created by </th><th width='100px'> Members Count</th><th > Actions</th></tr>
             {group_list.map((group_list) =>
             <tr key={group_list.guid} ><td>{group_list.name}</td> <td>{login_user_name}</td><td>{group_list.membersCount}</td><td width='150px'>
               <div>
               <Link  id={group_list.guid} name={group_list.guid} onClick={this.getgroupid}>Chat</Link>
              </div>
              <div>
              <Link  to={"/usergroupList/" + group_list.guid}> List Members</Link>
              </div>
              {(logintype=='admin') ?
              <Link  to={"/usergroupadd/" + group_list.guid}> Add Members</Link> 
              : ''	}	
              {(logintype=='admin') ? 
              <Link  id={group_list.guid} name={group_list.guid} onClick={this.deletegroup}> Delete Group</Link>
              : ''	}	
             
                 </td></tr >
             )}
             </table>:<div>No Group List found........Refresh Your window to load data's..</div>}
             
             <div style={{display:'none'}} className='chatgroup' id='chatgroup'>
		        	<Widget 
            handleNewUserMessage={this.handleNewUserMessagegroup}
            title="Welcome To.."
            subtitle= {"CLLC Group Chat"}
            />
          </div>
	     	</div>
			</div>	
		</div>	
	</div>
		 	
    </div>
    );
  }
}

export default Chat;